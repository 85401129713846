import React from 'react';
import './Terms&Conditions.css';
import Footer from './Footer';
import FooterDesktop from './FooterDesktop';
import { isMobile } from 'react-device-detect';

function TermsAndConditions() {


    return (
        <div className="centre">
            <h1>
                Terms {'&'} Conditions
            </h1>
            <p>
                Please read our Terms and Conditions of Use ("Terms") and Privacy Policy carefully because they affect your legal rights, including an agreement to resolve any disputes that may arise between us by arbitration on an individual basis instead of by class actions or jury trials. By accessing or using the Damamma Platform, you accept to fall under the scope of this following Terms.
            </p>
            <h1>
                Introduction
            </h1>
            <p>
                Thank you for using Quidvid, a web-App that belongs to the company, Damamma Holdings Limited.
            </p>
            <p>
                These terms constitute a legally binding agreement (“Agreement”) between you and Damamma governing your access to and use of the Quidvid website, including any subdomains thereof, and any other websites through which Damamma makes it services available (collectively, “Site”), our mobile, tablet and other smart device applications, and application program interfaces (collectively, “Application”) and all associated services (collectively, “Damamma Services”). The Site, Application, all other software related to Quidvid, and Damamma Services together are hereinafter referred to as “Damamma Platform”). Other policies applicable to your use of the Damamma Platform are incorporated by reference into this Agreement.
            </p>
            <p>
                When these Terms mention “Damamma”, “we”, “us”, or “our”, it refers to Damamma Holdings Limited, company incorporated in England & Wales, company number: 11918774.
            </p>
            <p>
                Our collection and use of personal information in connection with your access to and use of the Damamma Platform is described in our Privacy Policy.
            </p>
            <p>
                Payment processing services (“Damamma Payments”) for Members on the Damamma Platform are provided by Stripe and are subject to the Stripe Connected Account Agreement, which includes the Stripe Terms of Service (collectively, the “Stripe Services Agreement”). By agreeing to [this agreement / these terms / etc.] or continuing to operate as a Member on the Damamma Platform, you agree to be bound by the Stripe Services Agreement, as the same may be modified by Stripe from time to time. As a condition of Damamma enabling payment processing services through Stripe, you agree to provide Damamma accurate and complete information about you, and you authorise Damamma to share it and transaction information related to your use of the payment processing services provided by Stripe. 
            </p>
            <h1>
                1. Scope of Services
            </h1>
            <p>
                1.1 The Damamma Platform is an online marketplace that enables registered users (“Members”) to request questions (“Request”) from certain third parties who offer responses in return (Members and third parties who offer their services are “Independent Partners. Any Member who makes a Request is defined as a “Consumer”.
            </p>
            <p>
                1.2 As the provider of the Damamma Platform, Damamma does not own, create, sell, resell, provide, control, manage, offer, deliver or supply any questions, or other pieces of content. Damamma's responsibility is to list the questions asked by Members so that they may be answered by Independent Partners offering their services on the Damamma Platform. When a question is Requested by a Consumer, and subsequently answered by an Independent Partner, a contractual relationship arises between the Independent Partner and Consumer. Damamma is not party to this contract, nor is Damamma to be considered an insurer. Damamma does not act as an agent of any Member, unless otherwise expressly stated in the section “Service Fees”. 
            </p>
            <p>
                1.3 While we may help facilitate the resolution of disputes, Damamma has no control over and does not guarantee the existence, quality, reliability, suitability or legality of any answer under any circumstances whatsoever.  Damamma does not endorse any Member, or answer. Any references to a Member being "verified" (or similar language) only indicate that the Member has completed a relevant verification or identification process and nothing else. Any such description is not an endorsement, certification or guarantee by Damamma about any Member, including of the Member's identity or background or whether the Member is trustworthy, safe or suitable. You should always exercise due diligence and care when deciding whether to ask a question, or follow any advice provided in an answer. Similarly, Members should exhibit caution when communicating or interacting with other Members, whether online or in person. Verified Images (as defined below) are intended only to indicate a photographic representation of a Listing at the time the photograph was taken, and are therefore not an endorsement by Damamma of any Independent Partner.
            </p>
            <p>
                1.4 If you choose to use the Damamma Platform as an Independent Partner, your relationship with Damamma is limited to being an independent, third-party contractor, and not an employee, agent, joint venturer or partner of Damamma for any reason, and you act exclusively on your own behalf and for your own benefit, and not on behalf, or for the benefit, of Damamma. Damamma does not, and shall not be deemed to, direct or control you generally or in your performance under these Terms specifically, including in connection with your provision of content.
            </p>
            <p>
                1.5 The Damamma Platform may contain links to third-party websites or resources (“Third-Party Services”). Such Third-Party-Services may be subject to different terms and conditions and privacy practices. Damamma is not responsible or liable for the availability or accuracy of such Third-Party-Services, or the content, products, or services available from such Third-Party Services. Links to such Third-Party-Services are not an endorsement by Damamma of such Third-Party-Services.
            </p>
            <p>
                1.6 Damamma is not responsible for outages or disruptions of the Internet and telecommunications infrastructure which are beyond our control and can lead to interruptions of the Damamma Platform. Damamma may, temporarily and under consideration of the Member’s legitimate interests, (e.g. by providing prior noticing), restrict the availability of the Damamma Platform or certain areas or features therefore, if this is necessary in view of capacity limits, the security or integrity of our servers, or to carry out maintenance measures that ensure the proper or improved functions of the Damamma Platform. Damamma may improve, enhance and modify the Damamma Platform from time to time. Damamma will provide notice to Members of any material changes to the Damamma Platform, unless such changes are of minor nature or without material effect on the parties’ contractual obligations.
            </p>
            <h1>
                2. Eligibility
            </h1>
            <p>
                2.1 You must be at least 18 years old and able to enter into legally binding contracts to access and use the Damamma Platform or register as a Member. By accessing or using the Damamma Platform, you represent and warrant that you are 18 or older and have the legal capacity and authority to enter into a contract.
            </p>
            <p>
                2.2 Damamma may make access to and use of the Damamma Platform, or certain areas or features of the Damamma Platform, subject to certain conditions or requirements, such as completing a verification process, meeting specific quality or eligibility criteria, meeting Ratings or Reviews thresholds, or a Member’s booking and cancellation history.
            </p>
            <p>
                2.3 Damamma does not assume any responsibility for confirmation of any Member’s identity. Notwithstanding the above, for transparency and fraud prevention purposed, and as permitted by applicable laws, we may, but have no obligation to (i) ask Members to provide a form of government identification or other information or undertake additional checks designed to help verify the identities or backgrounds of Members, (ii) where we have sufficient information to identify a Member, obtain reports from public records of criminal convictions or sex offender registrations or an equivalent version of background or registered sex offender checks in your location jurisdiction (if available).
            </p>
            <p>
                2.4 The access to use of specific areas and features of the Damamma Platform may be subject to separate policies, standards or guidelines, or may require that you accept additional terms and conditions before you can access the relevant areas or feature of the Damamma Platform. If there is a conflict between these Terms and terms and conditions applicable to a specific area or feature of the Damamma Platform, the latter terms and conditions will take precedence concerning your access to or use of that area or feature, unless otherwise specified otherwise in the latter terms and conditions.
            </p>
            <h1>
                3. Modification of These Terms
            </h1>
            <p>
                Damamma reserves the right to modify these Terms at any time in accordance with this provision. If we make changes to these Terms, we will post the revised Terms on the Damamma Platform and update the “Last Updated” date at the top of the terms. We will also provide you with notice of the modification by email at least thirty (30) days before the date they come into effect. If you disagree with the revised terms, you may terminate this Agreement with immediate effect. We will inform you about your right to terminate the Agreement in the notification email. If you do not terminate your Agreement before the date the revised Terms become effective, your continued access to or use of the Damamma Platform will constitute acceptance of the revised Terms.
            </p>
            <h1>
                4. Account Registration
            </h1>
            <p>
                4.1 You must register an account (“Damamma Account”) to access and use certain features of the Damamma Platform, such as asking or answering questions. If you are registering a Damamma Account for a company or other legal entity, you represent and warrant that you have the authority to legally bind that entity and grant us all the permissions and licenses provided in these Terms.
            </p>
            <p>
                4.2 You can register a Damamma Account using an email address and creating a password.
            </p>
            <p>
                4.3 You must provide accurate, current and complete information during the registration process and keep your Damamma Account and public Damamma Account profile page information up-to-date at all times.
            </p>
            <p>
                4.4 You may not register more than one (1) Damamma Account unless Damamma authorises you to do so. You may not assign or otherwise transfer your Damamma Account to another party.
            </p>
            <p>
                4.5 You are responsible for maintaining the confidentiality and security of your Damamma Account credentials and may not disclose your credentials to any third party. You must immediately notify Damamma if you know or have any reason to suspect that your credentials have been lost, stolen, misappropriated, or otherwise compromised or in case of any actual or suspected unauthorised use of your Damamma Account. You are liable for any activities conducted through your Damamma Account unless you do not authorise such activities and you are not otherwise negligent (such as failing to report the unauthorised use or loss of your credentials).
            </p>
            <p>
                4.6 Damamma may enable features that allow you to authorise other Members or certain third parties to take certain actions that affect your Damamma Account. These features do not require that you share your credentials with any other person. No third party is authorised by Damamma to ask for your credentials, and you shall not request the credentials of another Member.
            </p>
            <h1>
                5. Content
            </h1>
            <p>
                5.1 Damamma may enable Members to (i) create, upload, post, post, send, receive and store content, such as text, photos, audio, video or other materials and information on or through the Damamma Platform (“Members Content”); and (ii) access and view Members content and any content that Damamma itself makes available on or through the Damamma Platform, including proprietary Damamma content and any content licensed or authorised for use by or through Damamma from a third party (“Damamma Content” and together with Member Content, “Collective Content”).
            </p>
            <p>
                5.2 The Damamma Platform, Damamma Content, and Member Content may in its entirety or in part be protected by copyright, trademark, and/or other laws of England and Wales and other countries. You acknowledge and agree that the Damamma Platform and Damamma Content, including all associated intellectual property rights, are the exclusive property of Damamma and/or its licensors or authorising third-parties. You will not remove, alert or obscure any copyright, trademark, service mark or other property rights notices incorporated in or accompanying the Damamma Platform, Damamma Content or Member Content. All trademarks, service marks, logos, trade names and any other propriety designations of third parties used on or in connection with the Damamma Platform, Damamma Content, and/or Collective Content are used for identification purposes only and may be the properties of their respective owners.
            </p>
            <p>
                5.3 You will not use, copy, adapt, modify, prepare derivative works of, distribute, license, sell, transfer, publicly display, publicly perform, transmit, broadcast or otherwise exploit the Damamma Platform or Collective Content, except to the extent you are the legal owner of specific Member Content or as expressly permitted in these Terms. No license or rights are granted to you by implication or otherwise under any intellectual property rights owned or controlled by Damamma or its licensors, except for the licenses and rights expressly issued in these Terms.
            </p>
            <p>
                5.4 Subject to your compliance with these Terms, Damamma grants you a limited, non-exclusive, non-sublicensable, revocable, non-transferable license to (i) download and use the Application on your personal device(s); and (ii) access and view any Collective Content made available on or through the Damamma Platform and accessible to you, solely for your personal and non-commercial use.
            </p>
            <p>
                5.4 Subject to your compliance with these Terms, Damamma grants you a limited, non-exclusive, non-sublicensable, revocable, non-transferable license to (i) download and use the Application on your personal device(s); and (ii) access and view any Collective Content made available on or through the Damamma Platform and accessible to you, solely for your personal and non-commercial use.
            </p>
            <p>
                5.6 You are solely responsible for all Member Content that you make available on or through the Damamma Platform. Accordingly, you represent and warrant that: (i) you either are the sole and exclusive owner of all Member Content that you make available on or through the Damamma Platform or you have all rights, licenses, consents and releases that are necessary to grant Damamma the rights in and to such Member Content, as contemplated under these Terms; and (ii) neither the Member Content nor your posting, uploading, publication, submission or transmittal of the Member Content or Damamma’s use of the Member Content (or any portion thereof) as contemplated under these Terms will infringe, misappropriate or violate a third party's patent, copyright, trademark, trade secret, moral rights or other proprietary or intellectual property rights, or rights of publicity or privacy, or result in the violation of any applicable law or regulation.  
            </p>
            <p>
                5.7 You will not post, upload, publish, submit or transmit any Member Content that: (i) is fraudulent, false, misleading (directly or by omission or failure to update information) or deceptive; (ii) is defamatory, libellous, obscene, pornographic, vulgar or offensive; (iii) promotes discrimination, bigotry, racism, hatred, harassment or harm against any individual or group; (iv) is violent or threatening or promotes violence or actions that are threatening to any other person or animal; (v) promotes illegal or harmful activities or substances; or (vi) violates Damamma’s Content Policy or any different Damamma policy. Damamma may remove or disable access to any Member Content that violates applicable law, these Terms or Damamma’s then-current Policies or Standards, or otherwise may be harmful or objectionable to Damamma, its Members, third parties, or property. Where Damamma removes or disables Member Content, Damamma will notify a Member and provide the reasons for such a measure, unless such notification would (i) prevent or impede the detection or prevention of fraud or other illegal activities, (ii) harm the legitimate interests of other Members or third parties, or (ii) contravene applicable laws. You may appeal such a decision by contacting customer service.
            </p>
            <h1>
                6. Service Fees
            </h1>
            <p>
                6.1 Damamma may charge fees to Independent Partners (“Independent Partner Fees”), Consumers (“Consumer Fees”) and/or Members, (collectively, “Service Fees”) in consideration for the use of the Damamma Platform. 
            </p>
            <p>
                6.2 Any applicable Service fees (including any applicable Taxes) will be displayed to an Independent Partner or Member prior to supplying an answer to a question. Damamma reserves the right to change the Service Fees at any time and will provide Members adequate notice of any fees changes before they become effective. Such fee changes will not affect any Request made before the effective date of the fee change. 
            </p>
            <p>
                6.3 You are responsible for paying any Service Fees that you owe Damamma. The applicable Service Fees (including any applicable Taxes) are collected by Damamma Payments. Damamma Payments will deduct any Independent Partner Fees before remitting the pay-out to the Independent Partner. Except as otherwise provided on the Damamma Platform, Service Fees are non-refundable.
            </p>
            <h1>
                7. Terms Specific for Independent Partners
            </h1>
            <h2>
                7.1 Terms applicable to all Listings
            </h2>
            <p>
                7.1.1 When you accept a Request by a Member, you are entering into a legally binding agreement with the Member and are required to provide your answer to the Member as described in the accepted Request. You also agree to pay the applicable Independent Partner Fees or any applicable Taxes.
            </p>
            <p>
                7.1.2 When you accept or have pre-approved a request by a Consumer, you are entering into a legally binding agreement with the Consumer and are required to provide your Independent Partner Service(s) to the Consumer. You also agree to pay the applicable Independent Partner Fee and any applicable Taxes.

                Any content you supply shall (i) not breach any agreement you have entered into with any third parties or other agreement, and (ii) comply with all applicable laws, Tax requirements, and different rules and regulations (including having all required permits, licenses and registrations).

                If, as an Independent Partner, your Consumer cancels a confirmed Request or Damamma decides that it is necessary to cancel a confirmed booking, and Damamma issues a refund to the Consumer, you agree that in the event you have already been paid, Damamma Payments will be entitled to recover the amount of any such refund from you, including by subtracting such refund amount out from any future Payouts due to you.
            </p>
            <h1>
                8. Terms specific for Consumers
            </h1>
            <h2>
               8.1 Terms applicable to all bookings 
            </h2>
            <p>
                8.1.1 Upon receipt of an answer confirmation from Damamma, a legally binding agreement is formed between you and your Independent Partner. Damamma Payments will collect the Total Fees at the time of the booking request.
            </p>
            <h1>
                9. Rounding Off
            </h1>
            <p>
                Damamma generally supports payment amounts that are payable from or to Consumers or Independent Partners to the smallest unit supported by a given currency (i.e. Pound pence or other supported currencies). Where Damamma’s third-party payment services provider does not support payments in the smaller unit supported by a given currency, Damamma may, in its sole discretion, round up or round down the displayed amounts that are payable from or to Consumers or Independent Partners to the nearest whole functional base unit in which the currency is denominated (i.e. to the nearest Pound or other supported currency); for example, Damamma may round up an amount of £101.50 to £102.00, and round down an amount of £101.49 to £101.00.
            </p>
            <h1>
                10. Taxes
            </h1>
            <p>
                10.1 As an Independent Partner you are solely responsible for determining your obligations to report, collect, remit any applicable VAT or other indirect sales taxes, taxes relating to the sale of content, or income taxes ("Taxes").
            </p>
            <p>
                10.2 Tax regulations may require us to collect appropriate Tax information from Independent Partners or to withhold Taxes from payouts to Independent Partners, or both. If an Independent Partner fails to provide us with documentation that we determine to be sufficient to alleviate our obligation (if any) to withhold Taxes from payouts to you, we reserve the right to freeze all payouts, withhold such amounts as required by law, or to do both, until resolution.
            </p>
            <p>
                11.3 You understand that any appropriate governmental agency, department and/or authority ("Tax Authority") where you are located may require Taxes to be collected from Consumers or Independent Partners on Independent Partner Fees, and to be remitted to the respective Tax Authority. The laws in jurisdictions may vary, but these Taxes may be required to be collected and remitted as a percentage of the Independent Partner Fees set by Independent Partners, a set amount per day, or other variations.
            </p>
            <h1>
                11. Prohibited Activities
            </h1>
            <p>
                You are solely responsible for compliance with all laws, rules, regulations, and Tax obligations that may apply to your use of the Damamma Platform. In connection with your use of the Damamma platform, you will not and will not assist or enable others to:
            </p>
            <ul>
                <li>
                	Breach or circumvent any applicable laws or regulations, agreements with third-parties, third-party rights, or our Terms, Policies or Standards. 
                </li>
                <li>
                	Use the Damamma Platform or Collective Content for any commercial or other purposes that are not expressly permitted by these Terms or in a manner that falsely implies Damamma endorsement, partnership or otherwise misleads others to your affiliation with Damamma.  
                </li>
                <li>
                	Copy, store, or otherwise access or use any information, including personally identifiable information about any other Member, contained on the Damamma Platform in any way that is inconsistent with Damamma’s Privacy Policy or these Terms or that otherwise violates the privacy rights of Members or third parties.
                </li>
                <li>
                	Use the Damamma Platform in connection with the distribution of unsolicited commercial messages (“spam”). 
                </li>
                <li>
                	Contact another Member for any purpose other than asking questions related to your Request. 
                </li>
                <li>
                	Request, accept or make any payment for Service Fees outside of the Damamma Platform or Damamma Payments. If you do so, you acknowledge and agree that you: (i) would be in breach of these Terms; (ii) accept all risks and responsibility for such payment, and (ii) hold Damamma harmless from any liability for such payment. 
                </li>
                <li>
                	Discriminate against or harass anyone on the basis of race, national origin, religion, gender, gender identity, physical or mental disability, medication, marital status, age or sexual orientation, or otherwise engage in any violent, harmful, abusive or disruptive behaviour. 
                </li>
                <li>
                	Use, display, mirror or frame the Damamma Platform or Collective Content, or any individual element within the Damamma Platform, Damamma’s name, any Damamma trademark, logo or other proprietary information, or the layout and design of any page or form contained on a page in the Damamma Platform, without Damamma’s express written consent 
                </li>
                <li>
                	Dilute, tarnish or otherwise harm the Damamma brand in any way, including through unauthorised use of Collective Content, registration and/or using Damamma or derivative terms in domain names, trade names, trademarks or other source identifiers, or registering and/or using domain names, trade names, trademarks or other source identifiers that closely imitate or are confusingly similar to Damamma domains, trademarks, taglines, promotional campaigns or Collective Content. 
                </li>
                <li>
                	Use any robots, spider, crawler, scraper or other automated means or processes to access, collect data or other content from or otherwise interact with the Damamma Platform for any purpose. 
                </li>
                <li>
                	Attempt to decipher, decompile, disassemble or reverse engineer any of the software used to provide the Damamma Platform.  
                </li>
                <li>
                	Take any action that damages or adversely affect, or could damage or adversely affect the performance or proper functioning of the Damamma Platform. 
                </li>
                <li>
                	Violate or infringe anyone else’s rights or otherwise cause harm to anyone.
                </li>
            </ul>
            <p>
                11.2 You acknowledge that Damamma has no obligation to monitor the access to or use of the Damamma Platform by any Member or to review, disable access to, or edit any Member Content, but has the right to do so to (i) operate, secure and improve the Damamma Platform (including without limitation for fraud prevention, risk assessment, investigation and customer support purposes); (ii) ensure Members’ compliance with these Terms; (iii) comply with applicable law or the order or requirement of a court, law enforcement or other administrative agency or governmental body; (iv) respond to Member Content that it determines is harmful or objectionable; or (v) as otherwise set forth in these Terms. Members agree to cooperate with and assist Damamma in good faith, and to provide Damamma with such information and take such actions as may be reasonably requested by Damamma concerning any investigation undertaken by Damamma or a representative of Damamma regarding the use or abuse of the Damamma Platform.
            </p>
            <p>
                11.3 If you feel that any Member you interact with, whether online or in person, is acting or has acted inappropriately, including but not limited to anyone who (i) engages in offensive, violent or sexually inappropriate behaviour, (ii) you suspect of stealing from you, or (iii) engages in any other disturbing conduct, you should immediately report such person to the appropriate authorities and then to Damamma by contacting us with your police station and report number (if available). You agree that any report you make will not obligate us to take any action (beyond that required by law, if any). 
            </p>
            <h1>
                12. Terms, Terminations and Suspension
            </h1>
            <p>
                12.1 This Agreement shall be effective for a 30-day term, at the end of which it will automatically and continuously renew for subsequent 30-day terms until such time when you or Damamma terminate the Agreement per this provision. 
            </p>
            <p>
                12.2 You may terminate this Agreement at any time by sending us an email. If you cancel your Damamma Account as an Independent Partner, any confirmed requests(s) will be automatically cancelled, and your Consumers will receive a full refund. If you cancel your Damamma Account as an Independent Partner, any confirmed booking(s) will be automatically cancelled, and any refund will depend upon the terms of the cancellation policy.
            </p>
            <p>
                12.3 Unless your Country of Residence is the United States, without limiting our rights specified below, Damamma may terminate this Agreement for convenience at any time by giving you thirty (30) days' notice via email to your registered email address. 
            </p>
            <p>
                12.4 Damamma may immediately, without notice, terminate this Agreement and/or stop providing access to the Damamma Platform if (i) you have materially breached your obligations under these Terms, the Payments Terms, our Policies or Standards, (ii) you have violated applicable laws, regulations or third party rights, or (iii) Damamma believes in good faith that such action is reasonably necessary to protect the personal safety or property of Damamma, its Members, or third parties (for example in the case of fraudulent behaviour of a Member).
            </p>
            <p>
                12.5 In addition, Damamma may take any of the following measures (i) to comply with applicable law, or the order or request of a court, law enforcement or other administrative agency or governmental body, or if (ii) you have breached these Terms, the Payments Terms, our Policies or Standards, applicable laws, regulations, or third party rights, (iii) you have provided inaccurate, fraudulent, outdated or incomplete information during the Damamma Account registration, answering process or thereafter, (iv) you and/or your content at any time fail to meet any applicable quality or eligibility criteria, (v) you have repeatedly received poor Ratings or Reviews or Damamma otherwise becomes aware of or has received complaints about your performance or conduct, (vi) you have repeatedly cancelled confirmed requests or failed to respond to requests without a valid reason, or (vii) Damamma believes in good faith that such action is reasonably necessary to protect the personal safety or property of Damamma, its Members, or third parties, or to prevent fraud or other illegal activity:
            </p>
            <ul>
                <li>
                	Limit your access to or use of the Damamma Platform; 
                </li>
                <li>
                	Temporarily or permanently revoke any special status associated with your Damamma Account; 
                </li>
                <li>
                	Temporarily or in case of severe or repeated offenses permanently suspend your Damamma Account and stop providing access to the Damamma Platform.
                </li>
            </ul>
            <p>
                12.6 In case of non-material breaches and where appropriate, you will be given notice of any intended measure by Damamma and an opportunity to resolve the issue to Damamma’ reasonable satisfaction.
                If we take any of the measures described above (i) we may refund your Consumers in full for any and all requests that have been cancelled, irrespective of pre-existing cancellation policies, and (ii) you will not be entitled to any compensation for pending or confirmed Requests that were cancelled.
            </p>
            <p>
                12.7 When this Agreement has been terminated, you are not entitled to a restoration of your Damamma Account or any of your Member Content. If your access to or use of the Damamma Platform has been limited or your Damamma Account has been suspended, or we have terminated this Agreement, you may not register a new Damamma Account or access and use the Damamma Platform through a Damamma Account of another Member.
            </p>
            <h1>
               13. Disclaimers
            </h1>
            <p>
                13.1 You choose to use the Damamma Platform or Collective Content; you do so voluntarily and at your sole risk. The Damamma Platform and Collective Content is provided “as is”, without warranty of any kind, either express or implied.
            </p>
            <p>
                13.2 You agree that you have had whatever opportunity you deem necessary to investigate the Damamma Services, laws, rules, or regulations that may apply to your answers and/or answer services you are receiving and that you are not relying upon any statement of law or fact made by Damamma relating to an answer.
            </p>
            <p>
                13.3 If we choose to conduct identity verification or background checks on any Member, to the extent permitted by applicable law, we disclaim warranties of any kind, either express or implied, that such checks will identify prior misconduct by a Member or guarantee that a Member will not engage in misconduct in the future.
            </p>
            <p>
                13.4 The preceding disclaimers apply to the maximum extent permitted by law. You may have other statutory rights. However, the duration of statutorily required warranties, if any, shall be limited to the maximum extent permitted by law.
            </p>
            <h1>
                14. Liability
            </h1>
            <p>
                Damamma is liable under statutory provisions for intent and gross negligence by us, our legal representatives, directors, or other vicarious agents, insofar as their actions may be attributable to Damamma. The same applies to the assumption of guarantees or any other strict liability, or in case of a culpable injury to life, limb, or health. Damamma is liable for any negligent breaches of essential contractual obligations by us, our legal representatives, directors, or other vicarious agents, insofar as their actions may be attributable to Damamma. Essential contractual obligations are such duties of Damamma in whose proper fulfilment you regularly trust and must trust for the proper execution of the contract but the amount shall be limited to the typically occurring foreseeable damage. Any additional liability of Damamma is excluded.
            </p>
            <h1>
                15. Indemnification
            </h1>
            <p>
                You agree to release, defend (at Damamma’s option), indemnify, and hold Damamma and its affiliates and subsidiaries, including but not limited to, Damamma Payments, and their officers, directors, employees and agents, harmless from and against any claims, liabilities, damages, losses, and expenses, including, without limitation, reasonable legal and accounting fees, arising out of or in any way connected with (i) your breach of these Terms or our Policies or Standards, (ii) your improper use of the Damamma Platform or any Damamma Services, (iii) your interaction with any Member, Request, including without limitation any injuries, losses or damages (whether compensatory, direct, incidental, consequential or otherwise) of any kind arising in connection with or as a result of such interaction, participation or use, (iv) Damamma’s Collection and Remittance of Taxes, or (v) your breach of any laws, regulations or third party rights.
            </p>
            <h1>
                16. Feedback
            </h1>
            <p>
                We welcome and encourage you to provide feedback, comments and suggestions for improvements to the Damamma Platform (“Feedback“). You may submit Feedback by emailing us, through the “Contact us” section of the Damamma Platform, or by other means of communication. Any Feedback you submit to us will be considered non-confidential and non-proprietary to you. By submitting Feedback to us, you grant us a non-exclusive, worldwide, royalty-free, irrevocable, sub-licensable, perpetual license to use and publish those ideas and materials for any purpose, without compensation to you.
            </p>
            <h1>
                17. Applicable Law & Exclusive Jurisdiction
            </h1>
            <p>
                These Terms are governed by and construed in accordance with the laws of England and Wales. If you are acting as a Consumer or Independent Partner and if mandatory statutory consumer protection regulations in your country of residence contain provisions that are more beneficial for you, such provisions shall apply irrespective of the choice of English and Welsh law. As a Consumer or Independent Partner, you may bring any judicial proceedings relating to these Terms before the competent court of your place of residence or the competent court of Damamma’s place of business in England and Wales. If Damamma wishes to enforce any of its rights against you as a Consumer or Independent Partner, we may do so only in the courts of the jurisdiction in which you are a resident. If you are acting as a business, you agree to submit to the exclusive jurisdiction of the English and Welsh courts.
            </p>
            <h1>
                18. General Provisions
            </h1>
            <p>
                18.1  Except as they may be supplemented by additional terms and conditions, policies, guidelines or standards, these Terms constitute the entire Agreement between Damamma and you pertaining to the subject matter hereof, and supersede any and all prior oral or written understandings or agreements between Damamma and you in relation to the access to and use of the Damamma Platform.
            </p>
            <p>
                18.2 No joint venture, partnership, employment, or agency relationship exists between you and Damamma as a result of this Agreement or your use of the Damamma Platform.
            </p>
            <p>
                18.3 These Terms do not and are not intended to confer any rights or remedies upon any person other than the parties. 
            </p>
            <p>
                18.4 If any provision of these Terms is held to be invalid or unenforceable, such provision will be struck and will not affect the validity and enforceability of the remaining provisions.
            </p>
            <p>
                18.5 Damamma’s failure to enforce any right or provision in these Terms will not constitute a waiver of such right or provision unless acknowledged and agreed to by us in writing. Except as expressly outlined in these Terms, the exercise by either party of any of its remedies under these Terms will be without prejudice to its other remedies under these Terms or otherwise permitted under law.
            </p>
            <p>
                18.6 You may not assign, transfer or delegate this Agreement and your rights and obligations hereunder without Damamma’s prior written consent. Damamma may without restriction assign, transfer or delegate this Agreement and any rights and obligations hereunder, at its sole discretion, with 30 days prior notice. Your right to terminate this Agreement at any time remains unaffected.
            </p>
            <p>
                18.7 Unless specified otherwise, any notices or other communications to Members permitted or required under this Agreement, will be provided electronically and given by Damamma via email, Damamma Platform notification, or messaging service (including SMS, WhatsApp and WeChat).
            </p>
            <p>
                18.8 If you have any questions about these Terms, please email us.
            </p>
            {
                isMobile === true ?
                <Footer/>
                :
                <FooterDesktop/>
            }
        </div>
    )
}

export default TermsAndConditions;
