import React from 'react';
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { quidIndex, declineQuidModal } from './actions';
import { colour, fontFamily, ActionButtonShort, DesktopDefaultButton } from './GlobalStyles';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import { isMobile } from "react-device-detect";
import VideocamIcon from '@material-ui/icons/Videocam';
import * as firebase from 'firebase/app';
import '@firebase/firestore';
import Modal from 'react-modal';

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

  function CreatorProfile() {

    const currentWidth = useSelector(state => state.width);
    const currentHeight = useSelector(state => state.height);
    const newQuestions = useSelector(state => state.newQuestions);
    const totalEarningsData = useSelector(state => state.totalEarnings);
    const currentUserId = useSelector(state => state.userId);
    const questionIndex = useSelector(state => state.quidIndex);
    const declineModal = useSelector(state => state.declineQuidModal);
    const dispatch = useDispatch();
    let history = useHistory();

    function declineQuid() {
        
        firebase.firestore().collection("Creator Profile").doc(newQuestions[questionIndex].userId)
            .onSnapshot(snapshot => {
                if (!snapshot.exists) {
                    console.log('No such document!')
                }
                else {
                    const data = snapshot.data();
                    const askedQuestions = data.askedQuestions;

                    if ( newQuestions.length !== 0 ) {
                        const index = askedQuestions.map((e) => { return e.id; }).indexOf(newQuestions[questionIndex].id);
                        if ( index !== -1 ) {
                            askedQuestions[index].answer = "Rejection";
                            firebase.firestore().collection("Creator Profile").doc(newQuestions[questionIndex].userId).set({
                                askedQuestions
                            }, {merge: true})
                        }
                    }
                }
            })

        newQuestions.splice(questionIndex, 1)
        firebase.firestore().collection("Creator Profile").doc(currentUserId).set({
            newQuestions
        }, {merge: true})
        dispatch(quidIndex(0))

        alert("Quid declined");
        dispatch(declineQuidModal(false));
    }

    function acceptQuid() {
        if ( isMobile === true && currentUserId !== "" ) {
            history.push('../recordAnswer');
        }
        else {
            alert("Oopps..you need to be on mobile to respond to a question!");
        }
    }

    const styles = {
        container: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: currentHeight * 0.25
        },
        creatorImage: {
            width: currentWidth * 0.14,
            height: currentWidth * 0.14,
            borderRadius: currentWidth * 0.07
        },
        centre: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        },
        swiper: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        },
        border: {
            maxWidth: isMobile === true ? currentWidth * 0.7 : currentWidth * 0.25,
            borderRadius: '30px',
            backgroundColor: colour.light,
        },
        questionContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
        },
        user: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            paddingLeft: isMobile === true ? currentWidth * 0.1 : currentWidth * 0.02,
            paddingTop: currentHeight * 0.02,
            alignItems: 'center'
        },
        userImage: {
            width: currentWidth * 0.05,
            height: currentWidth * 0.05,
            borderRadius: currentWidth * 0.025
        },
        question: {
            paddingLeft: isMobile === true ? currentWidth * 0.1 : currentWidth * 0.02,
            paddingTop: currentHeight * 0.02,
            width: isMobile === true ? currentWidth * 0.6 : currentWidth * 0.2
        },
        arrowIcon: {
            color: "#A3A3A3",
            fontSize: isMobile === true ? 20 : 40,
            cursor: 'pointer'
        },
        earnQuid: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            alignItems: 'center',
        },
        modal: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: currentHeight * 0.7
        },
        modalWidth: {
            width: currentWidth * 0.6
        },
        questionModal: {
            padding: currentWidth * 0.1
        }
    }

    const customStyles = {
        content : {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
        }
      };

    return (
        <div>
            {
                declineModal === true ?
                <Modal
                    isOpen={declineModal}
                    style={customStyles}
                    //onAfterOpen={afterOpenModal}
                    //onRequestClose={closeModal}
                    //style={customStyles}
                    //contentLabel="Example Modal"
                >
                    <div style={styles.modal}>
                        <div style={styles.modalWidth}>
                            <h3 style={{ fontFamily: fontFamily, color: colour.dark, fontSize: '1.375em', fontWeight: 'bold', textAlign: 'center' }}>
                                Are you sure you want to decline this quid?
                            </h3>
                        </div>
                        <div style={styles.questionModal}>
                            <p
                                style={{ fontFamily: fontFamily, color: colour.med, fontSize: '1em', fontWeight: 'lighter' }}
                            >
                                {newQuestions[questionIndex].question}
                            </p>
                        </div>
                        <DesktopDefaultButton
                            onClick={() => declineQuid()}
                        >
                            Decline
                        </DesktopDefaultButton>
                        <div style={{ height: currentHeight * 0.05 }} />
                        <p 
                            onClick={() => dispatch(declineQuidModal(false))}
                            style={{ fontFamily: fontFamily, color: colour.med, fontSize: '1.125em', fontWeight: 'bold', textAlign: 'center' }}
                        >
                            Cancel
                        </p>
                    </div>  
                </Modal>
                :
                <div>
                     <div style={{ height: currentHeight * 0.03 }} />   
                    <div style={styles.centre}>
                        <h3 style={{ fontSize: '1.375em', fontWeight: 'bold', color: colour.dark, fontFamily: fontFamily }}>
                            £{totalEarningsData}{Number.isInteger(totalEarningsData) === true ? ".00" : "0"}
                        </h3>
                    </div>
                    <div style={{ height: currentHeight * 0.03 }} />     
                    <Swiper
                        spaceBetween={currentWidth * 0.02}
                        navigation
                        slidesPerView={isMobile === true ? 1 : 2}
                        onSlideChange={(slide) => dispatch(quidIndex(slide.activeIndex))}
                        onSwiper={(swiper) => console.log(swiper)}
                    >
                        {
                            newQuestions.map((item, index) =>
                                <SwiperSlide>
                                    <div style={styles.swiper}>
                                        <div style={styles.border}>
                                            <div style={styles.user}>
                                                <img
                                                    src={item.userName === 'anonymous' ? require('./anonymous.svg') : item.userImage === "" ? require('./anonymous.svg') : item.userImage}
                                                    style={styles.userImage}
                                                    alt="userImage"
                                                />
                                                <div style={{ width: currentWidth * 0.02 }} />
                                                <p style={{ color: colour.primaryMuted, fontSize: '0.75em', fontFamily: fontFamily, fontWeight: '500' }}>
                                                    @{item.userName}{" "}
                                                    <span style={{ color: colour.med, fontSize: '1em', fontFamily: fontFamily, fontWeight: '500' }}>
                                                        asked
                                                    </span>
                                                </p>
                                            </div>
                                            <div style={{ height: currentHeight * 0.02 }} />  
                                            <div style={styles.question}>
                                                <p style={{ fontSize: isMobile === true ? '0.875em' : '1em', fontWeight: 'bold', fontFamily: fontFamily, color: colour.med }}>
                                                    {item.question}
                                                </p>
                                            </div>
                                            <div style={{ height: currentHeight * 0.05 }} /> 
                                        </div>
                                    </div>
                                </SwiperSlide>
                            )
                        }
                    </Swiper>
                    <div style={{ height: currentHeight * 0.02 }} />
                    {
                        newQuestions.length === 0 ?
                        null
                        :
                        isMobile === false ?
                        <p style={{ fontSize: 24, color: colour.med, fontFamily: fontFamily, fontWeight: 'lighter', textAlign: 'center' }}>
                            You must be on mobile to accept or decline quidvids
                        </p>
                        :
                        newQuestions[questionIndex].credit === true ?
                        <div style={styles.earnQuid}>
                            <h2 
                                onClick={() => dispatch(declineQuidModal(true))}
                                style={{ fontSize: '1.125em', cursor: 'pointer', color: colour.med, fontWeight: 'bold' }}
                            >
                                decline quid
                            </h2>
                            <ActionButtonShort
                                onClick={() => acceptQuid()}
                            >
                                Free
                                <div style={{ width: currentWidth * 0.02 }} />
                                <VideocamIcon
                                    style={{ color: '#FFFFFF', fontSize: 40 }}
                                />
                            </ActionButtonShort>
                        </div>
                        :
                        <div style={styles.earnQuid}>
                            <h2 
                                onClick={() => dispatch(declineQuidModal(true))}
                                style={{ fontSize: '1.125em', cursor: 'pointer', color: colour.med, fontWeight: 'bold' }}
                            >
                                decline quid
                            </h2>
                            <ActionButtonShort
                                onClick={() => acceptQuid()}
                            >
                                +£1 for
                                <div style={{ width: currentWidth * 0.02 }} />
                                <VideocamIcon
                                    style={{ color: '#FFFFFF', fontSize: 40 }}
                                />
                            </ActionButtonShort>
                        </div>
                    }
                </div>
            }  
        </div>
    )
  }

  export default CreatorProfile;