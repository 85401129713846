import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { width, height, userName, auth, userId } from './actions';
import * as firebase from 'firebase/app';
import '@firebase/firestore';
import Header from './Header';
import Footer from './Footer';
import LandingComponent from './LandingComponent';
import LandingData from './LandingData';
import FooterDesktop from './FooterDesktop';
import { isMobile } from 'react-device-detect';
//import axios from 'axios';

function App() {

  const currentHeight = useSelector(state => state.height);
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, []);

  useEffect(() => {

    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        stableDispatch(auth(true));
        stableDispatch(userId(user.uid));

        firebase.firestore().collection("Creator Profile").doc(user.uid)
        .onSnapshot(snapshot => {
          if (!snapshot.exists) {
            console.log("No such document!")
          }
          else {
            const data = snapshot.data();
            const currentUserName = data.userName;
            stableDispatch(userName(currentUserName));
          }
        })
      }
      else {
        stableDispatch(auth(false));
        stableDispatch(userId(""));
        stableDispatch(userName(""));
      }
    })

    function resizeWindow () {
      const newWidth = window.innerWidth;
      const newHeight = window.innerHeight;
      stableDispatch(width(newWidth));
      stableDispatch(height(newHeight));
    };
    window.addEventListener("resize", resizeWindow);
    return () => window.removeEventListener("resize", resizeWindow);
  }, [stableDispatch]);

  //   function getGeoInfo() {
  //     axios.get('https://ipapi.co/json/').then((response) => {
  //         let data = response.data;
  //         alert(`Country Name = ${data.country_name}`);
  //         alert(`Country Name = ${data.country_calling_code}`);
  //     }).catch((error) => {
  //         console.log(error);
  //     });
  // };

  return (
    <div>
      <Header/>
      <LandingComponent/>
      <div style={{ height: currentHeight * 0.07 }} />
      <LandingData />
      {
        isMobile === true ?
        <Footer />
        :
        <FooterDesktop />
      }
    </div>
  );
}

export default App;