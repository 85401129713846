import React, { useRef, useEffect, useCallback } from 'react';
import { colour, fontFamily } from './GlobalStyles';
import VideoRecorder from 'react-video-recorder';
import { newVideoBlob, videoUpload, feedData, askedQuestionsList, askedQuestionIndex } from './actions';
import { useDispatch, useSelector } from 'react-redux';
import * as firebase from 'firebase/app';
import '@firebase/firestore';
import { useHistory } from "react-router-dom";
import Lottie from 'react-lottie-player';
import lottieJson from './money-loading-animation.json';
import { isAndroid } from 'react-device-detect';

function RecordAnswer() {

    const currentVideoBlob = useSelector(state => state.newVideoBlob);
    const videoUploadTime = useSelector(state => state.videoUpload);
    const currentWidth = useSelector(state => state.width);
    const currentHeight = useSelector(state => state.height);
    const questionIndex = useSelector(state => state.quidIndex);
    const currentUserId = useSelector(state => state.userId);
    const newQuestions = useSelector(state => state.newQuestions);
    const answers = useSelector(state => state.answeredQuestions);
    const currentUserName = useSelector(state => state.userName);
    const currentUserPicture = useSelector(state => state.userPicture);
    const askedQuestions = useSelector(state => state.askedQuestionsList);
    const currentAskedQuestionIndex = useSelector(state => state.askedQuestionIndex)
    const totalQuidvidsData = useSelector(state => state.totalQuidvids);
    const totalEarnings = useSelector(state => state.totalEarnings);
    const currency = useSelector(state => state.paymentCurrency);
    const currentFeedData = useSelector(state => state.feedData);
    const dispatch = useDispatch();
    const stableDispatch = useCallback(dispatch, []);
    let history = useHistory();
    const videoRef = useRef(null);

    useEffect(() => {

        if ( newQuestions.length === 0 ) {
            history.push('../home');
        }
        else {
            firebase.firestore().collection("Feed").doc("Data")
            .onSnapshot(snapshot => {
                if (!snapshot.exists) {
                    console.log('No such document!')
                }
                else {
                    const data = snapshot.data();
                    const feedEntries = data.feed;
                    stableDispatch(feedData(feedEntries)); 
                }
            })

            firebase.firestore().collection("Creator Profile").doc(newQuestions[questionIndex].userId)
            .onSnapshot(snapshot => {
                if (!snapshot.exists) {
                    console.log('No such document!')
                }
                else {
                    const data = snapshot.data();
                    const questions = data.askedQuestions;

                    if ( newQuestions.length !== 0 && questionIndex !== newQuestions.length ) {
                        if ( questionIndex > newQuestions.length ) {

                        }
                        else {
                            const index = questions.map((e) => { return e.id; }).indexOf(newQuestions[questionIndex].id);
                            if ( index !== -1 ) {
                                stableDispatch(askedQuestionsList(questions));
                                stableDispatch(askedQuestionIndex(index));
                            }
                        }
                    }
                }
            })
        }

        console.log(videoRef.current);
      }, [videoRef, stableDispatch, history, newQuestions, questionIndex, currentUserId]);

    function retake() {
        videoRef.current.handleStopRecording();
        videoRef.current.handleOpenVideoInput();
    }

    function record() {
        if ( isAndroid === true ) {
            uploadData();
        }
        else {
            videoRef.current.handleOpenVideoInput();
        }
    }

    const onChange = async (event) => {
        event.stopPropagation();
        event.preventDefault();
        const file = URL.createObjectURL(event.target.files[0]);
        const type = event.target.files[0].type;
        
        if ( type.includes("video") ) {
           
            const code = Math.random().toString(36).substring(7);
            const response = await fetch(file);
            const blob = await response.blob();
        
            const ref = firebase.storage().ref(`Creator Videos/${currentUserId}`).child(code);
            const uploadTask = ref.put(blob);
            new Promise((resolve, reject) => {
                uploadTask.on(
                    'state_changed',
                    (snapshot) => {
                    const progress = snapshot.bytesTransferred / snapshot.totalBytes * 100;
                    console.log('Upload is ' + progress + '% done');
                    dispatch(videoUpload(progress));
                    },
                    (error) => {
                    reject(error);  // added this line
                    console.log(error)
                    },
                    () => {
                    queryVideo(code);
                    resolve();  // added this line
                    }
                );
            });
        }
        else {
            alert("Please select a video ;)")
        }
    }

    function makeId(length, url) {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
           result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }

        const resultIndex = currentFeedData.map((e) => { return e.code; }).indexOf(result);
        if ( resultIndex !== -1 ) {
            makeId(length, url);
        }
        else if ( newQuestions[questionIndex].credit === true ) {
            freeQuestion(url, result);
        }
        else {
            charge(url, result);
        }
    }

    function freeQuestion(url, result) {
        dispatch(videoUpload(""));
                    alert("Free question answered!");

                    askedQuestions[currentAskedQuestionIndex].answer = url;
                    askedQuestions[currentAskedQuestionIndex].code = result;
                    firebase.firestore().collection("Creator Profile").doc(newQuestions[questionIndex].userId).set({
                        askedQuestions
                    }, {merge: true})

                    const db = firebase.firestore();
                    const feedRef = db.collection("Feed").doc("Data");
        
                    db.runTransaction((transaction) => {
                        return transaction.get(feedRef).then((doc) => {
                            if (!doc.exists) {
                                console.log("Document does not exist!");
                            }
                            const feed = doc.data().feed;
                            const newFeedPost = {
                                creatorImage: currentUserPicture,
                                creatorUserId: currentUserId,
                                creatorName: currentUserName,
                                creatorVideo: url,
                                totalQuidvids: totalQuidvidsData + 1,
                                userName: newQuestions[questionIndex].userName,
                                userPhoto: newQuestions[questionIndex].userImage,
                                userQuestion: newQuestions[questionIndex].question,
                                userUserId: newQuestions[questionIndex].userId,
                                code: result
                            }
                            feed.unshift(newFeedPost);
                            
                            transaction.update(feedRef, 
                                { 
                                    feed
                                });
                            return feed;
                          
                        });
                    }).then(() => {
                        console.log("Promise compiled with success");

                        const newAnswer = {
                            date: new Date(),
                            question: newQuestions[questionIndex].question,
                            questioner: newQuestions[questionIndex].userName,
                            questionerImage: newQuestions[questionIndex].userImage,
                            questionerUserId: newQuestions[questionIndex].userId,
                            creatorVideo: url,
                            code: result
                        }
                        answers.unshift(newAnswer);
    
                        newQuestions.splice(questionIndex, 1)
                        firebase.firestore().collection("Creator Profile").doc(currentUserId).set({
                            newQuestions,
                            answers,
                            totalQuidvids: totalQuidvidsData + 1,
                            totalEarnings: totalEarnings
                        }, {merge: true})
                        dispatch(videoUpload(""));
                        dispatch(newVideoBlob(""));
                        history.goBack();
                    }).catch((err) => {
                        console.error(err);
                    });
    }

    function charge(url, result) {

        if ( newQuestions[questionIndex].paymentMethod !== undefined && newQuestions[questionIndex].customerId !== undefined ) {
            dispatch(videoUpload("charging card"));
            firebase.firestore().collection("Stripe_Customers").doc(currentUserId).collection("Charges").doc("Recent").set({
                amount: 100,
                customerId: newQuestions[questionIndex].customerId,
                paymentMethod: newQuestions[questionIndex].paymentMethod,
                currency: currency
            })
  
            firebase.firestore().collection("Stripe_Customers").doc(currentUserId).collection("Charges").doc("Recent").delete();

            firebase.firestore().collection("Stripe_Customers").doc(currentUserId).collection("Success").doc("Success")
            .onSnapshot(snapshot => {
              if (!snapshot.exists) {
                console.log("No such document!")
              }
              else {
                const data = snapshot.data();
                const paymentIntent = data.paymentIntent;
                const status = paymentIntent.status;
                
                if ( status === "succeeded" ) {
                    dispatch(videoUpload(""));
                    alert("You just earned £1!");
                    firebase.firestore().collection("Stripe_Customers").doc(currentUserId).collection("Success").doc("Success").delete();   

                    askedQuestions[currentAskedQuestionIndex].answer = url;
                    askedQuestions[currentAskedQuestionIndex].code = result;
                    firebase.firestore().collection("Creator Profile").doc(newQuestions[questionIndex].userId).set({
                        askedQuestions
                    }, {merge: true})

                    const db = firebase.firestore();
                    const feedRef = db.collection("Feed").doc("Data");
        
                    db.runTransaction((transaction) => {
                        return transaction.get(feedRef).then((doc) => {
                            if (!doc.exists) {
                                console.log("Document does not exist!");
                            }
                            const feed = doc.data().feed;
                            const newFeedPost = {
                                creatorImage: currentUserPicture,
                                creatorUserId: currentUserId,
                                creatorName: currentUserName,
                                creatorVideo: url,
                                totalQuidvids: totalQuidvidsData + 1,
                                userName: newQuestions[questionIndex].userName,
                                userPhoto: newQuestions[questionIndex].userImage,
                                userQuestion: newQuestions[questionIndex].question,
                                userUserId: newQuestions[questionIndex].userId,
                                code: result
                            }
                            feed.unshift(newFeedPost);
                            
                            transaction.update(feedRef, 
                                { 
                                    feed
                                });
                            return feed;
                          
                        });
                    }).then(() => {
                        console.log("Promise compiled with success");

                        const newAnswer = {
                            date: new Date(),
                            question: newQuestions[questionIndex].question,
                            questioner: newQuestions[questionIndex].userName,
                            questionerImage: newQuestions[questionIndex].userImage,
                            questionerUserId: newQuestions[questionIndex].userId,
                            creatorVideo: url,
                            code: result
                        }
                        answers.unshift(newAnswer);
    
                        newQuestions.splice(questionIndex, 1)
                        firebase.firestore().collection("Creator Profile").doc(currentUserId).set({
                            newQuestions,
                            answers,
                            totalQuidvids: totalQuidvidsData + 1,
                            totalEarnings: totalEarnings + 1
                        }, {merge: true})
                        dispatch(videoUpload(""));
                        dispatch(newVideoBlob(""));
                        history.goBack();
                    }).catch((err) => {
                        console.error(err);
                    });
                }
                else if ( status === "error" ){
                    dispatch(videoUpload(""));
                    alert("Oopps, we're sorry..your customer's card was declined. We have deleted their question, and you will be navigated back to your profile :(");
                    firebase.firestore().collection("Stripe_Customers").doc(currentUserId).collection("Success").doc("Success").delete();

                    askedQuestions[currentAskedQuestionIndex].answer = "Error";
                    askedQuestions[currentAskedQuestionIndex].errorMessage = paymentIntent;
                    firebase.firestore().collection("Creator Profile").doc(newQuestions[questionIndex].userId).set({
                        askedQuestions
                    }, {merge: true})

                    newQuestions.splice(questionIndex, 1)
                    firebase.firestore().collection("Creator Profile").doc(currentUserId).set({
                        newQuestions,
                    }, {merge: true})
                    history.goBack();
                }
                else {
                    dispatch(videoUpload(""));
                    alert("Oopps, we're sorry..your customer's card was declined. We have deleted their question, and you will be navigated back to your profile :(");
                    firebase.firestore().collection("Stripe_Customers").doc(currentUserId).collection("Success").doc("Success").delete();

                    askedQuestions[currentAskedQuestionIndex].answer = "Error";
                    askedQuestions[currentAskedQuestionIndex].errorMessage = paymentIntent;
                    firebase.firestore().collection("Creator Profile").doc(newQuestions[questionIndex].userId).set({
                        askedQuestions
                    }, {merge: true})

                    newQuestions.splice(questionIndex, 1)
                    firebase.firestore().collection("Creator Profile").doc(currentUserId).set({
                        newQuestions,
                    }, {merge: true})
                    history.goBack();
                }
              }
            })
        }
        else {
            alert("Ooppss.. we're sorry but your customer does not have a card on file. Their question has been deleted.");
            newQuestions.splice(questionIndex, 1)
            firebase.firestore().collection("Creator Profile").doc(currentUserId).set({
                newQuestions,
            }, {merge: true})
            history.goBack();
        }
    }

    function uploadData() {
        textInput.current.click();
    }

    const textInput = useRef(null);

    const submitVideo = async () => {
        const code = Math.random().toString(36).substring(7);
        const blob = currentVideoBlob
    
        const ref = firebase.storage().ref(`Creator Videos/${currentUserId}`).child(code);
        const uploadTask = ref.put(blob);
        new Promise((resolve, reject) => {
            uploadTask.on(
                'state_changed',
                (snapshot) => {
                const progress = snapshot.bytesTransferred / snapshot.totalBytes * 100;
                console.log('Upload is ' + progress + '% done');
                dispatch(videoUpload(progress));
                },
                (error) => {
                reject(error);  // added this line
                console.log(error)
                },
                () => {
                queryVideo(code);
                resolve();  // added this line
                }
            );
            });
      }
    
      const queryVideo = (imageName) => {
    
        const creatorVideos = firebase.storage().ref(`Creator Videos/${currentUserId}`).child(imageName);   
        creatorVideos.getDownloadURL()
            .then((url) => {
                                makeId(15, url);
                            })
            .catch(err => {
                            console.log('Error getting creator video', err);
                            dispatch(videoUpload(""));
                            dispatch(newVideoBlob(""));
                        })
    }

    const styles = {
        video: {
            height: currentHeight, 
            width: currentWidth
        },
        uploadTime: {
            position: 'absolute',
            zIndex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            bottom: currentHeight * 0.4,
            left: 0
        },
        submit: {
            position: 'absolute',
            zIndex: 1,
            bottom: currentHeight * 0.05,
            left: currentWidth * 0.7,
            cursor: 'pointer'
        },
        upload: {
            width: currentWidth * 0.3
        },
        retakeIcon: {
            width: currentWidth * 0.1
        },
        retake: {
            position: 'absolute',
            zIndex: 1,
            bottom: currentHeight * 0.07,
            left: currentWidth * 0.02,
            backgroundColor: colour.dark,
            width: currentWidth * 0.2,
            height: currentWidth * 0.2,
            borderRadius: currentWidth * 0.1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer'
        },
        uploadVideo: {
            position: 'absolute',
            zIndex: 1,
            bottom: currentHeight * 0.02,
            left: currentWidth * 0.02,
            backgroundColor: colour.dark,
            width: currentWidth * 0.2,
            height: currentWidth * 0.2,
            borderRadius: currentWidth * 0.1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer'
        },
        takeVideo: {
            backgroundColor: colour.primary,
            width: currentWidth * 0.4,
            borderRadius: currentWidth * 0.02,
            height: currentHeight * 0.1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            position: 'absolute',
            zIndex: 1,
            bottom: currentHeight * 0.2,
            left: currentWidth * 0.3
        },
        loading: {
            width: currentWidth
        }
    }
  
    return (
        <div style={styles.video}>
            <div
                ref={el => { console.log(el); videoRef.current = el; }}
            />
            <input type='file' id='file' ref={textInput} style={{ display: 'none' }} onChange={onChange} />   
            <VideoRecorder 
                onRecordingComplete={(videoBlob) => {
                    dispatch(newVideoBlob(videoBlob));
                }}
                style={{ height: currentHeight, width: currentWidth }}
                ref={el => { console.log(el); videoRef.current = el; }}
            />
            {
                videoUploadTime === "" ?
                null
                :
                <div style={styles.uploadTime}>
                    <p style={{ color: colour.light, textAlign: 'center', fontWeight: 'bold', fontSize: '1.375em', fontFamily: fontFamily, position: 'absolute', zIndex: 1, top: currentHeight * 0.2 }}>
                        {videoUploadTime === "charging card" ? "Charging customer card..." : videoUploadTime.toFixed(0) + '%'}
                    </p>
                    <Lottie
                        loop
                        animationData={lottieJson}
                        play
                        style={styles.loading}
                    />
                </div>
            }
            {
                currentVideoBlob === "" ?
                <div 
                    style={styles.takeVideo}
                    onClick={() => record()}
                >
                    <p style={{ color: colour.dark, textAlign: 'center', fontWeight: 'bold', fontSize: '1.375', fontFamily: fontFamily }}>
                        Record Video
                    </p>
                </div>
                :
                <div style={styles.submit}>
                    <img
                        src={require('./send-video.svg')}
                        style={styles.upload}
                        alt="upload"
                        onClick={() => submitVideo()}
                    />
                </div>
            }
            {
                currentVideoBlob === "" ?
                <div 
                    onClick={() => uploadData()}
                    style={styles.uploadVideo}>
                    <p 
                        style={{ color: colour.light, fontFamily: fontFamily, fontSize: '0.75em', fontWeight: 'bold', textAlign: 'center' }}
                    >
                        Upload
                    </p>
                </div>
                :
                <div style={styles.retake}>
                    <img
                        src={require('./back.svg')}
                        style={styles.retakeIcon}
                        alt="upload"
                        onClick={() => retake()}
                    />
                    <p style={{ color: colour.light, fontFamily: fontFamily, fontSize: '0.75em', fontWeight: 'bold', textAlign: 'center' }}>
                        Retake
                    </p>
                </div>
            }
        </div>
    )
}

export default RecordAnswer;