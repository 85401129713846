import React, { useEffect, useCallback } from 'react';
import Header from './Header';
import { useSelector, useDispatch } from 'react-redux';
import { isMobile } from 'react-device-detect';
import Footer from './Footer';
import DesktopFooter from './FooterDesktop';
import { colour, fontFamily, DefaultButton, DesktopDefaultButton } from './GlobalStyles';
import { feedData, authenticationDisabledButton, watchCode, auth, userId, userName, profileUserId, copyToClipboard, viewingProfileUserPicture, profileUserName } from './actions';
import * as firebase from 'firebase/app';
import '@firebase/firestore';
import ReactPlayer from 'react-player';
import { useHistory } from 'react-router-dom';
import Lottie from 'react-lottie-player';
import lottieJson from './loading-animation.json';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FacebookShareButton, FacebookIcon, WhatsappShareButton, WhatsappIcon, TwitterShareButton, TwitterIcon } from "react-share";

function Watch({ match }) {

    const currentHeight = useSelector(state => state.height);
    const currentWidth = useSelector(state => state.width);
    const loading = useSelector(state => state.authenticationDisabledButton);
    const videoCode = useSelector(state => state.watchCode);
    const currentFeedData = useSelector(state => state.feedData);
    const currentUserName = useSelector(state => state.userName);
    const isCopied = useSelector(state => state.copyToClipboard);
    const dispatch = useDispatch();
    const stableDispatch = useCallback(dispatch, []);
    let history = useHistory();
    const videoURL = match.params.video;
    const url = window.location.href;

    useEffect(() => {

        firebase.auth().onAuthStateChanged(user => {
            if (user) {
                stableDispatch(auth(true));
                stableDispatch(userId(user.uid));

                firebase.firestore().collection("Creator Profile").doc(user.uid)
                .onSnapshot(snapshot => {
                    if(!snapshot.exists) {
                        console.log('No such document!')
                    }
                    else {
                        const data = snapshot.data();
                        const creatorUserName = data.userName;
                        stableDispatch(userName(creatorUserName));
                    }
                })
            }
            else {
                stableDispatch(auth(false));
                stableDispatch(userId(""));
            }
        })

        const feedQuery = firebase.firestore().collection("Feed").doc("Data");
        feedQuery.get()
        .then((doc) => {
            if(!doc.exists) {
                console.log('No such document!');
            }
            else {
                const data = doc.data();
                const feed = data.feed;
                const codeIndex = feed.map((e) => { return e.code; }).indexOf(videoURL);
                if ( codeIndex !== -1 ) {
                    stableDispatch(watchCode(codeIndex));
                }
                else {
                    history.push('../error');
                }
                stableDispatch(feedData(feed));
            }
        })
        .catch((err) => {
            console.log('Error =', err);
        })


    }, [stableDispatch, videoURL, history])

    const onBuffer = () => {
        dispatch(authenticationDisabledButton(true));
    }
    
    const onBufferEnd = () => { 
        dispatch(authenticationDisabledButton(false))
    }

     function navigate(path) {

        if ( videoCode === null ) {

        }
        else if ( currentFeedData.length === 0 ) {

        }
        else if ( path !== "anonymous" ) {
            history.push(`../profile/${path}`);
        }
    }

    function anotherQuestion() {
        dispatch(profileUserId(currentFeedData[videoCode].creatorUserId));
        dispatch(viewingProfileUserPicture(currentFeedData[videoCode].creatorImage));
        dispatch(profileUserName(currentFeedData[videoCode].creatorName));
        history.push('../question'); 
    }

    const styles = {
        centre: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: colour.lightestGray
        },
        row: {
            display: 'flex',
            flexDirection: 'row',
            backgroundColor: "#FFFFFF",
            width: currentWidth * 0.9,
            borderRadius: currentWidth * 0.02,
            padding: currentWidth * 0.02
        },
        sections: {
            width: currentWidth * 0.45
        },
        userPhoto: {
            width: isMobile === true ? currentWidth * 0.1 : currentWidth * 0.03,
            height: isMobile === true ? currentWidth * 0.1 : currentWidth * 0.03,
            borderRadius: isMobile === true ? currentWidth * 0.1 : currentWidth * 0.015,
        },
        centreRow: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
        },
        questioner: {
            color: colour.primaryMuted, 
            fontSize: isMobile === true ? '0.75em' : 24, 
            fontFamily: fontFamily, 
            fontWeight: '500', 
            cursor: 'pointer'
        },
        creatorImage: {
            width: isMobile === true ? currentWidth * 0.1 : currentWidth * 0.05,
            height: isMobile === true ? currentWidth * 0.1 : currentWidth * 0.05,
            borderRadius: isMobile === true ? currentWidth * 0.1 : currentWidth * 0.025,
        },
        creator: {
            fontWeight: isMobile === true ? '500' : 'bold', 
            fontSize: isMobile === true ? '1em' : 26, 
            color: colour.primaryMuted, 
            cursor: 'pointer'
        },
        question: {
            height: isMobile === true ? currentHeight * 0.5 : currentHeight * 0.3
        },
        quidvidTotal: {
            fontSize: isMobile === true ? '0.75em' : 28, 
            color: colour.med, 
            fontFamily: fontFamily,
            fontWeight: 'lighter'
        },
        loading: {
            width: currentWidth * 0.2,
            position: 'absolute',
            zIndex: 1,
            right: currentWidth * 0.2
        },
        questionText: {
            color: colour.dark, 
            fontSize: isMobile === false ? 30 : currentHeight > 600 ? '1em' : currentFeedData.length === 0 ? '1em' : videoCode === null ? '1em' : currentFeedData[videoCode].userQuestion.length < 200 ? '0.875em' : '0.75em', 
            fontFamily: fontFamily, 
            fontWeight: 'bold', 
            lineHeight: currentHeight > 600 ? '1.125em' : '1em'
        },
        copyLink: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#E5E5E5',
            width: isMobile === true ? currentWidth * 0.16 : currentWidth * 0.04,
            height: isMobile === true ? currentWidth * 0.16 : currentWidth * 0.04,
            borderRadius: isMobile === true ? currentWidth * 0.08 : currentWidth * 0.02
          },
    }

    return (
        <div>
            <Header />
            <div style={styles.centre}>
                <div style={{ height: currentHeight * 0.03 }} />
                <div style={styles.row}>
                    <div style={styles.sections}>
                        <div style={styles.centreRow}>
                            <img
                                src={currentFeedData.length === 0 ? null : videoCode === null ? require('./anonymous.svg') : currentFeedData[videoCode].creatorImage === "" ? require('./anonymous.svg') : currentFeedData[videoCode].creatorImage}
                                style={styles.userPhoto}
                                alt=""
                            />
                            <div style={{ width: currentWidth * 0.01 }} />
                            <p 
                                onClick={() => navigate(currentFeedData[videoCode].userName)}
                                style={styles.questioner}
                            >
                                {
                                    videoCode === null ?
                                    null
                                    :
                                    currentFeedData.length === 0 ? 
                                    null 
                                    :
                                    currentFeedData[videoCode].userName + "  "
                                }
                                <span style={{ color: colour.med, fontSize: '1em', fontFamily: fontFamily }}>
                                    asked
                                </span>
                            </p>
                        </div>
                        <div style={{ height: currentHeight * 0.05 }} />
                        <div style={styles.question}>
                            {
                                videoCode === null ?
                                null
                                :
                                currentFeedData.length === 0 ? 
                                null 
                                :
                                <h1 style={styles.questionText}>
                                    {currentFeedData[videoCode].userQuestion}
                                </h1>
                            }
                        </div>
                        <div style={styles.centreRow}>
                            <div>
                                <img
                                    src={currentFeedData.length === 0 ? null : videoCode === null ? require('./anonymous.svg') : currentFeedData[videoCode].creatorImage === "" ? require('./anonymous.svg') : currentFeedData[videoCode].creatorImage}
                                    style={styles.creatorImage}
                                    alt=""
                                />
                            </div>
                            <div style={{ width: currentWidth * 0.01 }} />
                            {
                                videoCode === null ?
                                null
                                :
                                currentFeedData.length === 0 ? 
                                null 
                                :
                                <div>
                                    <p 
                                        onClick={() => navigate(currentFeedData[videoCode].creatorName)}
                                        style={styles.creator}
                                    >
                                        @{currentFeedData[videoCode].creatorName}
                                    </p>
                                    <p style={styles.quidvidTotal}>
                                        {currentFeedData[videoCode].totalQuidvids} {currentFeedData[videoCode].totalQuidvids === 1 ? 'quidvid' : 'quidvids'}
                                    </p>
                                </div>
                            }
                        </div>
                    </div>
                    <div style={styles.sections}>
                    {
                        loading === true ?
                        <Lottie
                            loop
                            animationData={lottieJson}
                            play
                            style={styles.loading}
                        />
                        :
                        null
                    }
                        <ReactPlayer
                            url={currentFeedData.length === 0 ? null : videoCode === null ? "" : currentFeedData[videoCode].creatorVideo}
                            playing={true}
                            loop={false}
                            controls={true}
                            width={currentWidth * 0.4}
                            onBufferEnd={() => onBufferEnd()}
                            onBuffer={() => onBuffer()}
                        />
                    </div>
                </div>
                <div style={{ height: currentHeight * 0.03 }} />
                <div style={styles.centre}>
                    {
                        currentFeedData.length === 0 ?
                        null
                        :
                        videoCode === null ?
                        null
                        :
                        currentFeedData[videoCode].creatorName === currentUserName ?
                        null
                        :
                        isMobile === true ?
                        <DefaultButton
                            onClick={() => anotherQuestion()}
                        >
                            Ask Another Question
                        </DefaultButton>
                        :
                        <DesktopDefaultButton
                            onClick={() => anotherQuestion()}
                        >
                            Ask Another Question
                        </DesktopDefaultButton>
                    }
                </div>
                <div style={{ height: currentHeight * 0.02 }} />
                <p style={{ fontFamily: fontFamily, fontSize: '1em', color: colour.med, fontWeight: 'normal' }}>
                    Share
                </p>
                <div style={{ height: currentHeight * 0.02 }} />
                <div style={styles.centreRow}>
                    <CopyToClipboard 
                        text={url}
                        onCopy={() => dispatch(copyToClipboard(true))}
                    >
                        <div style={styles.copyLink}>
                            <img
                                src={isCopied === true ? require('./copyLinkActive.svg') : require('./copylink.svg')}
                                alt="Copy Link"
                                style={{ width: isMobile === true ? currentWidth * 0.1 : currentWidth * 0.03, cursor: 'pointer' }}
                            />
                        </div>
                    </CopyToClipboard>
                    <div style={{ width: currentWidth * 0.03 }} />
                    <FacebookShareButton 
                        url={url}
                        children="Share"
                    >
                        <FacebookIcon 
                            size={50}
                            round={true}
                        />
                    </FacebookShareButton>
                    <div style={{ width: currentWidth * 0.03 }} />
                    <WhatsappShareButton
                        url={url}
                        children="Share"
                    >
                    <WhatsappIcon
                        size={50}
                        round={true}
                    />
                    </WhatsappShareButton>
                    <div style={{ width: currentWidth * 0.03 }} />
                    <TwitterShareButton
                        url={url}
                        children="Share"
                    >
                    <TwitterIcon
                        size={50}
                        round={true}
                    />
                    </TwitterShareButton>
                </div>
            </div>
            {
                isMobile === true ?
                <Footer/>
                :
                <DesktopFooter/>
            }
        </div>
    )
}

export default Watch;