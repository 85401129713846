import React, { useEffect, useCallback } from 'react';
import * as firebase from 'firebase/app';
import '@firebase/firestore';
import { useSelector, useDispatch } from 'react-redux';
import { width, height } from './actions';
import { useHistory } from "react-router-dom";
import LoginComponent from './LoginComponent';
import { QuidvidButton, fontFamily, colour } from './GlobalStyles';
import Header from './Header';
import DesktopLoginComponent from './DesktopLoginComponent';
import { isMobile } from 'react-device-detect';
import Footer from './Footer';
import FooterDesktop from './FooterDesktop';
import Navbar from './NavBar';
  
  function Login() {

    const currentWidth = useSelector(state => state.width);
    const currentHeight = useSelector(state => state.height);
    const dispatch = useDispatch();
    const stableDispatch = useCallback(dispatch, []);
    let history = useHistory();

    useEffect(() => {
      firebase.auth().onAuthStateChanged( user => {
        if (user) {
          history.push('./');
        }
        else {
        }
      })
      
    function resizeWindow () {
      const newWidth = window.innerWidth;
      const newHeight = window.innerHeight;
      stableDispatch(width(newWidth));
      stableDispatch(height(newHeight));
    };
    window.addEventListener("resize", resizeWindow);
    return () => window.removeEventListener("resize", resizeWindow);
  }, [history, stableDispatch]);

    const styles = {
        centre: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        },
        welcome: {
          paddingLeft: currentWidth * 0.05
        },
        width: {
          width: currentWidth * 0.8 
        },
        navbar: {
          position: 'fixed', 
          zIndex: 1,
          bottom: 0, 
          left: 0,
          opacity: 1
      },
    }
      return (
          <div>
            <Header />
            {
              isMobile === true ?
              <div style={styles.welcome}>
                <h3 style={{ fontFamily: fontFamily, color: colour.dark, fontSize: '1.375em', fontWeight: 'bold' }}>
                  Welcome back {'<3'}
                </h3>
              </div>
              :
              null
            }
            <div style={{ height: currentHeight * 0.05 }} />
            {
              isMobile === true ?
              <LoginComponent 
                path="./home"
              />
              :
              <DesktopLoginComponent 
                path="./home"
              />
            }
            <div style={{ height: currentHeight * 0.02 }} />
            <div style={styles.centre}>
              {
                isMobile === false ?
                null
                :
                <QuidvidButton 
                  onClick={() => history.push('./signUp')}
                >
                  Create new account
                </QuidvidButton>
              }
            </div>
            <div style={{ height: currentHeight * 0.05 }} />
            {
                isMobile === false ?
                null
                :
                <div style={styles.centre}>
                  <div style={styles.width}>
                    <p style={{ textAlign: 'center', color: colour.med, fontWeight: '500', fontFamily: fontFamily, fontsize: '0.75em' }}>
                      Those are the only options, what more do you want?
                    </p>
                  </div>
                </div>
            }
            {
              isMobile === true ?
              <div style={styles.navbar}>
                <Navbar/>
              </div>
              :
              null
            }
            {
              isMobile === true ?
              <Footer />
              :
              <FooterDesktop />
            }
          </div>
      )
  }

  export default Login;