import React from 'react';
import { useSelector } from 'react-redux';
import '@firebase/firestore';
import AddPaymentMethod from './AddPaymentMethod';
import QuestionBanner from './QuestionBanner';
import Header from './Header';
import { useHistory } from 'react-router-dom';

function QuestionAddPaymentMethod() {

    const currentWidth = useSelector(state => state.width);
    const currentHeight = useSelector(state => state.height);
    const newQuestion = useSelector(state => state.newQuestionText);
    const anonymousState = useSelector(state => state.anonymous);
    const questionerName = useSelector(state => state.questioner);
    const currentProfileUserName = useSelector(state => state.profileUserName);
    let history = useHistory();

    if ( newQuestion === "" ) {
        history.push('./home');
    }
    return (
        <div>
            <Header />
            <QuestionBanner 
                width={currentWidth}
                height={currentHeight}
                question={newQuestion}
                anonymous={anonymousState}
                questionerName={questionerName}
                creatorUserName={currentProfileUserName}
            />
            <div style={{ height: currentHeight * 0.1 }} />
            <AddPaymentMethod 
                pay={true}
            />
        </div>
    )
}

export default QuestionAddPaymentMethod;