import React from 'react';
import { colour, fontFamily } from './GlobalStyles';
import { useHistory } from "react-router-dom";
import { useSelector } from 'react-redux'

function FooterDesktop() {

    const currentHeight = useSelector(state => state.height);
    const currentWidth = useSelector(state => state.width);
    let history = useHistory();

    const styles = {
        container: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            width: currentWidth,
            backgroundColor: colour.light,
            height: currentHeight * 0.15,
            marginTop: currentHeight * 0.2
        },
        squidContainer: {
            width: currentWidth * 0.75,
            paddingLeft: currentWidth * 0.1
        },
        squid: {    
            width: currentWidth * 0.05
        },
        flexStart: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flexStart',
            width: currentWidth * 0.2
        },
        row: {
            display: 'flex',
            flexDirection: 'row',
        }
    }

    return (
        <div style={styles.container}>
            <div style={styles.squidContainer}>
                <img
                    src={require('./squidy-green.svg')}
                    style={styles.squid}
                    alt=""
                /> 
                <div>
                    <p style={{ fontFamily: fontFamily, fontSize: '1.125em', color: colour.dark, fontWeight: 'bold' }}>
                        2020 Quidvid
                    </p>
                </div>
            </div>
            <div style={styles.row}>
                <div style={styles.flexStart}>
                    <p 
                        onClick={() => history.push('./privacy')}
                        style={{ cursor: 'pointer', color: colour.med, fontFamily: fontFamily, fontSize: '1.125em' }}
                    >
                        Privacy Policy
                    </p>
                    <div style={{ height: currentHeight * 0.02 }} />
                    <p onClick={() => history.push('./terms')}
                        style={{ cursor: 'pointer', color: colour.med, fontFamily: fontFamily, fontSize: '1.125em' }}
                    >
                        Terms {"&"} Conditions
                    </p>
                </div>
                <div style={styles.flexStart}>
                    <p 
                        onClick={() => history.push('./ourAnswersToYourPerfectlyReasonableQuestions')}
                        style={{ cursor: 'pointer', color: colour.dark, fontFamily: fontFamily, fontSize: '1.125em', fontWeight: 'bold' }}
                    >
                        FAQ
                    </p>
                    <div style={{ height: currentHeight * 0.02 }} />
                    <p 
                        onClick={() => history.push('./aboutUs')}
                        style={{ cursor: 'pointer', color: colour.primaryMuted, fontFamily: fontFamily, fontSize: '1.125em', fontWeight: 'bold' }}
                    >
                        About Us
                    </p>
                </div>
            </div>
        </div>
    )
}

export default FooterDesktop;