import React, { useEffect } from 'react';
import * as firebase from 'firebase/app';
import '@firebase/firestore';
import { useSelector, useDispatch } from 'react-redux';
import Checkbox from '@material-ui/core/Checkbox';
import { auth, email, password, userId, stayLoggedIn, userName, userList, authenticationDisabledButton, videoUpload, newVideoBlob } from './actions';
import { useHistory } from "react-router-dom";
//import FacebookButton from './FacebookButton';
import { DefaultButton, Input, fontFamily, colour } from './GlobalStyles';

function SignupComponent({ path }) {
  
    const currentWidth = useSelector(state => state.width);
    const currentHeight = useSelector(state => state.height);
    const newEmail = useSelector(state => state.email);
    const newPassword = useSelector(state => state.password);
    const stayLoggedInData = useSelector(state => state.stayLoggedIn);
    const currentUsers = useSelector(state => state.userList);
    const disabledButton = useSelector(state => state.authenticationDisabledButton);
    const currentVideoBlob = useSelector(state => state.newVideoBlob);
    const answers = useSelector(state => state.answeredQuestions);
    const currentFeedData = useSelector(state => state.feedData);
    const sharedQuestions = useSelector(state => state.sharedQuestions);
    const sharedQuestionIndex = useSelector(state => state.sharedQuestionIndex);
    const currentUserName = useSelector(state => state.userName);
    const totalQuidvidsData = useSelector(state => state.totalQuidvids);
    const currentTotalEarnings = useSelector(state => state.totalEarnings);
    const currency = useSelector(state => state.paymentCurrency);
    const dispatch = useDispatch();
    let history = useHistory();

    useEffect(() => {

      firebase.firestore().collection("Users").doc("List")
      .onSnapshot(snapshot => {
          if (!snapshot.exists) {
              console.log("No such document!")
          }
          else {
              const data = snapshot.data();
              const userData = data.users;
              dispatch(userList(userData)); 
          }
      })
  })

    function submit() {
        const userIndex = currentUsers.map((e) => { return e.userName; }).indexOf(currentUserName);

        if ( userIndex === -1 && currentUserName !== "" ) {
          dispatch(authenticationDisabledButton(true));
            firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
            .then(() => {
              // Existing and future Auth states are now persisted in the current
              // session only. Closing the window would clear any existing state even
              // if a user forgets to sign out.
              // ...
              // New sign-in will be persisted with session persistence.
              return firebase.auth().createUserWithEmailAndPassword(newEmail, newPassword);
            })
            .catch((error) => {
              // Handle Errors here.
              const errorCode = error.code;
              const errorMessage = error.message;
              dispatch(authenticationDisabledButton(false));
              if ( errorCode === "auth/invalid-email" ) {
                alert("Oopps..You entered an invalid e-mail address. Please try again!");
              }
              else if ( errorCode === "auth/user-disabled" ) {
                alert("Oopps..It looks like your profile has been disabled. Please contact us for further details");
              }
              else if ( errorCode === "auth/user-not-found" ) {
                alert("Oopps..We couldn't find your account in our database. Please try again!");
              }
              else if ( errorCode === "auth/weak-password" ) {
                alert("Oopps..It looks like you used a weak password. Please try again!");
              }
              else if ( errorCode === "auth/email-already-in-use" ) {
                alert("Oopps..It looks like this e-mail has already been used. Please try again with another e-mail address");
              }
              else {
                alert("Oopps...There was an error. Please try again!", errorMessage);
              }
            });
        
            firebase.auth().onAuthStateChanged((user) => {
              if (user) {
                dispatch(auth(true));
                dispatch(userId(user.uid));

                const db = firebase.firestore();
                const userDocRef = db.collection("Users").doc("List");

                db.runTransaction((transaction) => {
                    return transaction.get(userDocRef).then((doc) => {
                        if (!doc.exists) {
                            console.log("Document does not exist!");
                        }
                        const users = doc.data().users;
                      
                        const newUser = {
                          userName: currentUserName,
                          userId: user.uid,
                          email: user.email,
                          photoURL: ""
                        }

                        users.push(newUser);
                        transaction.update(userDocRef, 
                            { 
                                users
                            });
                        return users;
                      
                    });
                }).then(() => {
                    console.log("Promise compiled with success");

                    if ( path === "openQuestion" ) {
                      const code = Math.random().toString(36).substring(7);
                      const blob = currentVideoBlob
                  
                      const ref = firebase.storage().ref(`Creator Videos/${user.uid}`).child(code);
                      const uploadTask = ref.put(blob);
                      new Promise((resolve, reject) => {
                          uploadTask.on(
                              'state_changed',
                              (snapshot) => {
                              const progress = snapshot.bytesTransferred / snapshot.totalBytes * 100;
                              console.log('Upload is ' + progress + '% done');
                              dispatch(videoUpload(progress));
                              },
                              (error) => {
                              reject(error);  // added this line
                              console.log(error)
                              },
                              () => {
                              queryVideo(code, user);
                              resolve();  // added this line
                              }
                          );
                      });
                    }
                    else {
                      firebase.firestore().collection("Creator Profile").doc(user.uid).set({
                        userName: currentUserName,
                        email: user.email,
                        photoURL: "",
                        date: new Date()
                    })
      
                      firebase.firestore().collection("Welcome_Email").doc(user.uid).set({
                        email: user.email
                      })
                      firebase.firestore().collection("Welcome_Email").doc(user.uid).delete();
      
                      dispatch(userName(""));
                      dispatch(email(""));
                      dispatch(password(""));
      
                      history.push(path);
                      dispatch(authenticationDisabledButton(false));
                    }
                }).catch((err) => {
                    firebase.firestore().collection("Authentication Error").doc(user.uid).set({
                      error: err,
                      desktop: false
                    })
                    console.error(err);
                });
              }
              else {
                dispatch(auth(false));
        
              }
            });
        }
        else {
          alert("This user name has already been taken!");
        }
      }

      const queryVideo = (imageName, user) => {
    
        const creatorVideos = firebase.storage().ref(`Creator Videos/${user.uid}`).child(imageName);   
        creatorVideos.getDownloadURL()
            .then((url) => {
                                makeId(15, url, user);
                            })
            .catch(err => {
                            console.log('Error getting creator video', err);
                            dispatch(videoUpload(""));
                            dispatch(newVideoBlob(""));
                        })
    }

    function makeId(length, url, user) {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
           result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }

        const resultIndex = currentFeedData.map((e) => { return e.code; }).indexOf(result);
        if ( resultIndex !== -1 ) {
            makeId(length, url, user);
        }
        else if ( sharedQuestions[sharedQuestionIndex].credit === true ) {
            freeQuestion(url, result, user);
        }
        else {
            charge(url, result, user);
        }
    }

    function freeQuestion(url, result, user) {
        dispatch(videoUpload(""));
                    alert("Free question answered!");

                    const db = firebase.firestore();
                    const sharedQuestionsRef = db.collection("Shared Questions").doc("Data");
                    db.runTransaction((transaction) => {
                        return transaction.get(sharedQuestionsRef).then((doc) => {
                            if (!doc.exists) {
                                console.log("Document does not exist!");
                            }
                            const newSharedQuestions = doc.data().sharedQuestions;
                            const questionIndex = newSharedQuestions.map((e) => { return e.id; }).indexOf(sharedQuestions[sharedQuestionIndex].id);
                            console.log("question Index =", questionIndex)
                            newSharedQuestions.splice(questionIndex, 1);
                            console.log("newSharedQuestions =", newSharedQuestions)
                            transaction.update(sharedQuestionsRef, 
                                { 
                                    sharedQuestions: newSharedQuestions
                                });
                            return newSharedQuestions;
                          
                        });
                    }).then(() => {
                        console.log('free splice transaction complete');
                    }).catch((err) => {
                        console.error(err);
                    });

                    const feedRef = db.collection("Feed").doc("Data");
        
                    db.runTransaction((transaction) => {
                        return transaction.get(feedRef).then((doc) => {
                            if (!doc.exists) {
                                console.log("Document does not exist!");
                            }
                            const feed = doc.data().feed;
                            const newFeedPost = {
                                creatorImage: "",
                                creatorUserId: user.uid,
                                creatorName: currentUserName,
                                creatorVideo: url,
                                totalQuidvids: totalQuidvidsData + 1,
                                userName: sharedQuestions[sharedQuestionIndex].userName,
                                userPhoto: sharedQuestions[sharedQuestionIndex].userImage,
                                userQuestion: sharedQuestions[sharedQuestionIndex].question,
                                userUserId: sharedQuestions[sharedQuestionIndex].userId,
                                code: result
                            }
                            feed.unshift(newFeedPost);
                            
                            transaction.update(feedRef, 
                                { 
                                    feed
                                });
                            return feed;
                          
                        });
                    }).then(() => {
                        console.log("Promise compiled with success");

                        const newAnswer = {
                            date: new Date(),
                            question: sharedQuestions[sharedQuestionIndex].question,
                            questioner: sharedQuestions[sharedQuestionIndex].userName,
                            questionerImage: sharedQuestions[sharedQuestionIndex].userImage,
                            questionerUserId: sharedQuestions[sharedQuestionIndex].userId,
                            creatorVideo: url,
                            code: result
                        }
                        answers.unshift(newAnswer);
    
                        firebase.firestore().collection("Creator Profile").doc(user.uid).set({
                            answers,
                            totalQuidvids: totalQuidvidsData + 1,
                            totalEarnings: currentTotalEarnings,
                            userName: currentUserName,
                            email: user.email,
                            photoURL: "",
                            date: new Date(),
                            questionCredits: 10
                        }, {merge: true})
        
                        firebase.firestore().collection("Welcome_Email").doc(user.uid).set({
                          email: user.email
                        })
                        firebase.firestore().collection("Welcome_Email").doc(user.uid).delete();
        
                        dispatch(userName(""));
                        dispatch(email(""));
                        dispatch(password(""));
        
                        history.push(path);
                        dispatch(authenticationDisabledButton(false));
                        dispatch(videoUpload(""));
                        dispatch(newVideoBlob(""));
                        history.push('./home');
                    }).catch((err) => {
                        console.error(err);
                    });
    }

    function charge(url, result, user) {

        if ( sharedQuestions[sharedQuestionIndex].paymentMethod !== undefined && sharedQuestions[sharedQuestionIndex].customerId !== undefined ) {
            dispatch(videoUpload("charging card"));
            firebase.firestore().collection("Stripe_Customers").doc(user.uid).collection("Charges").doc("Recent").set({
                amount: 100,
                customerId: sharedQuestions[sharedQuestionIndex].customerId,
                paymentMethod: sharedQuestions[sharedQuestionIndex].paymentMethod,
                currency: currency
            })
  
            firebase.firestore().collection("Stripe_Customers").doc(user.uid).collection("Charges").doc("Recent").delete();

            firebase.firestore().collection("Stripe_Customers").doc(user.uid).collection("Success").doc("Success")
            .onSnapshot(snapshot => {
              if (!snapshot.exists) {
                console.log("No such document!")
              }
              else {
                const data = snapshot.data();
                const paymentIntent = data.paymentIntent;
                const status = paymentIntent.status;
                
                if ( status === "succeeded" ) {
                    dispatch(videoUpload(""));
                    alert("You just earned £1!");
                    firebase.firestore().collection("Stripe_Customers").doc(user.uid).collection("Success").doc("Success").delete();   

                    const db = firebase.firestore();
                    const sharedQuestionsRef = db.collection("Shared Questions").doc("Data");
                    db.runTransaction((transaction) => {
                        return transaction.get(sharedQuestionsRef).then((doc) => {
                            if (!doc.exists) {
                                console.log("Document does not exist!");
                            }
                            const newSharedQuestions = doc.data().sharedQuestions;
                            const questionIndex = newSharedQuestions.map((e) => { return e.id; }).indexOf(sharedQuestions[sharedQuestionIndex].id);
                            
                            newSharedQuestions.splice(questionIndex, 1);
                            transaction.update(sharedQuestionsRef, 
                                { 
                                    sharedQuestions: newSharedQuestions
                                });
                            return newSharedQuestions;
                          
                        });
                    }).then(() => {
                        console.log('paid splice transaction complete');
                    }).catch((err) => {
                        console.error(err);
                    });

                    const feedRef = db.collection("Feed").doc("Data");
        
                    db.runTransaction((transaction) => {
                        return transaction.get(feedRef).then((doc) => {
                            if (!doc.exists) {
                                console.log("Document does not exist!");
                            }
                            
                            const feed = doc.data().feed;
                            const newFeedPost = {
                                creatorImage: "",
                                creatorUserId: user.uid,
                                creatorName: currentUserName,
                                creatorVideo: url,
                                totalQuidvids: totalQuidvidsData + 1,
                                userName: sharedQuestions[sharedQuestionIndex].userName,
                                userPhoto: sharedQuestions[sharedQuestionIndex].userImage,
                                userQuestion: sharedQuestions[sharedQuestionIndex].question,
                                userUserId: sharedQuestions[sharedQuestionIndex].userId,
                                code: result
                            }
                            feed.unshift(newFeedPost);
                            
                            transaction.update(feedRef, 
                                { 
                                    feed
                                });
                            return feed;
                          
                        });
                    }).then(() => {
                        console.log("Promise compiled with success");

                        const newAnswer = {
                            date: new Date(),
                            question: sharedQuestions[sharedQuestionIndex].question,
                            questioner: sharedQuestions[sharedQuestionIndex].userName,
                            questionerImage: sharedQuestions[sharedQuestionIndex].userImage,
                            questionerUserId: sharedQuestions[sharedQuestionIndex].userId,
                            creatorVideo: url,
                            code: result
                        }
                        answers.unshift(newAnswer);
    
                        firebase.firestore().collection("Creator Profile").doc(user.uid).set({
                            answers,
                            totalQuidvids: totalQuidvidsData + 1,
                            totalEarnings: currentTotalEarnings + 1,
                            userName: currentUserName,
                            email: user.email,
                            photoURL: "",
                            date: new Date(),
                            questionCredits: 10
                        }, {merge: true})
        
                        firebase.firestore().collection("Welcome_Email").doc(user.uid).set({
                          email: user.email
                        })
                        firebase.firestore().collection("Welcome_Email").doc(user.uid).delete();
        
                        dispatch(userName(""));
                        dispatch(email(""));
                        dispatch(password(""));
        
                        history.push('./');
                        dispatch(authenticationDisabledButton(false));
                        dispatch(videoUpload(""));
                        dispatch(newVideoBlob(""));
                        history.push('./home');
                    }).catch((err) => {
                        console.error(err);
                    });
                }
                else if ( status === "error" ){
                    dispatch(videoUpload(""));
                    alert("Oopps, we're sorry..your customer's card was declined. We have deleted their question, and you will be navigated back to your profile :(");
                    firebase.firestore().collection("Stripe_Customers").doc(user.uid).collection("Success").doc("Success").delete();

                    history.push('./home');
                }
                else {
                    dispatch(videoUpload(""));
                    alert("Oopps, we're sorry..your customer's card was declined. We have deleted their question, and you will be navigated back to your profile :(");
                    firebase.firestore().collection("Stripe_Customers").doc(user.uid).collection("Success").doc("Success").delete();

                    history.push('./home');
                }
              }
            })
        }
        else {
            alert("Ooppss.. we're sorry but your customer does not have a card on file. Their question has been deleted.");
            history.push('./home');
        }
    }


    const styles = {
        centre: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        },
        keepLogged: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
        },
    }

    return (
        <div>
            <div style={styles.centre}>
              <Input
                value={currentUserName}
                id="outlined"
                label="user name"
                inputProps={{
                    autoCapitalize: 'none',
                }}
                variant="outlined" 
                onChange={(text) => dispatch(userName(text.target.value.replace(/\s+/g, '')))}
                style={styles.textField}
              />
                <div style={{ height: currentHeight * 0.02 }} />
                <Input
                  value={newEmail}
                  id="outlined"
                  label="email"
                  inputProps={{
                      autoCapitalize: 'none',
                  }} 
                  variant="outlined" 
                  onChange={(text) => dispatch(email(text.target.value.replace(/\s+/g, '')))}
                  style={styles.textField}
                />
                <div style={{ height: currentHeight * 0.02 }} />
                <Input
                  value={newPassword}
                  id="outlined"
                  label="password"
                  type="password"
                  autoComplete="current-password"
                  inputProps={{
                      autoCapitalize: 'none',
                  }}
                  variant="outlined"
                  onKeyDown={(e) => e.key === 'Enter' ? submit() : null} 
                  onChange={(text) => dispatch(password(text.target.value))}
                  style={styles.textField}
                />
            </div>
            <div style={{ height: currentHeight * 0.02 }} />
            <div style={styles.keepLogged}>
              <div style={{ width: currentWidth * 0.05 }} />
              <Checkbox
                  checked={stayLoggedInData}
                  onChange={(state) => dispatch(stayLoggedIn(state.target.checked))}
                  name="checkedB"
                  label="Keep me logged in"
                  color="primary"
                  labelStyle={{color: 'white'}}
                  iconStyle={{fill: 'white'}}
              />
              <p style={{ color: colour.med, fontSize: '1em', fontFamily: fontFamily }}>
                  Keep me logged in
              </p>
            </div>
            <div style={styles.centre}>
              {
                currentWidth < 350 ?
                <DefaultButton
                  disabled={disabledButton}
                  onClick={() => submit()}
                >
                  Sign Up
                </DefaultButton>
                : 
                <DefaultButton
                  disabled={disabledButton}
                  onClick={() => submit()}
                >
                    That was easier than I expected
                </DefaultButton>
              }
            </div>
            <div style={{ height: currentHeight * 0.01 }} />
            {/*
            <FacebookButton 
              path={path}
            />
            */}
        </div>
    )
}

export default SignupComponent;