import React from 'react';
import { colour, ActionButton, fontFamily, DesktopActionButton } from './GlobalStyles';
import { isMobile } from "react-device-detect";
import { useHistory } from "react-router-dom";
import { useSelector } from 'react-redux';
import Lottie from 'react-lottie-player';
import lottieJson from './banner-animation.json';

function LandingComponent() {

    const currentWidth = useSelector(state => state.width);
    const currentHeight = useSelector(state => state.height);
    let history = useHistory();

    const styles = {
        bodyContainer: {
            display: 'flex',
            flexDirection: 'column',
            backgroundImage: `url(${require("./banner-bg.png")})`,
            width: currentWidth,
            backgroundSize: 'cover'
          },
          bodyText: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: isMobile === true ? 'center' : 'center',
            //paddingLeft: currentWidth * 0.15,
            width: isMobile === false ? currentWidth * 0.45 : currentWidth,
          },
          centre: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          },
          desktopTitle: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: currentHeight * 0.05,
            height: currentHeight * 0.4
          },
          bannerAnimation: {
            width: isMobile === true ? currentWidth : currentWidth * 0.4,
            margin: '0 auto'
          },
    }
    return (
        <div style={styles.bodyContainer}>
          {
            isMobile === true ?
            <div>
              <div style={styles.bodyText}>
                <div style={{ height: currentHeight * 0.05 }} />
                <h1 style={{ fontFamily: fontFamily, fontSize: '1.375em', color: colour.primary, lineHeight: '1.375em', fontWeight: 'bold', textAlign: 'center' }}>
                  Answer your audience. 
                  <br />
                  Share your expertise. 
                  <br />
                  Get paid.
                </h1>
              </div>
              <Lottie
                loop
                animationData={lottieJson}
                play
                style={styles.bannerAnimation}
              />
            </div>
            :
            <div style={styles.desktopTitle}>
              <div style={{ width: currentWidth * 0.5 }}>
                <h1 style={{ fontFamily: fontFamily, fontSize: 55, color: colour.primary, lineHeight: '1.375em', fontWeight: 'bold', textAlign: 'center' }}>
                  Answer your audience. 
                  <br />
                  Share your expertise. 
                  <br />
                  Get paid.
                </h1>
                <Lottie
                  loop
                  animationData={lottieJson}
                  play
                  style={styles.bannerAnimation}
                />
              </div>
            </div>
          }
        <div style={{ height: isMobile === true ? currentHeight * 0.06 : currentHeight * 0.05 }} />
        <div style={styles.centre}>
          {
            isMobile === true ?
            <ActionButton
              onClick={() => history.push('../home')}
            >
              Get in on the action
            </ActionButton>
            :
            <DesktopActionButton
              onClick={() => history.push('../home')}
            >
              Get in on the action
            </DesktopActionButton>
          }
        </div>
        <div style={{ height: currentHeight * 0.1 }} />
      </div>
    )
}

export default LandingComponent;