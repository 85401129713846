import React, { useEffect, useRef, useCallback } from 'react';
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import * as firebase from 'firebase/app';
import '@firebase/firestore';
import { width, height, auth, userId, ownProfile, profileUserId, profileUserName, newQuestions, totalEarnings, totalQuidvids, userName, userPicture, viewingProfileName, viewingProfileUserPicture, viewingProfileTotalQuidvids, videoUpload, answeredQuestions, userIndex, userList, profileNewQuestions } from './actions';
import { isMobile } from "react-device-detect";
import AnswerQuestion from './AnswerQuestion';
import VidForQuid from './VidForQuid';
import Header from './Header';
import { colour, fontFamily } from './GlobalStyles';
import './ProfileStyles.css';
import VideoList from './VideoList';
import NavBar from './NavBar';
import SearchComponent from './SearchComponent';
import NavbarDesktop from './NavBarDesktop';
import Lottie from 'react-lottie-player';
import lottieJson from './loading-animation.json';

  function Profile({ match }) {

    const userURL = match.params.user;
    const currentWidth = useSelector(state => state.width);
    const currentHeight = useSelector(state => state.height);
    const authState = useSelector(state => state.auth);
    const currentUserName = useSelector(state => state.userName);
    const currentUserId = useSelector(state => state.userId);
    const currentOwnProfile = useSelector(state => state.ownProfile);
    const currentProfileUserId = useSelector(state => state.profileUserId);
    const totalQuidvidsData = useSelector(state => state.totalQuidvids);
    const currentUserPicture = useSelector(state => state.userPicture);
    const currentViewingProfileName = useSelector(state => state.viewingProfileName);
    const currentviewingProfileUserPicture = useSelector(state => state.viewingProfileUserPicture)
    const currentViewingProfileTotalQuidvids = useSelector(state => state.viewingProfileTotalQuidvids);
    const loading = useSelector(state => state.videoUpload);
    const dispatch = useDispatch();
    const stableDispatch = useCallback(dispatch, []);
    const declineModal = useSelector(state => state.declineQuidModal);
    let history = useHistory();

    useEffect(() => {
        
      stableDispatch(profileNewQuestions([]));
      stableDispatch(viewingProfileName(""));
      stableDispatch(viewingProfileUserPicture(""));
      stableDispatch(viewingProfileTotalQuidvids(""));
      
      firebase.auth().onAuthStateChanged(user => {
        if (user) {
            stableDispatch(auth(true));
            stableDispatch(userId(user.uid));
    
            firebase.firestore().collection("Users").doc("List")
            .onSnapshot(snapshot => {
                if (!snapshot.exists) {
                    console.log("No such document!")
                }
                else {
                    const data = snapshot.data();
                    const currentUserList = data.users;
                    stableDispatch(userList(currentUserList));
                    
                    const currentUserIndex = currentUserList.map((e) => { return e.userName; }).indexOf(userURL);
                    if ( currentUserIndex === -1 ) {
                        history.push('../error');
                    }
                    else {
                        stableDispatch(userIndex(currentUserIndex));
                        const userListUserId = currentUserList[currentUserIndex].userId;
                        if ( userListUserId === user.uid ) {
                            stableDispatch(ownProfile(true));
                            stableDispatch(profileUserId(userListUserId));
                            stableDispatch(profileUserName(userURL));

                            firebase.firestore().collection("Creator Profile").doc(user.uid)
                            .onSnapshot(snapshot => {
                                if (!snapshot.exists) {
                                    console.log('No such document!');
                                }
                                else {
                                    const data = snapshot.data();
                                    const name = data.userName;
                                    stableDispatch(userName(name));
                
                                    if ( data.answers !== undefined ) {
                                        const answers = data.answers;
                                        stableDispatch(answeredQuestions(answers));
                                    }
                                    else {
                                        stableDispatch(answeredQuestions([]));
                                    }
                                    if ( data.photoURL !== undefined ) {
                                        const userPhoto = data.photoURL;
                                        stableDispatch(userPicture(userPhoto));
                                    }
                                    if ( data.newQuestions !== undefined ) {
                                        const newQuestionList = data.newQuestions;
                                        stableDispatch(newQuestions(newQuestionList));
                                    }
                                    if ( data.totalEarnings !== undefined ) {
                                        const totalEarningNumber = data.totalEarnings;
                                        stableDispatch(totalEarnings(totalEarningNumber));
                                    }
                                    if ( data.totalQuidvids !== undefined ) {
                                        const totalQuidvidNumber = data.totalQuidvids;
                                        stableDispatch(totalQuidvids(totalQuidvidNumber));
                                    }
                                    else {
                                        stableDispatch(totalQuidvids(0));
                                    }
                                }
                            })
                        }
                        else {
                            stableDispatch(ownProfile(false));
                            stableDispatch(profileUserId(userListUserId));
                            stableDispatch(profileUserName(userURL));

                            firebase.firestore().collection("Creator Profile").doc(userListUserId)
                            .onSnapshot(snapshot => {
                                if (!snapshot.exists) {
                                    console.log('No such document!');
                                }
                                else {
                                    const data = snapshot.data();
                                    const name = data.userName;
                                    stableDispatch(viewingProfileName(name));
                
                                    if ( data.answers !== undefined ) {
                                        const answers = data.answers;
                                        stableDispatch(answeredQuestions(answers));
                                    }
                                    else {
                                        stableDispatch(answeredQuestions([]));
                                    }
                                    if ( data.photoURL !== undefined ) {
                                        const userPhoto = data.photoURL;
                                        stableDispatch(viewingProfileUserPicture(userPhoto));
                                    }
                                    else {
                                        stableDispatch(viewingProfileUserPicture(""));
                                    }
                                }
                            })

                            firebase.firestore().collection("Creator Profile").doc(userListUserId)
                            .onSnapshot(snapshot => {
                                if (!snapshot.exists) {
                                    console.log('No such document!');
                                }
                                else {
                                    const data = snapshot.data();
                
                                    if ( data.totalQuidvids !== undefined ) {
                                        const totalQuidvidNumber = data.totalQuidvids;
                                        stableDispatch(viewingProfileTotalQuidvids(totalQuidvidNumber));
                                    }
                                }
                            })
                        }
                    }
                }
            })
        }
        else {
            stableDispatch(auth(false));
            stableDispatch(userId(""));
            stableDispatch(ownProfile(false));

            firebase.firestore().collection("Users").doc("List")
            .onSnapshot(snapshot => {
                if (!snapshot.exists) {
                    console.log("No such document!")
                }
                else {
                    const data = snapshot.data();
                    const currentUserList = data.users;
                    stableDispatch(userList(currentUserList));
                    const currentUserIndex = currentUserList.map((e) => { return e.userName; }).indexOf(userURL);
                    if ( currentUserIndex === -1 ) {
                        history.push('../error');
                    }
                    else {
                        stableDispatch(userIndex(currentUserIndex));
                        const userListUserId = currentUserList[currentUserIndex].userId;
                        stableDispatch(profileUserId(userListUserId));
                        stableDispatch(profileUserName(userURL));

                        firebase.firestore().collection("Creator Profile").doc(userListUserId)
                        .onSnapshot(snapshot => {
                            if (!snapshot.exists) {
                                console.log('No such document!');
                            }
                            else {
                                const data = snapshot.data();
                                const name = data.userName;
                                stableDispatch(viewingProfileName(name));
            
                                if ( data.answers !== undefined ) {
                                    const answers = data.answers;
                                    stableDispatch(answeredQuestions(answers));
                                }
                                else {
                                    stableDispatch(answeredQuestions([]));
                                }
                                if ( data.photoURL !== undefined ) {
                                    const userPhoto = data.photoURL;
                                    stableDispatch(viewingProfileUserPicture(userPhoto));
                                }
                                else {
                                    stableDispatch(viewingProfileUserPicture(""));
                                }
                                if ( data.totalQuidvids !== undefined ) {
                                    const totalQuidvidNumber = data.totalQuidvids;
                                    stableDispatch(viewingProfileTotalQuidvids(totalQuidvidNumber));
                                }
                            }
                        })
                    }
                }
            })
        }
      })
      
      function resizeWindow () {
        const newWidth = window.innerWidth;
        const newHeight = window.innerHeight;
        stableDispatch(width(newWidth));
        stableDispatch(height(newHeight));
      };

        window.addEventListener("resize", resizeWindow);
        return () => window.removeEventListener("resize", resizeWindow);
    }, [stableDispatch, userURL, history]);

    const submitImage = async (file) => {
        const code = "profileImage";
        const response = await fetch(file);
        const blob = await response.blob();
    
        const ref = firebase.storage().ref(`Creator Images/${currentUserId}`).child(code);
        const uploadTask = ref.put(blob);
        new Promise((resolve, reject) => {
            uploadTask.on(
                'state_changed',
                (snapshot) => {
                const progress = snapshot.bytesTransferred / snapshot.totalBytes * 100;
                console.log('Upload is ' + progress + '% done');
                dispatch(videoUpload(progress));
                },
                (error) => {
                reject(error);  // added this line
                console.log(error)
                },
                () => {
                queryImage(code);
                resolve();  // added this line
                }
            );
            });
      }
    
      const queryImage = (imageName) => {
    
        const creatorImages = firebase.storage().ref(`Creator Images/${currentUserId}`).child(imageName);   
        creatorImages.getDownloadURL()
            .then((url) => {
                              const image = url;
                              
                              firebase.firestore().collection("Creator Profile").doc(currentUserId).set({
                                photoURL: image
                              }, {merge: true})
                              
                              const db = firebase.firestore();
                              const userDocRef = db.collection("Users").doc("List");
                  
                              db.runTransaction((transaction) => {
                                  return transaction.get(userDocRef).then((doc) => {
                                      if (!doc.exists) {
                                          console.log("Document does not exist!");
                                      }
                                      const users = doc.data().users;
                                      const userIndex = users.map((e) => { return e.userId; }).indexOf(currentUserId);
                                      users[userIndex].photoURL = image;
                  
                                      transaction.update(userDocRef, 
                                          { 
                                              users
                                          });
                                      return users;
                                    
                                  });
                            }).then(() => {
                                console.log('Photo uploaded successfully');
                                dispatch(videoUpload(""));
                            }).catch((err) => {
                                console.error(err);
                                dispatch(videoUpload(""));
                            });
                            })
            .catch(err => {
                            console.log('Error getting creator image', err);
                            dispatch(videoUpload(""));
                        })
    }

    const styles = {
        container: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: currentHeight * 0.25
        },
        header: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: currentWidth * 0.9,
            paddingTop: currentHeight * 0.05
        },
        creatorImage: {
            width: currentWidth * 0.14,
            height: currentWidth * 0.14,
            borderRadius: currentWidth * 0.07
        },
        centre: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        },
        swiper: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        },
        questionContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            width: currentWidth * 0.95
        },
        user: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center'
        },
        userImage: {
            width: currentWidth * 0.07,
            height: currentWidth * 0.07,
            borderRadius: currentWidth * 0.035
        },
        question: {
            width: currentWidth * 0.7,
        },
        arrowIcon: {
            color: "#A3A3A3",
            fontSize: isMobile === true ? 20 : 40,
            cursor: 'pointer'
        },
        earnQuid: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            alignItems: 'center',
        },
        navbar: {
            position: 'fixed', 
            zIndex: 1,
            bottom: 0, 
            left: 0,
            opacity: 1
        },
        search: {
            width: currentWidth * 0.4
        },
        row: {
            display: 'flex',
            flexDirection: 'row'
        },
        profileDesktop: {
            width: currentWidth * 0.6
        },
        mobileAnimation: {
            width: currentWidth * 0.5,
            position: 'absolute',
            zIndex: 1,
            top: currentHeight * 0.2,
            left: currentWidth * 0.25
        },
        mobileAnimationContainer: {
            width: currentWidth,
            height: currentHeight,
            backgroundColor: 'rgba(255,255,255,0.5)',
            position: 'absolute',
            zIndex: 1
        },
        desktopAnimation: {
            width: currentWidth * 0.3,
            position: 'absolute',
            zIndex: 1,
            top: currentHeight * 0.2,
            left: currentWidth * 0.36
        },
        isModalOpen: {
            height: '100vh', 
            overflowY: 'hidden',
            position: 'fixed'
        },
        nothing: {
            
        }
    }

    function uploadData() {
        textInput.current.click();
    }

    const textInput = useRef(null);

    const onChangeFile = async (event) => {
        event.stopPropagation();
        event.preventDefault();
        const file = URL.createObjectURL(event.target.files[0]);
        const type = event.target.files[0].type;

        if ( type.includes("image") ) {
            submitImage(file)
        }
        else {
            alert("Please select an image ;)")
        }
    }

    return (
        <div style={declineModal === true ? styles.isModalOpen : styles.nothing}>
            <Header/>
            {
                isMobile === false ?
                <div style={styles.row}>
                    <div style={styles.search}>
                        <SearchComponent/>
                        <div style={{ height: currentHeight * 0.45 }} />
                        <NavbarDesktop />
                    </div>
                    <div style={styles.profileDesktop}>
                        <div style={styles.centre}>
                            <div>
                                <input type='file' id='file' ref={textInput} style={{ display: 'none' }} onChange={onChangeFile} />
                            </div>
                            {
                                loading !== "" ?
                                <div style={styles.mobileAnimationContainer}>
                                    <Lottie
                                        loop
                                        animationData={lottieJson}
                                        play
                                        style={styles.desktopAnimation}
                                    />
                                </div>
                                :
                                null
                            }
                            {   
                                currentOwnProfile === false ?
                                    <img
                                        src={currentviewingProfileUserPicture === "" ? require('./anonymous.svg') : currentviewingProfileUserPicture}
                                        //style={styles.creatorImage}
                                        className={isMobile === true ? "dodecagon3" : "dodecagon1"}
                                        alt="creatorImage"
                                    />
                                :
                                authState === false ?
                                    <img
                                        src={currentviewingProfileUserPicture === "" ? require('./anonymous.svg') : currentviewingProfileUserPicture}
                                        //style={styles.creatorImage}
                                        className={isMobile === true ? "dodecagon3" : "dodecagon1"}
                                        alt="creatorImage"
                                    />
                                :
                                currentUserPicture === "" ?
                                <div style={styles.creatorImage}>
                                    <img
                                        src={require('./upload.svg')}
                                        className={isMobile === true ? "dodecagon4" : "dodecagon2"}
                                        //style={styles.creatorImage}
                                        alt="squid"
                                        onClick={() => uploadData()}
                                    />
                                </div>
                                :
                                <img
                                    src={currentUserPicture}
                                    className={isMobile === true ? "dodecagon4" : "dodecagon2"}
                                    //style={styles.creatorImage}
                                    alt="creatorImage"
                                    onClick={() => uploadData()}
                                />
                        
                            }
                            <div style={{ height: currentHeight * 0.02 }} />
                            {
                                currentOwnProfile === true ?
                                <div>
                                    <h3 style={{ fontSize: '1.125em', fontFamily: fontFamily, color: colour.primaryMuted, fontWeight: 'bold', textAlign: 'center' }}>
                                        @{currentUserName}
                                    </h3>
                                    <p style={{ fontFamily: fontFamily, fontSize: '0.75', color: colour.dark, textAlign: 'center' }}>
                                        {totalQuidvidsData} {totalQuidvidsData === 1 ? 'quidvid' : 'quidvids'}
                                    </p>
                                </div>
                                :
                                <div>
                                    <h3 style={{ fontSize: '1.125em', fontFamily: fontFamily, color: colour.primaryMuted, fontWeight: 'bold' }}>
                                        @{currentViewingProfileName}
                                    </h3>
                                    <p style={{ fontFamily: fontFamily, fontSize: '0.75', color: colour.dark }}>
                                        {currentViewingProfileTotalQuidvids} {currentViewingProfileTotalQuidvids === 1 ? 'quidvid' : 'quidvids'}
                                    </p>
                                </div>
                            }
                        </div>  
                        {
                            currentOwnProfile === true ?
                            <AnswerQuestion
                            />
                            :
                            <VidForQuid
                                userId={currentUserId}
                                currentProfileUserId={currentProfileUserId}
                                currentHeight={currentHeight}
                            />
                        }
                        <div style={{ height: currentHeight * 0.05 }} />
                        <VideoList />
                    </div>     
                </div>
                :
                <div style={styles.centre}>
                    <div>
                        <input type='file' id='file' ref={textInput} style={{ display: 'none' }} onChange={onChangeFile} />
                    </div>
                    {
                        loading !== "" ?
                        <div style={styles.mobileAnimationContainer}>
                            <Lottie
                                loop
                                animationData={lottieJson}
                                play
                                style={styles.mobileAnimation}
                            />
                        </div>
                        :
                        null
                    }
                    {   
                        currentOwnProfile === false ?
                            <img
                                src={currentviewingProfileUserPicture === "" ? require('./anonymous.svg') : currentviewingProfileUserPicture}
                                //style={styles.creatorImage}
                                className={isMobile === true ? "dodecagon3" : "dodecagon1"}
                                alt="creatorImage"
                            />
                        :
                        authState === false ?
                            <img
                                src={currentviewingProfileUserPicture === "" ? require('./anonymous.svg') : currentviewingProfileUserPicture}
                                //style={styles.creatorImage}
                                className={isMobile === true ? "dodecagon3" : "dodecagon1"}
                                alt="creatorImage"
                            />
                        :
                        currentUserPicture === "" ?
                            <img
                                src={require('./upload.svg')}
                                className={isMobile === true ? "dodecagon4" : "dodecagon2"}
                                style={styles.creatorImage}
                                alt="squid"
                                onClick={() => uploadData()}
                            />
                        :
                        <img
                            src={currentUserPicture}
                            className={isMobile === true ? "dodecagon4" : "dodecagon2"}
                            //style={styles.creatorImage}
                            alt="creatorImage"
                            onClick={() => uploadData()}
                        />
                
                    }
                    <div style={{ height: currentHeight * 0.02 }} />
                    {
                        currentOwnProfile === true ?
                        <div>
                            <h3 style={{ fontSize: '1.125em', fontFamily: fontFamily, color: colour.primaryMuted, fontWeight: 'bold', textAlign: 'center' }}>
                                @{currentUserName}
                            </h3>
                            <p style={{ fontFamily: fontFamily, fontSize: '0.75', color: colour.dark, textAlign: 'center' }}>
                                {totalQuidvidsData} {totalQuidvidsData === 1 ? 'quidvid' : 'quidvids'}
                            </p>
                        </div>
                        :
                        <div>
                            <h3 style={{ fontSize: '1.125em', fontFamily: fontFamily, color: colour.primaryMuted, fontWeight: 'bold' }}>
                                @{currentViewingProfileName}
                            </h3>
                            <p style={{ fontFamily: fontFamily, fontSize: '0.75', color: colour.dark }}>
                                {currentViewingProfileTotalQuidvids} quidvids
                            </p>
                        </div>
                    }
                </div>       
            } 
            {
                isMobile === false ?
                null
                :
                currentOwnProfile === true ?
                <AnswerQuestion
                />
                :
                <VidForQuid
                    userId={currentUserId}
                    currentProfileUserId={currentProfileUserId}
                    currentHeight={currentHeight}
                />
            }
            <div style={{ height: currentHeight * 0.05 }} />
            {
                isMobile === true ?
                <VideoList/>
                :
                null
            }
             <div style={{ height: currentHeight * 0.15 }} />
            {
                isMobile === true ?
                <div style={styles.navbar}>
                    <NavBar />
                </div>
                :
                null
            }
        </div>
    )
  }

  export default Profile;