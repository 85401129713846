import React, { useEffect } from 'react';
import Header from './Header';
import { isMobile } from 'react-device-detect';
import { colour, fontFamily } from './GlobalStyles';
import { useDispatch, useSelector } from 'react-redux';
import { accountNumber, sortCode, transactions, totalEarnings, accountCreated } from './actions';
import * as firebase from 'firebase/app';
import { useHistory } from 'react-router-dom';
import '@firebase/firestore';

function Transactions() {
    
    const dispatch = useDispatch();
    const currentHeight = useSelector(state => state.height);
    const currentWidth = useSelector(state => state.width); 
    const userTransactions = useSelector(state => state.transactions);
    const userAccountNumber = useSelector(state => state.accountNumber);
    const userSortCode = useSelector(state => state.sortCode);
    const userTotalEarnings = useSelector(state => state.totalEarnings);
    const userActiveBalance = useSelector(state => state.activeBalance);
    const history = useHistory();
   
    useEffect(() => {

        firebase.auth().onAuthStateChanged( user => {
            if (user) {
                dispatch(accountCreated(user.metadata.creationTime));
                
                firebase.firestore().collection("Creator Profile").doc(user.uid)
                .onSnapshot(snapshot => {
                    if(!snapshot.exists) {
                        console.log('No such document!')
                    }
                    else {
                        const data = snapshot.data();
                        if ( data.accountNumber !== undefined ) {
                            const currentAccountNumber = data.accountNumber;
                            dispatch(accountNumber(currentAccountNumber));
                        }
                        if ( data.sortCode !== undefined ) {
                            const currentSortCode = data.sortCode;
                            dispatch(sortCode(currentSortCode));
                        }
                        if ( data.transactions !== undefined ) {
                            const currentTransactions = data.transactions;
                            dispatch(transactions(currentTransactions));
                        }
                        if ( data.totalEarnings !== undefined ) {
                            const currentTotalEarnings = data.totalEarnings;
                            dispatch(totalEarnings(currentTotalEarnings));
                        }
                    }
                })
            }
            else {
                history.push('./');
            }
        })
    })

    const styles = {
        centre: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        },
        row: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center'
        },
        total: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: currentWidth * 0.2
        },
        transaction: {
            width: currentWidth * 0.7,
            backgroundColor: colour.lightestGray,
            borderRadius: currentWidth * 0.08,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        },
        text: {
            width: currentWidth * 0.6,
            
        },
        summaryRow: {
            display: 'flex',
            flexDirection: 'row'
        },
        summary: {
            width: currentWidth * 0.45
        },
        numbers: {
            width: currentWidth * 0.1
        },
        desktopWidth: {
            width: currentWidth * 0.8,
        },
        desktopContainer: {
            backgroundColor: colour.lightestGray,
            borderRadius: currentWidth * 0.1,
            display: 'flex',
            flexDirection: 'row',
            paddingTop: currentHeight * 0.02,
            paddingBottom: currentHeight * 0.02
        },
        poundContainer: {
            width: currentWidth * 0.2,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        },
        poundCoin: {
            width: currentWidth * 0.05
        },
        totalContainer: {
            width: currentWidth * 0.2,
        },
        desktopTransfer: {
            width: currentWidth * 0.4,
        },
        desktopSummary: {
            width: currentWidth * 0.4,
        },
        payoutDate: {
            width: currentWidth * 0.8
        },
        revenues: {
            width: currentWidth * 0.8,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center'
        },
        revenueContainer: {
            width: currentWidth * 0.4
        }
    }

    return (
        <div style={styles.centre}>
            <Header />
            <div style={styles.centre}>
                <h1 style={{ color: colour.dark, fontSize: '1.375em', fontFamily: fontFamily, textAlign: 'center', fontWeight: 'bold' }}>
                    Transaction History
                </h1>
                <div style={{ height: currentHeight * 0.02 }} />
                <div style={styles.revenues}>
                    <div style={styles.revenueContainer}>
                        <p style={{ color: colour.primaryMuted, fontSize: '1.375em', fontFamily: fontFamily, fontWeight: 'bold' }}>
                            £{userActiveBalance === 0 ? userTotalEarnings : userActiveBalance}.00
                        </p>
                        <p style={{ color: colour.primaryMuted, fontSize: '0.75em', fontFamily: fontFamily, fontWeight: 'lighter' }}>
                            Current Active Balance
                        </p>
                    </div>
                    <div style={styles.revenueContainer}>
                        <p style={{ color: colour.primaryMuted, fontSize: '1.375em', fontFamily: fontFamily, fontWeight: 'bold' }}>
                            £{userTotalEarnings}.00
                        </p>
                        <p style={{ color: colour.primaryMuted, fontSize: '0.75em', fontFamily: fontFamily, fontWeight: 'lighter' }}>
                            Lifetime Net Revenue
                        </p>
                    </div>
                </div>
                <div style={{ height: currentHeight * 0.02 }} />
                <div style={styles.payoutDate}>
                    <p style={{ color: colour.med, fontSize: '0.875em', fontFamily: fontFamily, textAlign: 'left', fontWeight: 'bold' }}>
                        Next Payout* date is 30th{" "} October, 2020.
                    </p>
                    <p style={{ color: colour.med, fontSize: '0.875em', fontFamily: fontFamily, textAlign: 'left' }}>
                        *Note: Your balance must be at least £10 before 
                        fees in order to receive a payout, otherwise the 
                        payout will be pushed to the following month.
                    </p>
                </div>
            </div>
            <div style={{ height: currentHeight * 0.05 }} />
            {
                isMobile === true ?
                    userTransactions.map((item, index) =>
                    <div style={styles.row}>
                        <div style={styles.total}>
                            <p style={{ fontFamily: fontFamily, fontSize: '1.125em', color: colour.primaryMuted, fontWeight: 'bold' }}>
                                + £{userTotalEarnings}
                            </p>
                        </div>
                        <div style={styles.transaction}>
                            <div style={styles.text}>
                                <div style={{ height: currentHeight * 0.02 }} />
                                <h2 style={{ color: colour.dark, fontSize: '1.125em', fontFamily: fontFamily, textAlign: 'center', fontWeight: 'bold' }}>
                                    {item.month} Balance Transfer
                                </h2>
                                <div style={{ height: currentHeight * 0.02 }} />
                                <p style={{ color: colour.med, fontWeight: 'lighter', fontSize: '1em', fontFamily: fontFamily }}>
                                    Account No: {userAccountNumber}
                                </p>
                                <p style={{ color: colour.med, fontWeight: 'lighter', fontSize: '1em', fontFamily: fontFamily }}>
                                    Sort Code: {userSortCode}
                                </p>
                                <div style={{ height: currentHeight * 0.04 }} />
                                <h3 style={{ color: colour.med, fontSize: '1em', fontFamily: fontFamily, fontWeight: 'bold' }}>
                                    Summary
                                </h3>
                                <div style={{ height: currentHeight * 0.04 }} />
                                <div style={styles.summaryRow}>
                                    <div style={styles.summary}>
                                        <p style={{ color: colour.med, fontWeight: 'lighter', fontSize: '1em', fontFamily: fontFamily }}>
                                            Total Revenue:
                                        </p>
                                        <p style={{ color: colour.med, fontWeight: 'lighter', fontSize: '1em', fontFamily: fontFamily }}>
                                            Service Fees:
                                        </p>
                                        <p style={{ color: colour.med, fontWeight: 'lighter', fontSize: '1em', fontFamily: fontFamily }}>
                                            Transaction Fees:
                                        </p>
                                    </div>
                                    <div style={styles.numbers}>
                                        <p style={{ color: colour.med, fontWeight: 'lighter', fontSize: '1em', fontFamily: fontFamily, textAlign: 'right' }}>
                                            £{item.totalRevenue}
                                        </p>
                                        <p style={{ color: colour.med, fontWeight: 'lighter', fontSize: '1em', fontFamily: fontFamily, textAlign: 'right' }}>
                                            £{item.serviceFees}
                                        </p>
                                        <p style={{ color: colour.med, fontWeight: 'lighter', fontSize: '1em', fontFamily: fontFamily, textAlign: 'right' }}>
                                            £{item.transactionFees}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div style={{ height: currentHeight * 0.05 }} />
                        </div>
                    </div>
                    )
                    :
                    userTransactions.map((item, index) =>
                        <div style={styles.desktopWidth}>
                            <div style={styles.desktopContainer}>
                                <div style={styles.poundContainer}>
                                    <img
                                        src={require('./pound-color.svg')}
                                        style={styles.poundCoin}
                                        alt=""
                                    />
                                </div>
                                <div style={styles.totalContainer}>
                                    <p style={{ color: colour.primaryMuted, fontWeight: 'bold', fontSize: '1em', fontFamily: fontFamily }}>
                                        + {userTotalEarnings}
                                    </p>
                                </div>
                                <div style={styles.desktopTransfer}>
                                    <p style={{ color: colour.dark, fontFamily: fontFamily, fontWeight: 'bold', fontSize: '1em' }}>
                                        {item.month} Balance Transfer
                                    </p>
                                    <p style={{ color: colour.med, fontFamily: fontFamily, fontWeight: 'lighter', fontSize: '1em' }}>
                                        Account No: {item.accountNumber}
                                    </p>
                                    <p style={{ color: colour.med, fontFamily: fontFamily, fontWeight: 'lighter', fontSize: '1em' }}>
                                        Sort Code: {item.sortCode}
                                    </p>
                                </div>
                                <div style={styles.desktopSummary}>
                                    <p style={{ color: colour.med, fontFamily: fontFamily, fontWeight: 'bold', fontSize: '1em' }}>
                                        {item.month} Balance Transfer
                                    </p>
                                    <p style={{ color: colour.med, fontFamily: fontFamily, fontWeight: 'lighter', fontSize: '1em' }}>
                                        Total Revenue: £{item.totalRevenue}
                                    </p>
                                    <p style={{ color: colour.med, fontFamily: fontFamily, fontWeight: 'lighter', fontSize: '1em' }}>
                                        Service Fees: £{item.serviceFees}
                                    </p>
                                    <p style={{ color: colour.med, fontFamily: fontFamily, fontWeight: 'lighter', fontSize: '1em' }}>
                                        Transaction Fees: £{item.transactionF}
                                    </p>
                                </div>
                            </div>
                        </div>
                    )
            }
        </div>
    )
}

export default Transactions;