import React from 'react';
import ReactPlayer from 'react-player';
import { useSelector, useDispatch } from 'react-redux';
import { colour, fontFamily } from './GlobalStyles';
import { questionVideo, questionVideoIndex, playVideo, authenticationDisabledButton, loadingIndex } from './actions';
import { isMobile } from 'react-device-detect';
import Lottie from 'react-lottie-player';
import lottieJson from './loading-animation.json';

function VideoList() {
    const currentWidth = useSelector(state => state.width);
    const currentHeight = useSelector(state => state.height);
    const questionVideoState = useSelector(state => state.questionVideo);
    const questionVideoIndexState = useSelector(state => state.questionVideoIndex);
    const videoPlaying = useSelector(state => state.playVideo);
    const answeredQuestions = useSelector(state => state.answeredQuestions);
    const loading = useSelector(state => state.authenticationDisabledButton);
    const currentLoadingIndex = useSelector(state => state.loadingIndex);
    const dispatch = useDispatch();

    const pausePlay = (index, play) => {
        if ( index === play ) {
            
            dispatch(playVideo(null));
        }
        else {
            
            dispatch(playVideo(index));
        }
    }
    
    const onBuffer = (index) => {
        dispatch(authenticationDisabledButton(true));
        dispatch(loadingIndex(index));
    }
    
    const onBufferEnd = () => { 
        dispatch(authenticationDisabledButton(false));
        dispatch(loadingIndex(0))
    }

    function questionIndex(item, index) {
        dispatch(questionVideo(questionVideoState === false ? true : true));
        dispatch(questionVideoIndex(index));

        if ( questionVideoState === true && index === questionVideoIndexState ) {
            dispatch(questionVideo(true));
            dispatch(questionVideoIndex(null));
        }
    }

    const vw = 100 / 2 + "vw";

    const styles = {
        outerContainer: {
            flexDirection: 'row', 
            display: 'flex',
            width: isMobile === true ? currentWidth : currentWidth * 0.6, 
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'center'
        },
        innerContainer: {
            width: isMobile === true ? currentWidth * 0.48 : currentWidth * 0.18,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            border: `1px solid ${colour.primary}`,
            borderRadius: '20px'
        },
        question: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: isMobile === true ? currentWidth * 0.02 : 0.02,
            height: isMobile === true ? currentHeight * 0.4 : currentHeight * 0.4,
            width: isMobile === true ? '45vw' : currentWidth * 0.15
        },
        circle: {
            position: 'absolute',
            zIndex: 1,
            backgroundColor: colour.med,
            width: currentWidth * 0.1,
            height: currentWidth * 0.1,
            borderRadius: currentWidth * 0.05
        },
        videoCircle: {
            position: 'absolute',
            zIndex: 1,
            width: isMobile === true ? currentWidth * 0.15 : currentWidth * 0.04,
            height: isMobile === true ? currentWidth * 0.15 : currentWidth * 0.04,
            borderRadius: isMobile === true ? currentWidth * 0.075 : currentWidth * 0.02,
            cursor: 'pointer'
        },
        emptyCircle: {
            position: 'absolute',
            zIndex: 1,
            width: isMobile === true ? currentWidth * 0.2 : currentWidth * 0.04,
            height: isMobile === true ? currentWidth * 0.2 : currentWidth * 0.04,
            borderRadius: isMobile === true ? currentWidth * 0.1 : currentWidth * 0.02,
            cursor: 'pointer'
        },
        row: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
        },
        questionerImage: {
            width: isMobile === true ? currentWidth * 0.1 : currentWidth * 0.04,
            height: isMobile === true ? currentWidth * 0.1 : currentWidth * 0.04,
            borderRadius: isMobile === true ? currentWidth * 0.05 : currentWidth * 0.02
        },
        loading: {
            width: currentWidth * 0.2,
            position: 'absolute',
            zIndex: 1
        }
    }
    
    return (
        <div style={styles.outerContainer}>
            {
            answeredQuestions.map((item, index) =>
                <div style={styles.innerContainer}>
                    {
                        index !== questionVideoIndexState ?
                        <div
                            onClick={() => questionIndex(item, index)}
                        >
                            <div style={{ height: isMobile === true ? currentHeight * 0.1 : 0 }} />
                            <div style={styles.question}>
                                <div style={{ height: currentHeight * 0.02 }} />
                                <p style={{ textAlign: isMobile === true ? 'center' : 'left', color: colour.dark, fontFamily: fontFamily, fontSize: '0.875em', fontWeight: 'bold', lineHeight: '1.2em' }}>
                                    {item.question}
                                </p>
                                <div style={{ height: currentHeight * 0.02 }} />
                                <div style={styles.row}>
                                    <img
                                        src={item.questioner === 'anonymous' ? require('./anonymous.svg') : item.questionerImage === "" ? require('./anonymous.svg') : item.questionerImage}
                                        style={styles.questionerImage}
                                        alt=""
                                    />
                                    <div style={{ width: currentWidth * 0.01 }} />
                                    <p style={{ textAlign: 'center', color: colour.primaryMuted, fontFamily: fontFamily, fontSize: '0.875em', fontWeight: 'bold' }}>
                                        @{item.questioner}
                                        <br/>
                                        asked
                                    </p>
                                </div>
                            </div>
                        </div>
                        :
                        <div>
                            <div 
                                onClick={() => questionIndex(item, index)}
                                style={styles.emptyCircle}
                            >
                                <img
                                    src={require('./question-reveal.svg')}
                                    style={styles.videoCircle}
                                    alt="questionReveal"
                                />
                            </div>
                            {
                                loading === true && index === currentLoadingIndex ?
                                <Lottie
                                    loop
                                    animationData={lottieJson}
                                    play
                                    style={styles.loading}
                                />
                                :
                                null
                            }
                            <ReactPlayer
                                url={item.creatorVideo}
                                playing={index === videoPlaying ? true : false}
                                onClick={() => pausePlay(index, videoPlaying)}
                                loop={false}
                                controls={true}
                                height="100%"
                                width={isMobile === true ? vw : currentWidth * 0.2}
                                onBufferEnd={() => onBufferEnd()}
                                onBuffer={() => onBuffer(index)}
                            />
                        </div>
                    }
                </div>
            )
            }
        </div>
    )
}

export default VideoList;