import React from 'react';
import './Terms&Conditions.css';
import Footer from './Footer';
import FooterDesktop from './FooterDesktop';
import { isMobile } from 'react-device-detect';

function PrivacyPolicy() {

    return (
        <div className="centre">
            <h1>
                Privacy Policy
            </h1>
            <h1>
                1. INTRODUCTION
            </h1>
            <p>
                Thank you for using Damamma! 

                This Privacy Policy describes how we collect, use, process, and disclose your information, including personal information, in conjunction with your access to and use of the Damamma Platform and the Payment Services.
            </p>
            <h1>
                1.1 DEFINITIONS
            </h1>
            <p>
                If you see an undefined term in this Privacy Policy (such as “Independent Partner” or “Damamma Platform”), it has the same definition as in our Terms of Service. 
            </p>
            <h1>
                1.2 DATA CONTROLLER
            </h1>
            <p>
                When this policy mentions Damamma,” “we,” “us,” or “our,” it refers to the company incorporated in England & Wales, Damamma Holdings Limited, company number: 11918774 that is responsible for your information under this Privacy Policy (the “Data Controller”).
            </p>
            <h1>
                2. INFORMATION WE COLLECT
            </h1>
            <p>
                There are three general categories of information we collect.
            </p>
            <h2>
                2.1 Information You Give to Us.
            </h2>
            <h3>
                2.1.1 Information that is necessary for the use of the Damamma Platform.
            </h3>
            <ul>
                <li>
                    Account Information. When you sign up for a Damamma Account, we require certain information such as your first name, last name, email address, and date of birth. 
                </li>
                <li>
                	Profile and Listing Information. To use certain features of the Damamma Platform, we may ask you to provide additional information, which may include your address, phone number, and a profile picture.
                </li>
                <li>
                	Identity Verification Information. To help create and maintain a trusted environment, we may collect identity verification information (such as images of your government issued ID, passport, national ID card, or driving license, as permitted by applicable laws) or other authentication information. 
                </li>
                <li>
                	Payment Information. To use certain features of the Damamma Platform, we may require you to provide certain financial information (such as your bank account details or credit/debit card details) in order to facilitate the processing of payments (via Damamma Payments). 
                </li>
                <li>
                	Communications with Damamma and other Members. When you communicate with Damamma or use the Damamma Platform to communicate with other Members, we collect information pertaining to your communication and any information you choose to provide.
                </li>
            </ul>
            <h3>
                2.1.2 Information You Choose To Give Us
            </h3>
            <p>
                You may choose to provide us with additional personal information in order to obtain a better user experience when using Damamma Platform. This additional information will be processed based on our legitimate interest or when applicable, your consent.
            </p>
            <ul>
                <li>
                	Additional Profile Information. You may choose to provide additional information as part of your Damamma profile (such as gender, language and a personal description). 
                </li>
                <li>
                	Other Information. You may otherwise choose to provide us information when you fill in a form, conduct a search, update or add information to your Damamma Account, respond to surveys, post to community forums, participate in promotions or use other features on the Damamma platform.
                </li>
            </ul>
            <h3>
                2.1.3 Information That is Necessary for the Use of Payment Services
            </h3>
            <p>
                The Payments Data Controller needs to collect the following information necessary for the adequate performance of the contract with you and to comply with applicable law (such as anti-money laundering regulations). Without it, you will not be able to use Payment Services:
            </p>
            <ul>
                <li>
                	Profile Information. When you use the Payment Services, the Payment Data Controller requires certain financial information (such as your bank account details or credit card details) in order to process payments and comply with applicable law. 
                </li>
                <li>
                	Identify Verification and Other Information. If you are an Independent Partner, the Payments Data Controller may require identity verification information (such as images of your government issued ID, password, national ID card, or driving license) or other authentication information, your date of birth, your address, email address, phone number and other information in order to verify your identity, provide the Payments Services to you and to comply with applicable law. 
                </li>
            </ul>
            <h3>
                2.1.4 Information We Collect from Your Use of the Damamma Platform
            </h3>
            <p>
                When you use the Damamma Platform and the Payment Services, we automatically collect information, including personal information, about the services you use and how you use them. This information is necessary for the adequate performance of the contract between you and us, to enable us to comply with legal obligations and given our legitimate interest in being able to provide and improve the functionalities of the Damamma Platform and Payment Services.
            </p>
            <ul>
                <li>
                	Geo-location Information. When you use certain features of the Damamma Platform, we may collect information about your precise or approximate location as determined through data such as your IP address or mobile device’s GPS to offer you an improved user experience. Most mobile devices allow you to control or disable the use of location services for applications in the device’s settings menu. 
                </li>
                <li>
                	Usage Information. We collect information about your interactions with the Damamma Platform such as the pages or content you view, your searches for profiles, questions you have asked, and other actions on the Damamma Platform. 
                </li>
                <li>
                	Log Data and Device Information. We automatically collect log data and device information when you access and use the Damamma Platform, even if you have not created a Damamma Account or logged in. That information includes, among other things: details about how you have used the Damamma Platform (including if you clicked on links to third party applications), IP address, access dates and times, hardware and software information, device information, device event information, unique identifiers, crash data, cookie data, and the pages you’ve viewed or engaged with before or after using the Damamma Platform. 
                </li>
                <li>
                	Cookies and Similar Technologies. We use cookies and other similar technologies, such as web beacons, pixels, and mobile identifiers. We may also allow our business partners to use these tracking technologies on the Damamma Platform, or engage others to track your behaviour on our behalf. You may disable the usage of cookies through your browser settings. 
                </li>
                <li>
                	Payment Transaction Information. Damamma Payments collects information related to your payment transactions through the Damamma Platform, including the payment instrument used, date and time, payment amount, payment instrument expiration date and billing postcode, IBAN information, your address and other related transaction details. This information is necessary for the adequate performance of the contract between you and Damamma Payments and to allow the provision of the Payment Services. 
                </li>
            </ul>
            <h3>
                2.1.5 Information We Collect from Third Parties
            </h3>
            <p>
                Damamma and Damamma Payments may collect information, including personal information, that others provide about you when they use the Damamma Platform and the Payments Service. We do not control, supervise or response for how the third parties providing your information process your personal data, and any information request regarding the disclosure of your personal information to us should be direct to such third parties.
            </p>
            <ul>
                <li>
                	Third Party Services. If you link, connect, or login to your Damamma Account with a third-party service (e.g. Google, Facebook), the third-party service may send us information such as your registration and profile information from that service. This information varies and is controlled by that service. 
                </li>
                <li>
                	Background Information. For Members in the United States, to the extent permitted by applicable laws, Damamma and Damamma Payments may obtain reports from public records of criminal convictions or sex offender registrations. For Members outside of the United States, to the extent permitted by applicable laws and with your consent where required, Damamma and Damamma Payments may obtain the local version of police, background or registered sex offender checks. We may use your information, including your full name and date of birth, to obtain such reports. 
                </li>
                <li>
                	Cookies and Similar Technologies. We use cookies and other similar technologies, such as web beacons, pixels, and mobile identifiers. We may also allow our business partners to use these tracking technologies on the Damamma Platform or engage others to track your behaviour on our behalf. You may disable the usage of cookies through your browser settings. 
                </li>
                <li>
                	Other Sources. To the extent permitted by applicable law, we may receive additional information about you, such as demographic data or fraud detection information, from third party service providers and/or partners, and combine it with information we have about you. For example, we may receive background check results (with your consent where required) or fraud warnings from service providers like identity verification services for our fraud prevention and risk assessment efforts. We may receive information about you and your activities on and off the Damamma Platform through partnerships, or about your experiences and interactions from our partner ad networks. 
                </li>
            </ul>
            <h1>
                HOW WE USE INFORMATION WE COLLECT
            </h1>
            <h2>
                Data Protection Officer
            </h2>
            <p>
                The Data Protection Officer (D.P.O.) for Damamma is Mr Liam Patrick Maher, Managing Director of Damamma Holdings Limited.

                The D.P.O. reserves the right to access the stored data in order to fulfil his/her role.
            </p>
            <h2>
                3.1 Provide, Improve, and Develop the Damamma Platform. 
            </h2>
            <p>
                We may use the personal information to provide, improve, and develop the Damamma Platform such as to:
            </p>
            <ul>
                <li>
                	Enable you to access and use the Damamma Platform. 
                </li>
                <li>
                	Enable you to communicate with other Members. 
                </li>
                <li>
                	Operate, protect, improve, and optimise the Damamma Platform and experience, such as by performing analytics and conducting research. 
                </li>
                <li>
                    Provide customer service.
                </li>
                <li>
                	Send you service or support messages, updates, security alerts, and account notifications. 
                </li>
                <li>
                	If you provide us with your contacts’ information, we may process this information: (i) to facilitate your referral invitations, (ii) send your requests for references, (iii) for fraud detection and prevention, and (iv) for any purpose you authorise at the time of collection. 
                </li>
                <li>
                	To operate, protect, improve, and optimise the Damamma Platform and experience, and personalise and customise your experience, we conduct profiling based on your interactions with the Damamma Platform, your search and booking history, your profile information and preferences, and other content you submit to the Damamma Platform. 
                </li>
            </ul>
            <h2>
                3.2 Create and Maintain a Trusted and Safer Environment We may use the personal information to create and maintain a trusted and safer environment such as to:
            </h2>
            <ul>
                <li>
                	Detect and prevent fraud, spam, abuse, security incidents, and other harmful activity. 
                </li>
                <li>
                	Conduct security investigations and risk assessments. 
                </li>
                <li>
                	Verify or authenticate information or identifications provided by you (such as to verify your address or compare your identification photo to another photo you provide). 
                </li>
                <li>
                	Conduct checks against databases and other information sources, including background or police checks, to the extent permitted by applicable laws and with your consent where required. 
                </li>
                <li>
                	Comply with our legal obligations. 
                </li>
                <li>
                	Resolve any disputes with any of our Members and enforce our agreements with third parties. 
                </li>
                <li>
                	Terms of Service and other policies. 
                </li>
                <li>
                	In connection with the activities above, we may conduct profiling based on your interactions with the Damamma Platform, your profile information and other content you submit to the Damamma Platform, and information obtained from third parties. In limited cases, automated processes may restrict or suspend access to the Damamma Platform if such processes detect a Member or activity that we think poses a safety or other risk to the Damamma Platform, other Members, or third parties.
                </li>
            </ul>
            <p>
                We process this information given our legitimate interest in protecting the Damamma Platform, to measure the adequate performance of our contract with you, and to comply with applicable laws.
            </p>
            <h2>
                3.3 Provide, Personalise, Measure and Improve our Marketing We may use the personal information to provide, personalize, measure, and improve our advertising and marketing such as to:
            </h2>
            <ul>
                <li>
                	Send you promotional messages, marketing, advertising, and other information that may be of interest to you based on your preferences (including information about Damamma or partner campaigns and services) and social media advertising through social media platforms such as Facebook or Google). 
                </li>
                <li>
                	Personalise, measure, and improve our advertising. 
                </li>
                <li>
                	Administer referral programs, rewards, surveys, sweepstakes, contests, or other promotional activities or events sponsored or managed by Damamma or its third-party partners. 
                </li>
                <li>
                	Conduct profiling on your characteristics and preferences (based on the information you provide to us, your interactions with the Damamma Platform, information obtained from third parties, and your search and booking history) to send you promotional messages, marketing, advertising and other information that we think may be of interest to you. 
                </li>
            </ul>
            <h2>
                3.4 How the Payments Data Controller use the Information We may use the personal information as a part of Payment services such as to: 
            </h2>
            <ul>
                <li>
                	Enable you to access and use the Payment Services. 
                </li>
                <li>
                	Detect and prevent fraud, abuse, security incidents, and other harmful activity. 
                </li>
                <li>
                	Conduct security investigations and risk assessments. 
                </li>
                <li>
                	Conduct checks against databases and other information sources. 
                </li>
                <li>
                	Comply with legal obligations (such as anti-money laundering regulations). 
                </li>
                <li>
                	Enforce the Payment Terms and other payment policies. 
                </li>
            </ul>
            <h1>
                4. SHARING AND DISCLOSURE
            </h1>
            <h2>
                4.1 With Your Consent
            </h2>
            <p>
                Where you have provided consent, we share your information, including personal information, as described at the time of consent, such as when you authorise a third party application or website to access your Damamma Account or when you participate in promotional activities conducted by Damamma partners or third parties.
            </p>
            <h2>
                4.2 Between Members
            </h2>
            <p>
                To help facilitate bookings or other interactions between Members, we may need to share certain information, including personal information, with other Members, as it is necessary for the adequate performance of the contract between you and us, as follows:
            </p>
            <ul>
                <li>
                	When you as an Independent Partner accept a Request, certain information is shared with the Consumer to coordinate the Request; such as your first name and your collection address.
                </li>
            </ul>
            <p>
                We don’t share your billing and payout information with other Members. 
            </p>
            <h2>
                4.3 Profile, Listings and Other Public Information 
            </h2>
            <p>
                The Damamma Platform lets you publish information, including personal information, that is visible to the general public. For example:
            </p>
            <ul>
                <li>
                	Parts of your public profile page, such as your first name, your description, and city, are publicly visible to others.
                </li>
                <li>
                	Listing pages are publicly visible and include information such as the Independent Partner’s approximate location (neighbourhood and city) or precise location (where you have provided your consent), Listing description, calendar availability, your public profile photo, aggregated demand information (like page views over a period of time), and any additional information you choose to share.
                </li>
                <li>
                	After completing a booking, Consumers and Independent Partners may write Reviews and rate each other. Reviews and Ratings are a part of your public profile page and may also be surfaced elsewhere on the Damamma Platform.
                </li>
                <li>
                	If you submit content in a community or discussion forum, blog or social media post, or use a similar feature on the Damamma Platform, that content is publicly visible. 
                </li>
            </ul>
            <p>
                Information you share publicly on the Damamma Platform may be indexed through third party search engines. In some cases, you may opt-out of this feature in your Account settings. If you change your settings or your public-facing content, these search engines may not update their databases. We do not control the practices of third-party search engines, and they may use caches containing your outdated information.  
            </p>
            <h2>
                4.4 Compliance with Law and Protection of our Rights
            </h2>
            <p>
                Damamma and Damamma Payments may disclose your information, including personal information, to courts, law enforcement or governmental authorities, or authorized third parties, if and to the extent we are required or permitted to do so by law or if such disclosure is reasonably necessary: (i) to comply with our legal obligations, (ii) to comply with legal process and to respond to claims asserted against Damamma, (iii) to respond to verified requests relating to a criminal investigation or alleged or suspected illegal activity or any other activity that may expose us, you, or any other of our users to legal liability, (iv) to enforce and administer our Terms of Service, the Payment Terms or other agreements with Members, or (v) to protect the rights, property or personal safety of Damamma, its employees, its Members, or members of the public. 
            </p>
            <p>
                These disclosures may be necessary to comply with our legal obligations, for the protection of your or another person's vital interests or for the purposes of our or a third party’s legitimate interest in keeping the Damamma Platform secure, preventing harm or crime, enforcing or defending legal rights, or preventing damage. 
            </p>
            <p>
                Where appropriate, we may notify Members about legal requests unless: (i) providing notice is prohibited by the legal process itself, by court order we receive, or by applicable law, or (ii) we believe that providing notice would be futile, ineffective, create a risk of injury or bodily harm to an individual or group, or create or increase a risk of fraud Damamma’s property, its Members and the Damamma Platform. In instances where we comply with legal requests without notice for these reasons, we will attempt to notify that Member about the request after the fact where appropriate and where we determine in good faith that we are no longer prevented from doing so.
            </p>
            <h2>
                4.5 Service Providers
            </h2>
            <p>
                Damamma and Damamma Payments uses a variety of third-party service providers to help us provide services related to the Damamma Platform and the Payment Services. Service providers may be located inside or outside of the European Economic Area (“EEA”). Our service providers are based in Europe, India, Asia Pacific and North and South America. 
            </p>
            <p>
                For example, service providers may help us: (i) verify your identity or authenticate your identification documents, (ii) check information against public databases, (iii) conduct background or police checks, fraud prevention, and risk assessment, (iv) perform product development, maintenance and debugging, or (v) provide customer service, or payments services. These providers have limited access to your information to perform these tasks on our behalf and are contractually bound to protect and to use it only for the purposes for which it was disclosed and consistent with this Privacy Policy. 
            </p>
            <p>
                Damamma and the Damamma Payments will need to share your information, including personal information, in order to ensure the adequate performance of our contract with you.
            </p>
            <h2>
                4.6 Social Media Platforms  
            </h2>
            <p>
                Where permissible according to applicable law we may use certain limited personal information about you, such as your email address, hash it and to share it with social media platforms, such as Facebook, Instagram, LinkedIn or Google, to generate leads, drive traffic to our websites or otherwise promote our products and services or the Damamma Platform. These processing activities are based on our legitimate interest in undertaking marketing activities to offer you products or services that may be if your interest.
            </p>
            <p>
                The social media platforms with which we may share your personal data are not controlled or supervised by Damamma. Therefore, any questions regarding how your social media platform service provider processes your personal data should be directed to such provider.
            </p>
            <p>
                Please note that you may, at any time ask Damamma to cease processing your data for these direct marketing purposes by sending an e-mail to liam.maher@damamma.app
            </p>
            <p>
                We may also share aggregated information (information about our users that we combine so that it no longer identifies or references an individual user) and other anonymised information for regulatory compliance, industry and market analysis, demographic profiling, marketing and advertising, and other business purposes. 
            </p>
            <h2>
                4.7 Business Transfers.
            </h2>
            <p>
                If Damamma undertakes or is involved in any merger, acquisition, reorganization, sale of assets, bankruptcy, or insolvency event, then we may sell, transfer or share some or all of our assets, including your information in connection with such transaction or in contemplation of such transaction (e.g., due diligence). In this event, we will notify you before your personal information is transferred and becomes subject to a different privacy policy.
            </p>
            <h2>
                4.8 Aggregated Data.  
            </h2>
            <p>
                We may also share aggregated information (information about our users that we combine together so that it no longer identifies or references an individual user) and other anonymized information for regulatory compliance, industry and market analysis, research, demographic profiling, marketing and advertising, and other business purposes.
            </p>
            <h1>
                5. OTHER IMPORTANT INFORMATION
            </h1>
            <h2>
                5.1 Analysing your Communication
            </h2>
            <p>
                We may review, scan, or analyse your communications on the Damamma Platform for fraud prevention, risk assessment, regulatory compliance, investigation, product development, research, and customer support purposes. For example, as part of our fraud prevention efforts, we scan and analyse messages to mask contact information and references to other websites. In some cases, we may also scan, review, or analyse messages to debug, improve, and expand product offerings. We use automated methods where reasonably possible. However, occasionally we may need to manually review some communications, such as for fraud investigations and customer support, or to assess and improve the functionality of these automated tools. We will not review, scan, or analyse your communications to send third party marketing messages to you, and we will not sell reviews or analyses of these communications. 
            </p>
            <p>
                These activities are carried out based on Damamma’s legitimate interest in ensuring compliance with applicable laws and our Terms, preventing fraud, promoting safety, and improving and ensuring the adequate performance of our services.
            </p>
            <h2>
                5.2 Google Maps/Earth
            </h2>
            <p>
                Parts of the Damamma Platform use Google Maps/Earth services, including the Google Maps API(s). Use of Google Maps/Earth is subject to Google Maps/Earth Additional Terms of Use and the to Google Privacy Policy.
            </p>
            <h2>
                5.3 Applicable Law & Exclusive Jurisdiction
            </h2>
            <p>
                This Privacy Policy is governed by and construed in accordance with the laws of England and Wales. If you are acting as a Consumer or Independent Partner and if mandatory statutory consumer protection regulations in your country of residence contain provisions that are more beneficial for you, such provisions shall apply irrespective of the choice of English and Welsh law. As a Consumer or Independent Partner, you may bring any judicial proceedings relating to these Terms before the competent court of your place of residence or the competent court of Damamma’s place of business in England and Wales. If Damamma wishes to enforce any of its rights against you as a Consumer or Independent Partner, we may do so only in the courts of the jurisdiction in which you are a resident. If you are acting as a business, you agree to submit to the exclusive jurisdiction of the English and Welsh courts.
            </p>
            <h1>
                6. YOUR RIGHTS
            </h1>
            <p>
                You may exercise any of the rights described in this section before your applicable Data Protection Officer by sending an email to liam.maher@damamma.app. Please note that we may ask you to verify your identity before taking further action on your request.
            </p>
            <h2>
                6.1 Managing Your Information
            </h2>
            <p>
                You may access and update some of your information through your Account settings.
            </p>
            <h2>
                6.2 Rectification of Inaccurate or Incomplete Information
            </h2>
            <p>
                You have the right to ask us to correct inaccurate or incomplete personal information concerning you (and which you cannot update yourself within your Damamma Account).
            </p>
            <h2>
                6.3 Data Access and Portability
            </h2>
            <p>
                In some jurisdictions, applicable law may entitle you to request copies of your personal information held by us. You may also be entitled to request copies of personal information that you have provided to us in a structured, commonly used, and machine-readable format and/or request us to transmit this information to another service provider (where technically feasible). 
            </p>
            <h2>
                6.4 Data Retention and Erasure
            </h2>
            <p>
                We generally retain your personal information for as long as is necessary for the performance of the contract between you and us and to comply with our legal obligations. If you no longer want us to use your information to provide the Damamma Platform to you, you can request that we erase your personal information and close your Damamma Account. Please note that if you request the erasure of your personal information: 
            </p>
            <ul>
                <li>
                	We may retain some of your personal information as necessary for our legitimate business interests, such as fraud detection and prevention and enhancing safety. For example, if we suspend a Damamma Account for fraud or safety reasons, we may retain certain information from that Damamma Account to prevent that Member from opening a new Damamma Account in the future.
                </li>
                <li>
                	We may retain and use your personal information to the extent necessary to comply with our legal obligations. For example, Damamma and Damamma Payments may keep some of your information for tax, legal reporting and auditing obligations.
                </li>
                <li>
                	Information you have shared with others (e.g., Reviews, forum postings) may continue to be publicly visible on the Damamma Platform, even after your Damamma Account is cancelled. However, attribution of such information to you will be removed. Additionally, some copies of your information (e.g., log records) may remain in our database, but are disassociated from personal identifiers.  
                </li>
                <li>
                	Because we maintain the Damamma Platform to protect from accidental or malicious loss and destruction, residual copies of your personal information may not be removed from our backup systems for a limited period.
                </li>
            </ul>
            <h2>
                6.5 Withdrawing Consent and Restriction of Processing.
            </h2>
            <p>
                If we are processing your personal information based on your consent you may withdraw your consent at any time by changing your Account settings or by sending a communication to Damamma specifying which consent you are withdrawing. Please note that the withdrawal of your consent does not affect the lawfulness of any processing activities based on such consent before its withdrawal.
            </p>
            <h2>
                6.6 Objection to Processing
            </h2>
            <p>
                In some jurisdictions, applicable law may entitle you to require Damamma and Damamma Payments not to process your personal information for certain specific purposes (including profiling) where such processing is based on legitimate interest. If you object to such processing Damamma and/or Damamma Payments will no longer process your personal information for these purposes unless we can demonstrate compelling legitimate grounds for such processing, or such processing is required for the establishment, exercise or defence of legal claims. 
                Where your personal information is processed for direct marketing purposes, you may, at any time ask Damamma to cease processing your data for these direct marketing purposes by sending an e-mail to liam.maher@damamma.app
            </p>
            <h2>
                6.7 Complaints
            </h2>
            <p>
                You have the right to lodge complaints about the data processing activities carried out by Damamma and Damamma Payments before the competent data protection authorities.
            </p>
            <h1>
                7. SECURITY
            </h1>
            <p>
                We are continuously implementing and updating administrative, technical, and physical security measures to help protect your information against unauthorised access, loss, destruction, or alteration. Some of the safeguards we use to protect your information are firewalls and data encryption, and information access controls. If you know or have reason to believe that your Damamma Account credentials have been lost, stolen, misappropriated, or otherwise compromised or in case of any actual or suspected unauthorised use of your Damamma Account, please contact us.
            </p>
            <h1>
                8. Changes to This Privacy Policy
            </h1>
            <p>
                Damamma reserves the right to modify this Privacy Policy at any time in accordance with this provision. If we make changes to this Privacy Policy, we will post the revised Privacy Policy on the Damamma Platform and update the “Last Updated” date at the top of this Privacy Policy. We will also provide you with notice of the modification by email at least thirty (30) days before the date they become effective. If you disagree with the revised Privacy Policy, you may cancel your Account. If you do not cancel your Account before the date the revised Privacy Policy becomes effective, your continued access to or use of the Damamma Platform will be subject to the revised Privacy Policy.
            </p>
            <h1>
                9. Contact Us
            </h1>
            <p>
                If you have any questions or complaints about this Privacy Policy or Damamma information handling practices, you may email us or contact us:
            </p>
            <ul>
                <li>
                	Damamma Holdings Limited, Legal Department, 1 Bicester Road, Richmond Upon Thames, London, UK
                </li>
            </ul>
            <p>
                You also have the right to request the personal information of the D.P.O. so as to contact them directly.
            </p>
            {
                isMobile === true ?
                <Footer/>
                :
                <FooterDesktop/>
            }
        </div>
    )
}

export default PrivacyPolicy;