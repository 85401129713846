import React, { useEffect, useCallback } from 'react';
import { DefaultButton, colour, fontFamily } from './GlobalStyles';
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { width, height } from './actions';
import { isMobile } from 'react-device-detect';
 
  function Error404() {

    const dispatch = useDispatch();
    const stableDispatch = useCallback(dispatch, [])
    const currentWidth = useSelector(state => state.width);

    useEffect(() => {
        
      function resizeWindow () {
        const newWidth = window.innerWidth;
        const newHeight = window.innerHeight;
        stableDispatch(width(newWidth));
        stableDispatch(height(newHeight));
      };

        resizeWindow();
      window.addEventListener("resize", resizeWindow);
      return () => window.removeEventListener("resize", resizeWindow);
    }, [stableDispatch]);

    const currentHeight = useSelector(state => state.height);
    let history = useHistory();

    function openVideo() {
      const win = window.open("https://youtu.be/4LZo9ugJTWQ?t=33", '_blank');
      if (win != null) {
        win.focus();
      }
    }

    const styles = {
        centre: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: currentHeight * 0.1,
        },
        logo: {
          width: currentWidth * 0.1
        }
    }
    return (
        <div style={styles.centre}>
          <h1 style={{ textAlign: 'center', fontFamily: fontFamily, color: colour.dark, fontSize: isMobile === true ? '1.375em' : 70, fontWeight: 'bold' }}>
              You just hit a 404 my friend.
          </h1>
          <div style={{ height: currentHeight * 0.05 }} />
          <img
            src={require('./squidy.svg')}
            style={styles.logo}
            alt=""
            onClick={() => openVideo()}
          />
          <div style={{ height: currentHeight * 0.05 }} />
          <p style={{ textAlign: 'center', fontFamily: fontFamily, color: colour.med, fontSize: isMobile === true ? '1em' : 24, fontWeight: 'bold' }}>
            Whatcha doin in my waters
          </p>
          <div style={{ height: currentHeight * 0.1 }} />
          <DefaultButton
              onClick={() => history.push('../home')}
          >   
              Get me the f*ck outta here
          </DefaultButton>
       </div>
    )
  }

  export default Error404;