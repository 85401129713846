import React from 'react';
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { newQuestionText, anonymous } from './actions';
import Checkbox from '@material-ui/core/Checkbox';
import { QuidvidButton, fontFamily, colour, MultilineInput, DesktopQuidvidButton, DesktopMultilineInput } from './GlobalStyles';

function AskQuestionInput({ openQuestion }) {

    const currentWidth = useSelector(state => state.width);
    const currentHeight = useSelector(state => state.height);
    const authState = useSelector(state => state.auth);
    const newQuestion = useSelector(state => state.newQuestionText);
    const anonymousState = useSelector(state => state.anonymous);
    const disabled = useSelector(state => state.disabledCardButton);
    const sourceList = useSelector(state => state.sources);
    const currentCredits = useSelector(state => state.questionCredits);
    const dispatch = useDispatch();
    let history = useHistory();

    function next() {

        if ( newQuestion === "" ) {
            return null;
        }
        else if ( authState === false && openQuestion === false ) {
            history.push('./questionSignUp');
        }
        else if ( authState === false && openQuestion === true ) {
            history.push('./openQuestionSignUp');
        }
        else if ( openQuestion === true ) {
            history.push('./openQuestionPay');
        }
        else if ( sourceList.length === 0 && currentCredits === 0 ) {
            history.push('./questionAddPaymentMethod');
        }
        else if ( currentCredits === 0 && sourceList.length !== 0 ) {
            history.push('./confirmCard');
        }
        else {
            history.push('./questionCredits');
        }
    }

    const styles = {
        centre: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        },
        checkbox: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
        },
        characterLimit: {
            paddingLeft: isMobile === true ? currentWidth * 0.85 : currentWidth * 0.65
        },
    }

    return (
        <div>
            <div style={styles.centre}>
                {
                    isMobile === true ?
                    <MultilineInput
                        value={newQuestion}
                        id="outlined" 
                        label="Your question goes here"
                        placeholder=""
                        rows={isMobile === true ? "6" : "8"}
                        inputProps={{
                            autoCapitalize: 'none',
                            maxLength: 280,
                        }}
                        variant="outlined" 
                        onChange={(text) => dispatch(newQuestionText(text.target.value))}
                        style={styles.textField}
                        multiline
                    />
                    :
                    <DesktopMultilineInput
                        value={newQuestion}
                        id="outlined" 
                        label="Your question goes here"
                        placeholder=""
                        rows={isMobile === true ? "6" : "8"}
                        inputProps={{
                            autoCapitalize: 'none',
                            maxLength: 280,
                        }}
                        variant="outlined" 
                        onChange={(text) => dispatch(newQuestionText(text.target.value))}
                        style={styles.textField}
                        multiline
                    />
                }
            </div>
            <div style={{ height: currentHeight * 0.02 }} />
            <div style={styles.characterLimit}>
                <p style={{ fontSize: '0.75em', fontFamily: fontFamily, color: colour.med }}>
                    {
                        280 - newQuestion.length
                    }
                </p>
            </div>
            <div style={styles.checkbox}>
                <Checkbox
                    checked={anonymousState}
                    onChange={(state) => dispatch(anonymous(state.target.checked))}
                    name="checkedB"
                    label="Anonymous"
                    color="primary"
                />
                <p style={{ fontSize: '1.125em', fontFamily: fontFamily, color: colour.med }}>
                    Make it anonymous
                </p>
            </div>
            <div style={{ height: currentHeight * 0.05 }} />
            {
                isMobile === true ?
                <div style={styles.centre}>
                    <QuidvidButton
                        disabled={disabled}
                        onClick={() => next()}
                    >
                        Next
                    </QuidvidButton>
                </div>
                :
                <div style={styles.centre}>
                    <DesktopQuidvidButton
                        disabled={disabled}
                        onClick={() => next()}
                    >
                        Next
                    </DesktopQuidvidButton>
                </div>
            }
            <div style={{ height: currentHeight * 0.05 }} />
            {
                openQuestion === true ?
                null
                :
                <div style={styles.centre}>
                    <p
                        onClick={() => history.goBack()}
                        style={{ color: colour.med, textAlign: 'center', fontSize: '1.125em', fontFamily: fontFamily, fontWeight: 'bold', cursor: 'pointer' }}
                    >
                        nevermind
                    </p>
                </div>
            }
        </div>
    )
}

export default AskQuestionInput;
        