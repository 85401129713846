import width from './width';
import height from './height';
import auth from './auth';
import email from './email';
import password from './password';
import userId from './userId';
import stayLoggedIn from './stayLoggedIn';
import clientSecret from './clientSecret';
import stripeCustomerId from './stripeCustomerId';
import sources from './sources';
import loginEmail from './loginEmail';
import loginPassword from './loginPassword';
import userName  from './userName';
import newQuestionText from './newQuestionText';
import newVideoBlob from './newVideoBlob';
import videoUpload from './videoUpload';
import paymentCurrency from './paymentCurrency';
import searchField from './searchField';
import creatorList from './creatorList';
import searchFieldFilter from './searchFieldFilter';
import disabledCardButton from './disabledCardButton';
import quidIndex from './quidIndex';
import playVideo from './playVideo';
import feedData from './feedData';
import ownProfile from './ownProfile';
import profileUserId from './profileUserId';
import profileUserName from './profileUserName';
import questioner from './questioner';
import anonymous from './anonymous';
import accountNumber from './accountNumber';
import sortCode from './sortCode';
import bug from './bug';
import bugReports from './bugReports';
import secret from './secret';
import secretArray from './secretArray';
import secretAnonymous from './secretAnonymous';
import newQuestions from './newQuestions';
import totalEarnings from './totalEarnings';
import totalQuidvids from './totalQuidvids';
import userPicture from './userPicture';
import hate from './hate';
import hateArray from './hateArray';
import viewingProfileName from './viewingProfileName';
import viewingProfileUserPicture from './viewingProfileUserPicture';
import viewingProfileTotalQuidvids from './viewingProfileTotalQuidvids';
import userList from './userList';
import authenticationDisabledButton from './authenticationButtonDisabled';
import profileNewQuestions from './profileNewQuestions';
import askedQuestionsList from './askedQuestionsList';
import questionVideo from './questionVideo';
import questionVideoIndex from './questionVideoIndex';
import answeredQuestions from './answeredQuestions';
import askedQuestionIndex from './askedQuestionIndex';
import loadingIndex from './loadingIndex';
import userIndex from './userIndex';
import declineQuidModal from './declineQuidModal';
import transactions from './transactions';
import accountCreated from './accountCreated';
import activeBalance from './activeBalance';
import bottomFeed from './bottomFeed';
import usdAccountNumber from './usdAccountNumber';
import usdRoutingNumber from './usdRoutingNumber';
import completeName from './completeName';
import streetAddress from './streetAddress';
import zipCode from './zipCode';
import usState from './usState';
import usDetails from './usDetails';
import usAccountType from './usAccountType';
import watchCode from './watchCode';
import copyToClipboard from './copyToClipboard';
import questionCredits from './questionCredits';
import sharedQuestions from './sharedQuestions';
import sharedQuestionIndex from './sharedQuestionIndex';
import sharedQuestionURL from './sharedQuestionURL';
import questionShareActive from './questionShareActive';
import { combineReducers } from 'redux';

const allReducers = combineReducers({
    width: width,
    height: height,
    auth: auth,
    email: email,
    password: password,
    userId: userId,
    stayLoggedIn: stayLoggedIn,
    stripeCustomerId: stripeCustomerId,
    clientSecret: clientSecret,
    sources: sources,
    loginEmail: loginEmail,
    loginPassword: loginPassword,
    userName: userName,
    newQuestionText: newQuestionText,
    newVideoBlob: newVideoBlob,
    videoUpload: videoUpload,
    paymentCurrency: paymentCurrency,
    searchField: searchField,
    creatorList: creatorList,
    searchFieldFilter: searchFieldFilter,
    disabledCardButton: disabledCardButton,
    playVideo: playVideo,
    quidIndex: quidIndex,
    feedData: feedData,
    ownProfile: ownProfile,
    profileUserId: profileUserId,
    profileUserName: profileUserName,
    questioner: questioner,
    anonymous: anonymous,
    accountNumber: accountNumber,
    sortCode: sortCode,
    bug: bug,
    bugReports: bugReports,
    secret: secret,
    secretArray: secretArray,
    secretAnonymous: secretAnonymous,
    newQuestions: newQuestions,
    totalEarnings: totalEarnings,
    totalQuidvids: totalQuidvids,
    userPicture: userPicture,
    hate: hate,
    hateArray: hateArray,
    viewingProfileName: viewingProfileName,
    viewingProfileUserPicture: viewingProfileUserPicture,
    viewingProfileTotalQuidvids: viewingProfileTotalQuidvids,
    userList: userList,
    authenticationDisabledButton: authenticationDisabledButton,
    profileNewQuestions: profileNewQuestions,
    askedQuestionsList: askedQuestionsList,
    questionVideo: questionVideo,
    questionVideoIndex: questionVideoIndex,
    answeredQuestions: answeredQuestions,
    askedQuestionIndex: askedQuestionIndex,
    userIndex: userIndex,
    loadingIndex: loadingIndex,
    declineQuidModal: declineQuidModal,
    transactions: transactions,
    accountCreated: accountCreated,
    activeBalance: activeBalance,
    bottomFeed: bottomFeed,
    usdAccountNumber: usdAccountNumber,
    usdRoutingNumber: usdRoutingNumber,
    completeName: completeName,
    streetAddress: streetAddress,
    zipCode: zipCode,
    usState: usState,
    usDetails: usDetails,
    usAccountType: usAccountType,
    watchCode: watchCode,
    copyToClipboard: copyToClipboard,
    questionCredits: questionCredits,
    sharedQuestions: sharedQuestions,
    sharedQuestionIndex: sharedQuestionIndex,
    sharedQuestionURL: sharedQuestionURL,
    questionShareActive: questionShareActive
})

export default allReducers;