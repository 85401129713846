import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { searchField, searchFieldFilter, userList } from './actions';
import * as firebase from 'firebase/app';
import '@firebase/firestore';
import { Input, colour, fontFamily, DesktopSearchInput } from './GlobalStyles';
import { useHistory } from "react-router-dom";
import { isMobile } from 'react-device-detect';

function SearchComponent() {

    const currentWidth = useSelector(state => state.width);
    const currentHeight = useSelector(state => state.height);
    const searchFieldValue = useSelector(state => state.searchField);
    const searchFieldFilterValue = useSelector(state => state.searchFieldFilter);
    const users = useSelector(state => state.userList);
    const dispatch = useDispatch();
    let history = useHistory();

    useEffect(() => {

        firebase.firestore().collection("Users").doc("List")
        .onSnapshot(snapshot => {
            if (!snapshot.exists) {
                console.log("No such document!")
            }
            else {
                const data = snapshot.data();
                const userData = data.users;
                dispatch(userList(userData)); 
            }
        })
    })

    function search(text) {
        dispatch(searchField(text.target.value));
    
        if ( text.target.value === "" ) {
          dispatch(searchFieldFilter([]));
        }
        else {
          const search = searchFieldValue.toLowerCase();
          const list = users;
          const data = list.filter(
            (contact) => {
              return contact.userName.toLowerCase().indexOf(search) !== -1;
            }
          )
          dispatch(searchFieldFilter(data));
        }
      }
    
      function selectSearchResult(item, index) {
        dispatch(searchField(item.userName));
        dispatch(searchFieldFilter([]));
        history.push(`../profile/${item.userName}`)
        dispatch(searchField(""));
      }

    const styles = {
        centre: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        },
        desktopInput: {
            paddingLeft: currentWidth * 0.02
        },
        searchContainer: {
            height: currentHeight * 0.3,
            position: 'absolute',
            zIndex: 1,
            backgroundColor: '#FFFFFF'
        },
        container: {
            paddingLeft: isMobile === true ? currentWidth * 0.07 : 0,
            height: currentHeight * 0.07
        },
        searchResults: {
            cursor: 'pointer',
            width: isMobile === false ? currentWidth * 0.3 : currentWidth * 0.78,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
        },
        profileImage: {
            width: isMobile === true ? currentWidth * 0.05 : currentWidth * 0.02,
            height: isMobile === true ? currentWidth * 0.05 : currentWidth * 0.02,
            borderRadius: isMobile === true ? currentWidth * 0.025 : currentWidth * 0.01,
        }
    }

    return (
        <div>
            {
                isMobile ===  true ?
                <div style={styles.centre}>
                    <Input
                        value={searchFieldValue}
                        id="outlined"
                        label="Search for a profile..."
                        inputProps={{
                            autoCapitalize: 'none',
                        }}
                        variant="outlined" 
                        onChange={(text) => search(text)}
                        style={styles.textField}
                    />
                </div>
                :
                <div style={styles.desktopInput}>
                    <DesktopSearchInput
                        value={searchFieldValue}
                        id="outlined"
                        label="Search for a profile..."
                        inputProps={{
                            autoCapitalize: 'none',
                        }}
                        variant="outlined" 
                        onChange={(text) => search(text)}
                        style={styles.textField}
                    />
                </div>
            }
            <div style={styles.searchContainer}>
                {
                    searchFieldFilterValue.length === 0 ?
                        null
                    :
                    searchFieldFilterValue.map((item, index) =>
                        <div style={styles.container}>
                            <div 
                                style={styles.searchResults}
                                onClick={() => selectSearchResult(item, index)}
                            >
                                {
                                    item.photoURL === undefined ?
                                    <div style={styles.profileImage} />
                                    :
                                    <img
                                        src={item.photoURL}
                                        style={styles.profileImage}
                                        alt=""
                                    />
                                }
                                <div style={{ width: currentWidth * 0.01 }} />
                                <p style={{ fontFamily: fontFamily, fontSize: '1em', color: colour.med }}>
                                    {item.userName}
                                </p>
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default SearchComponent;