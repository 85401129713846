import React from 'react';
import Header from './Header';
import NavBar from './NavBar';
import NavBarDesktop from './NavBarDesktop';
import QuestionBanner from './QuestionBanner';
import { isMobile } from 'react-device-detect';
import { copyToClipboard } from './actions';
import { colour, fontFamily, DefaultButton, DesktopDefaultButton } from './GlobalStyles';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FacebookShareButton, FacebookIcon, WhatsappShareButton, WhatsappIcon, TwitterShareButton, TwitterIcon } from "react-share";

function OpenQuestionShare() {

    const currentWidth = useSelector(state => state.width);
    const currentHeight = useSelector(state => state.height);
    const newQuestion = useSelector(state => state.newQuestionText);
    const anonymousState = useSelector(state => state.anonymous);
    const questionerName = useSelector(state => state.questioner);
    const sharedQuestionURL = useSelector(state => state.sharedQuestionURL);
    const dispatch = useDispatch();
    const url = `https://www.quidvid.com/questionShare/${sharedQuestionURL}`;
    let history = useHistory();

    if ( newQuestion === "" ) {
        history.push('./openQuestion');
    }

    const styles = {
        centre: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        },
        text: {
            width: currentWidth * 0.5
        },
        row: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center'
        }
    }

    return (
        <div>
            <Header/>
            <div style={styles.centre}>
                <h1 style={{ color: colour.dark, fontFamily: fontFamily, fontSize: '1.375em', fontWeight: 'bold' }}>
                    Share Question Link
                </h1>
                <div style={{ height: currentHeight * 0.03 }} />
                <div style={styles.text}>
                    <p style={{ textAlign: 'center', fontFamily: fontFamily, color: colour.dark, fontSize: '1.125em' }}>
                        Send this link to whomever you would like and 
                        they will receive 10 credits for making an account.
                    </p>
                </div>
                <div style={{ height: currentHeight * 0.05 }} />
                <QuestionBanner
                    width={currentWidth}
                    height={currentHeight}
                    question={newQuestion}
                    anonymous={anonymousState}
                    questionerName={questionerName}
                    creatorUserName='openQuestion'
                />
                <div style={{ height: currentHeight * 0.1 }} />
                {
                    isMobile === true ?
                    <CopyToClipboard 
                        text={url}
                        onCopy={() => dispatch(copyToClipboard(true))}
                    >
                        <DefaultButton>
                            Copy Question Link
                        </DefaultButton>
                    </CopyToClipboard>
                    :
                    <CopyToClipboard 
                        text={url}
                        onCopy={() => dispatch(copyToClipboard(true))}
                    >
                        <DesktopDefaultButton>
                            Copy Question Link
                        </DesktopDefaultButton>
                    </CopyToClipboard>
                }
                <div>
                <div style={{ height: currentHeight * 0.02 }} />
                <p style={{ color: colour.med, fontSize: '1.125em', fontFamily: fontFamily, textAlign: 'center' }}>
                    Or share to
                </p>
                <div style={{ height: currentHeight * 0.02 }} />
                    <div style={styles.row}>
                        <FacebookShareButton 
                            url={url}
                            children="Share"
                        >
                            <FacebookIcon 
                                size={50}
                                round={true}
                            />
                        </FacebookShareButton>
                        <div style={{ width: currentWidth * 0.03 }} />
                        <WhatsappShareButton
                            url={url}
                            children="Share"
                        >
                        <WhatsappIcon
                            size={50}
                            round={true}
                        />
                        </WhatsappShareButton>
                        <div style={{ width: currentWidth * 0.03 }} />
                        <TwitterShareButton
                            url={url}
                            children="Share"
                        >
                        <TwitterIcon
                            size={50}
                            round={true}
                        />
                        </TwitterShareButton>
                    </div>
                </div>
            </div>
            {
                isMobile === true ?
                <NavBar/>
                :
                <NavBarDesktop/>
            }
        </div>
    )
}

export default OpenQuestionShare;