import React from 'react';
import { useSelector } from 'react-redux';
import { fontFamily, colour } from './GlobalStyles';
import { isMobile } from 'react-device-detect';

function QuestionBanner({ width, height, question, anonymous, questionerName, creatorUserName }) {

    const currentUserName = useSelector(state => state.userName);
    const authState = useSelector(state => state.auth);

    const styles = {
        centre: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        },
        outerContainer: {
            width: isMobile === true ? width * 0.9 : width * 0.4,
            border: `1px solid ${colour.primary}`,
            backgroundColor: colour.lightestGray
        },
        container: {
            height: height * 0.2,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        },
        questioner: {
            paddingLeft: isMobile === true ? width * 0.5 : width * 0.25
        }
    }

    return (
        <div style={styles.centre}>
            <div style={styles.outerContainer}>
                <div style={styles.container}>
                    {
                        creatorUserName === "openQuestionShared" ?
                        <p style={{ fontFamily: fontFamily, fontSize: '1.125em', color: colour.med, fontWeight: 'lighter', textAlign: 'center' }}>
                            You were asked
                        </p>
                        :
                        creatorUserName === 'openQuestion' ?
                        null
                        :
                        <p style={{ fontFamily: fontFamily, fontSize: '1.125em', color: colour.med, fontWeight: 'lighter' }}>
                            Your quidvid to{" "}
                            <span style={{ fontFamily: fontFamily, fontSize: '1.125em', color: colour.primaryMuted, fontWeight: 'bold' }}>
                                @{creatorUserName}
                            </span>
                        </p>
                    }
                    <div style={{ height: height * 0.02 }} />
                    <p style={{ color: colour.dark, fontWeight: 'bold', fontSize: '1.125em' }}>
                        {question}
                    </p>
                </div>
                <div style={styles.questioner}>
                    <p style={{ fontFamily: fontFamily, fontSize: '1.125em', color: colour.med, fontWeight: 'bold' }}>
                        -{" "}
                        {
                        anonymous === true ? "anonymous" : creatorUserName === "openQuestionShared" ? questionerName : authState === false ? currentUserName : questionerName
                        }
                    </p>
                </div>
            </div>
        </div>
    )
}

export default QuestionBanner;