import React, { useEffect, useCallback } from 'react';
import Header from './Header';
import { colour, ActionButton, fontFamily, DesktopActionButton } from './GlobalStyles';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import Footer from './Footer';
import { isMobile } from "react-device-detect";
import { width, height } from './actions';
import FooterDesktop from './FooterDesktop';

function AboutUs() {

    const currentWidth = useSelector(state => state.width);
    const currentHeight = useSelector(state => state.height);
    const history = useHistory();
    const dispatch = useDispatch();
    const stableDispatch = useCallback(dispatch, []);

    useEffect(() => {
        function resizeWindow () {
            const newWidth = window.innerWidth;
            const newHeight = window.innerHeight;
            stableDispatch(width(newWidth));
            stableDispatch(height(newHeight));
        };
        window.addEventListener("resize", resizeWindow);
        return () => window.removeEventListener("resize", resizeWindow);
      }, [stableDispatch]);

        //iPhone SE = 320 + 568
        //iPhone 6 = 375 + 667
        //iPhone 6 Plus = 414 + 736
        //iPhone 7 = 375 + 667
        //iPhone 7 Plus = 414 + 736
        //iPhone 8 = 375 + 667
        //iPhone 8 Plus = 414 + 736
        //iPhone X = 375 + 812
        //iPhone XMax = 414 + 896
        //Samsung Galaxy S7 Edge = 480 + 853.3

    const styles = {
        outerContainer: {
            overflowY: 'scroll',
            overflowX: 'hidden'
        },
        container: {
            background: colour.gradient,
            paddingTop: currentHeight * 0.02,
            height: currentHeight * 0.2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        },
        memojiContainer: {
            position: 'absolute',
            zIndex: 1,
            display: 'flex',
            flexDirection: 'row',
            top: currentHeight * 0.27,
            left: isMobile === true ? currentWidth * 0.17 : currentWidth * 0.38
        },
        memojiLiam: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        },
        memojiMaddy: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        },
        memoji: {
            height: currentHeight * 0.2,
        },
        centre: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
        },
        width: {
            width: isMobile === true ? currentWidth * 0.8 : currentWidth * 0.6
        }
    }

    return (
        <div style={styles.outerContainer}>
            <Header />
            <div style={styles.container}>
              <h1 style={{ color: colour.dark, fontFamily: fontFamily, fontWeight: 'bold', fontSize: '1.375em', textAlign: 'center' }}>
                  A Brit {"&"} Yank
              </h1>
            </div>
            <div style={styles.memojiContainer}>
                <div style={styles.memojiLiam}>
                    <img
                        src={require('./liamoji.png')}
                        style={styles.memoji}
                        alt="liamoji"
                    />
                    <p style={{ fontSize: '1.125em', fontWeight: 'bold', color: colour.med, fontFamily: fontFamily }}>
                        Liam
                    </p>
                </div>
                <div style={{ width: currentWidth * 0.05 }} />
                <div style={styles.memojiMaddy}>
                    <img
                        src={require('./maddymoji.png')}
                        style={styles.memoji}
                        alt="maddymoji"
                    />
                    <p style={{ fontSize: '1.125em', fontWeight: 'bold', color: colour.med, fontFamily: fontFamily }}>
                        Maddy
                    </p>
                </div>
            </div>
            <div style={{ height: currentHeight * 0.15 }} />
            <div style={styles.centre}>
                <div style={styles.width}>
                    <p style={{ fontSize: '1em', color: colour.med, fontFamily: fontFamily }}>
                        Once upon a time, a Brit and an American had 
                        to put aside their cultural differences to work 
                        together towards a greater cause{" "}
                        <strong>
                            — helping TikTok 
                            users make money.
                        </strong>
                    </p>
                    <br />
                    <p style={{ fontSize: '1em', color: colour.med, fontFamily: fontFamily }}>
                        We think it’s absolute b*llocks that TikTok users 
                        can have tons of followers and not even make a quid. 
                        One quid is a perfectly reasonable exchange for a video 
                        from your favourite internet personalities.
                    </p>
                    <br />
                    <p style={{ fontSize: '1em', color: colour.med, fontFamily: fontFamily }}>
                        So we decided to build a product that is both fun 
                        and profitable for its users… and better yet, one 
                        that feels like it was written by humans.
                    </p>
                    <br />
                    <p style={{ fontSize: '1em', color: colour.med, fontFamily: fontFamily }}>
                        So let’s ask, answer, and get to know each other better. 
                    </p>
                </div>
                <div style={{ height: currentHeight * 0.03 }} />
                <div style={styles.centre}>
                    {
                        isMobile === true ?
                        <ActionButton
                            onClick={() => history.push('../home')}
                        >
                            Get in on the action
                        </ActionButton>
                        :
                        <DesktopActionButton
                            onClick={() => history.push('../home')}
                        >
                            Get in on the action
                        </DesktopActionButton>
                    }
                </div>
                <div style={{ height: currentHeight * 0.03 }} />
                <div style={styles.centre}>
                    <p style={{ fontSize: '0.75em', color: colour.med, fontFamily: fontFamily }}>
                        (So our mums will stop asking us to get real jobs)
                    </p>
                </div>
                <div style={{ height: currentHeight * 0.03 }} />
                <div style={styles.centre}>
                    <h1 style={{ color: colour.dark, fontFamily: fontFamily, fontWeight: 'bold', fontSize: '1.375em', textAlign: 'center' }}>
                        Anything Else?
                    </h1>
                    <div style={{ height: currentHeight * 0.02 }} />
                    <h2 
                        onClick={() => history.push('./reportBug')}
                        style={{ fontWeight: 'bold', fontSize: '1.125em', fontFamily: fontFamily, color: colour.primaryMuted, cursor: 'pointer' }}
                    >
                        Get Help/Report a Bug
                    </h2>
                    <div style={{ height: currentHeight * 0.02 }} />
                    <div 
                        style={styles.title}
                        onClick={() => history.push('./secret')}
                    >
                        <h2 style={{ fontWeight: 'bold', fontSize: '1.125em', fontFamily: fontFamily, color: colour.accentMuted, cursor: 'pointer' }}>
                            Tell us a Secret
                        </h2>
                    </div>
                    <div style={{ height: currentHeight * 0.02 }} />
                    <h2 
                        onClick={() => history.push('./hate')}
                        style={{ fontWeight: 'bold', fontSize: '1.125em', fontFamily: fontFamily, color: colour.primaryMuted, cursor: 'pointer' }}
                    >
                        Proclaim Your Hate
                    </h2>
                </div>
            </div>
            {
                isMobile === true ?
                <Footer />
                :
                <FooterDesktop />
            }
        </div>
    )
}

export default AboutUs;