import React, { useEffect, useCallback } from 'react';
import { fontFamily, colour } from './GlobalStyles';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { isMobile } from 'react-device-detect';
import * as firebase from 'firebase/app';
import '@firebase/firestore';
import { width, height, auth, userId, userName, email, sources, accountNumber, sortCode, usdAccountNumber, usdRoutingNumber, usDetails } from './actions';
import Header from './Header';
import NavBar from './NavBar';
import NavBarDesktop from './NavBarDesktop';

function AccountInformation() {

    const currentWidth = useSelector(state => state.width);
    const currentHeight = useSelector(state => state.height);
    const history = useHistory();
    const currentUserName = useSelector(state => state.userName);
    const emailData = useSelector(state => state.email);
    const sourceList = useSelector(state => state.sources);
    const accountNumberData = useSelector(state => state.accountNumber);
    const sortCodeData = useSelector(state => state.sortCode);
    const currentUsDetails = useSelector(state => state.usDetails);
    const usdAccountNumberData = useSelector(state => state.usdAccountNumber);
    const usdRoutingNumberData = useSelector(state => state.usdRoutingNumber);

    const dispatch = useDispatch();
    const stableDispatch = useCallback(dispatch, []);

    useEffect(() => {

        firebase.auth().onAuthStateChanged( user => {
            if (user) {
                stableDispatch(auth(true));
                stableDispatch(userId(user.uid));
                stableDispatch(email(user.email));

                firebase.firestore().collection("Creator Profile").doc(user.uid)
                .onSnapshot(snapshot => {
                    if (!snapshot.exists) {
                        console.log('No such document!');
                    }
                    else {
                        const data = snapshot.data();

                        if ( data.userName !== undefined ) {
                            const currentUserName = data.userName;
                            stableDispatch(userName(currentUserName));
                        }
                        else {
                            stableDispatch(userName(""));
                        }
                        if ( data.accountNumber !== undefined ) {
                            const accountNumberData = data.accountNumber;
                            stableDispatch(accountNumber(accountNumberData));
                        }
                        else {
                            stableDispatch(accountNumber(""));
                        }
                        if ( data.sortCode !== undefined ) {
                            const sortCodeData = data.sortCode;
                            stableDispatch(sortCode(sortCodeData));
                        }
                        else {
                            stableDispatch(sortCode(""));
                        }
                        if ( data.usDetails !== undefined ) {
                            const dbUsDetails = data.usDetails;
                            stableDispatch(usDetails(dbUsDetails));
                        }
                        if ( data.usdAccountNumber !== undefined ) {
                            const dbUsdAccountNumber = data.usdAccountNumber;
                            stableDispatch(usdAccountNumber(dbUsdAccountNumber));
                        }
                        else {
                            stableDispatch(usdAccountNumber(""));
                        }
                        if ( data.usdRoutingNumber !== undefined ) {
                            const dbUsdRoutingNumber = data.usdRoutingNumber;
                            stableDispatch(usdRoutingNumber(dbUsdRoutingNumber));
                        }
                        else {
                            stableDispatch(usdAccountNumber(""));
                        }
                    }
                })

                firebase.firestore().collection("Stripe_Customers").doc(user.uid)
                .onSnapshot(snapshot => {
                    if (!snapshot.exists) {
                        console.log("No such document!")
                    }
                    else {
                        const data = snapshot.data();
                        stableDispatch(sources(data.sources));
                    }
                })
            }
            else {
                stableDispatch(auth(false));
                stableDispatch(userId(""));
                history.push('../home');
            }
          })

    function resizeWindow () {
        const newWidth = window.innerWidth;
        const newHeight = window.innerHeight;
        stableDispatch(width(newWidth));
        stableDispatch(height(newHeight));
    };
    window.addEventListener("resize", resizeWindow);
    return () => window.removeEventListener("resize", resizeWindow);
  }, [stableDispatch, history]);

    const styles = {
        centre: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        },
        container: {
            width: currentWidth * 0.8
        },
        userData: {
            paddingLeft: currentWidth * 0.15,
            display: 'flex',
            flexDirection: 'column'
        },
        data: {
            paddingLeft: currentWidth * 0.15,
            display: 'flex',
            flexDirection: 'row',
            flexWrap: isMobile === true ? 'wrap' : 'no-wrap'
        },
        itemContainer: {
            width: isMobile === true ? currentWidth * 0.3 : currentWidth * 0.15
        },
        response: {
            width: isMobile === true ? currentWidth * 0.7 : currentWidth * 0.85
        },
        title: {
            paddingLeft: currentWidth * 0.1
        },
        navbar: {
            position: 'fixed', 
            zIndex: 1,
            bottom: 0, 
            left: 0,
            opacity: 1
        },
    }
    
    return (
        <div>
            <Header />
            <div style={styles.centre}>
                <h1 style={{ fontWeight: 'bold', fontSize: '1.375em', fontFamily: fontFamily, color: colour.dark, textAlign: 'center' }}>
                    My Info
                </h1>
            </div>
            <div style={styles.container}>
                <div style={styles.title}>
                    <h2 style={{ fontWeight: 'bold', fontSize: '0.875em', fontFamily: fontFamily, color: colour.med }}>
                        Info
                    </h2>
                </div>
                <div style={isMobile === true ? styles.userData : styles.data}>
                    <div style={styles.itemContainer}>
                        <p style={{ fontFamily: fontFamily, fontSize: '1.125em', color: colour.dark }}>
                            username
                        </p>
                    </div>
                    <div>
                        <p style={{ fontFamily: fontFamily, fontSize: '1.125em', color: colour.med, fontWeight: 'bold' }}>
                            {currentUserName}
                        </p>
                    </div>
                </div>
                <div style={{ height: currentHeight * 0.02 }} />
                <div style={isMobile === true ? styles.userData : styles.data}>
                    <div style={styles.itemContainer}>
                        <p style={{ fontFamily: fontFamily, fontSize: '1.125em', color: colour.dark }}>
                            email
                        </p>
                    </div>
                    <div>
                        <p style={{ fontFamily: fontFamily, fontSize: '1.125em', color: colour.med, fontWeight: 'bolder' }}>
                            {emailData}
                        </p>
                    </div>
                </div>
                <div style={{ height: currentHeight * 0.05 }} />
                <div style={styles.title}>
                    <h2 style={{ fontWeight: 'bold', fontSize: '0.875em', fontFamily: fontFamily, color: colour.med }}>
                        Receiving Payments
                    </h2>
                </div>
                <div style={{ height: currentHeight * 0.02 }} />
                <div>
                    {
                        currentUsDetails === true ?
                        <div>
                             <div style={styles.data}>
                                <div style={styles.itemContainer}>
                                    <p style={{ fontFamily: fontFamily, fontSize: '1.125em', color: colour.dark }}>
                                        account number
                                    </p>
                                </div>
                                <div>
                                    <p style={{ fontFamily: fontFamily, fontSize: '1.125em', color: colour.med, fontWeight: 'bolder' }}>
                                        {usdAccountNumberData}
                                    </p>
                                </div>
                            </div>
                            <div style={{ height: currentHeight * 0.02 }} />
                            <div style={styles.data}>
                                <div style={styles.itemContainer}>
                                <p style={{ fontFamily: fontFamily, fontSize: '1.125em', color: colour.dark }}>
                                    routing number
                                </p>
                                </div>
                                <div>
                                    <p style={{ fontFamily: fontFamily, fontSize: '1.125em', color: colour.med, fontWeight: 'bolder' }}>
                                        {usdRoutingNumberData}
                                    </p>
                                </div>
                            </div>
                        </div>
                        :
                        accountNumberData === "" ?
                        <div style={styles.data}>
                            <p style={{ fontWeight: '500', fontSize: '0.75em', color: colour.med, fontFamily: fontFamily }}>
                                Oops, in order to receive bank transfers, you must add your account
                                details
                            </p>
                        </div>
                        :
                        <div>
                            <div style={styles.data}>
                                <div style={styles.itemContainer}>
                                    <p style={{ fontFamily: fontFamily, fontSize: '1.125em', color: colour.dark }}>
                                        account number
                                    </p>
                                </div>
                                <div>
                                    <p style={{ fontFamily: fontFamily, fontSize: '1.125em', color: colour.med, fontWeight: 'bolder' }}>
                                        {accountNumberData}
                                    </p>
                                </div>
                            </div>
                            <div style={{ height: currentHeight * 0.02 }} />
                            <div style={styles.data}>
                                <div style={styles.itemContainer}>
                                <p style={{ fontFamily: fontFamily, fontSize: '1.125em', color: colour.dark }}>
                                    sort code
                                </p>
                                </div>
                                <div>
                                    <p style={{ fontFamily: fontFamily, fontSize: '1.125em', color: colour.med, fontWeight: 'bolder' }}>
                                        {sortCodeData}
                                    </p>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
            <div style={{ height: currentHeight * 0.02 }} />
            <div style={styles.centre}>
                <h3 
                    onClick={() => history.push('./bankAccountInfo')}
                    style={{ fontSize: '1.125em', color: colour.primaryMuted, textAlign: 'center', fontFamily: fontFamily, fontWeight: 'bold', cursor: 'pointer' }}
                >
                    {
                        accountNumberData === "" ?  "Add " : "Modify "
                    } 
                    Bank Account Details
                </h3>
            </div>
            <div style={{ height: currentHeight * 0.05 }} />
            <div style={styles.container}>
                <div style={styles.title}>
                    <h2 style={{ fontWeight: 'bold', fontSize: '0.875em', fontFamily: fontFamily, color: colour.med }}>
                        Making Payments
                    </h2>
                </div>
                <div style={{ height: currentHeight * 0.02 }} />
                {
                    sourceList.length === 0 ?
                    <div style={styles.data}>
                        <p style={{ fontWeight: '500', fontSize: '0.75em', color: colour.med, fontFamily: fontFamily }}>
                            No saved cards
                        </p>
                    </div>
                    :
                    sourceList.map((item, index) =>
                    <div style={styles.data}>
                        <div style={styles.itemContainer}>
                            <p style={{ fontFamily: fontFamily, fontSize: '1.125em', color: colour.dark }}>
                                saved card
                            </p>
                        </div>
                        <div>
                            <p style={{ fontFamily: fontFamily, fontSize: '1.125em', color: colour.med, fontWeight: 'bolder' }}>
                                {item.brand}
                                {"   "}
                                {item.last4}
                            </p>
                        </div>
                    </div>
                    )
                }
            </div>
            <div style={{ height: currentHeight * 0.02 }} />
            <div style={styles.centre}>
                <h3 
                    style={{ fontSize: '1.125em', color: colour.primaryMuted, textAlign: 'center', fontFamily: fontFamily, fontWeight: 'bold', cursor: 'pointer' }}
                    onClick={() => history.push('../addPaymentMethod')}
                >
                    {
                        sourceList.length === 0 ?  "Add " : "Modify "
                    } 
                    Credit Card
                </h3>
            </div>
            <div style={{ height: currentHeight * 0.15 }} />
            {
                isMobile === true ?
                <div style={styles.navbar}>
                    <NavBar/>
                </div>
                :
                <NavBarDesktop />
            }
        </div>
    )
}

export default AccountInformation;