import React, { useEffect, useCallback } from 'react';
import * as firebase from 'firebase/app';
import '@firebase/firestore';
import { width, height, userName, auth, userId } from './actions';
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { isMobile } from "react-device-detect";
import { colour, HeaderButton, fontFamily } from './GlobalStyles';

function Header() {

    const currentWidth = useSelector(state => state.width);
    const currentHeight = useSelector(state => state.height);
    const authState = useSelector(state => state.auth);
    let history = useHistory();
    const dispatch = useDispatch();
    const stableDispatch = useCallback(dispatch, []);

    useEffect(() => {
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                stableDispatch(auth(true));
                stableDispatch(userId(user.uid));
            }
            else {
                stableDispatch(auth(false));
                stableDispatch(userId(""));
                stableDispatch(userName(""));
            }
        })

        function resizeWindow () {
            const newWidth = window.innerWidth;
            const newHeight = window.innerHeight;
            stableDispatch(width(newWidth));
            stableDispatch(height(newHeight));
          };
          window.addEventListener("resize", resizeWindow);
          return () => window.removeEventListener("resize", resizeWindow);
      }, [stableDispatch]);

    const path = window.location.pathname;

    const styles = {
        centre: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: currentHeight * 0.2,
            width: currentWidth,
            backgroundColor: path !== '/' ? '#FFFFFF' : colour.dark
        },
        container: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: currentWidth * 0.9
        },
        logo: {
            width: isMobile === true ? currentWidth * 0.3 : currentWidth * 0.1,
            cursor: 'pointer'
        }
    }
   
    return (
        <div style={styles.centre}>
            <div style={styles.container}>
                <img
                    src={path === '/' ? require('./logo-green.svg') : require('./logo.svg')}
                    style={styles.logo}
                    alt="logo"
                    onClick={() => history.push('../')}
                />
                {
                    authState === true ?
                    <p 
                        onClick={() => history.push('../account')}
                        style={{ cursor: 'pointer', fontSize: isMobile === true ? '0.875em' : 24, color: path === '/' ? colour.primaryMuted : colour.med, fontFamily: fontFamily, fontWeight: 'bold' }}
                    >
                        Account
                    </p>
                    :
                    authState === false ?
                    <HeaderButton
                        onClick={() => history.push('../login')}
                    >
                        Login
                    </HeaderButton>
                    :
                    null
                }
            </div>
        </div>
    )
}

export default Header;