import Button from '@material-ui/core/Button';
import { styled, withStyles } from '@material-ui/core/styles';
import { isMobile } from "react-device-detect";
import TextField from '@material-ui/core/TextField';

export const fontFamily = '"avenir", Helvetica, Sans-Serif'

export const colour = {
    primary: '#D4FC30',
    primaryMuted: '#CECE00',
    gradient: 'linear-gradient(180deg, #D4FC30 30%, #CECE00 90%)',
    dark: '#282929',
    lightestGray: "#F8F8F8",
    med: '#A2A39A',
    light: '#FCFCFC',
    accentMuted: "#87C90C",
    darkGradient: 'linear-gradient(180deg, #282929 30%, #000000 90%)',
}

export const HeaderButton = styled(Button)({
    background: `linear-gradient(180deg, ${colour.primary} 30%, ${colour.primaryMuted} 90%)`,
    border: 0,
    color: colour.light,
    fontWeight: 'bold',
    fontFamily: fontFamily,
    fontSize: '1.125em',
    borderRadius: '30px',
    width: isMobile === false ? window.innerWidth * 0.15 : window.innerWidth * 0.3,
    textTransform: 'none',
    '&:hover': {
      color: colour.dark
   },
  });

export const ActionButton = styled(Button)({
    background: `linear-gradient(180deg, ${colour.primaryMuted} 30%, ${colour.primary} 90%)`,
    border: 0,
    color: colour.dark,
    fontWeight: 'bold',
    fontFamily: fontFamily,
    borderRadius: '30px',
    fontSize: '1.125em',
    width: window.innerWidth * 0.8,
    textTransform: 'none',
    '&:hover': {
      background: colour.primary
   },
  });

  export const DesktopActionButton = styled(Button)({
    background: `linear-gradient(180deg, ${colour.primaryMuted} 30%, ${colour.primary} 90%)`,
    border: 0,
    color: colour.dark,
    fontWeight: 'bold',
    fontFamily: fontFamily,
    borderRadius: '30px',
    fontSize: '1.125em',
    width: window.innerWidth * 0.3,
    textTransform: 'none',
    '&:hover': {
      background: colour.primary
   },
  });

  export const ActionButtonShort = styled(Button)({
    background: `linear-gradient(180deg, ${colour.primaryMuted} 30%, ${colour.primary} 90%)`,
    border: 0,
    color: colour.light,
    fontWeight: 'bold',
    fontFamily: fontFamily,
    borderRadius: '30px',
    fontSize: '1.375em',
    width: window.innerWidth * 0.6,
    textTransform: 'none',
    '&:hover': {
      background: colour.primary
   },
  });

  export const LoginButton = styled(Button)({
    background: `linear-gradient(180deg, ${colour.primary} 30%, ${colour.primaryMuted} 90%)`,
    border: 0,
    color: colour.light,
    fontWeight: 'bold',
    fontFamily: fontFamily,
    fontSize: '1.125em',
    borderRadius: '30px',
    width: window.innerWidth * 0.9,
    textTransform: 'none',
    '&:hover': {
      color: colour.dark
   },
  });

  export const DesktopLoginButton = styled(Button)({
    background: `linear-gradient(180deg, ${colour.primary} 30%, ${colour.primaryMuted} 90%)`,
    border: 0,
    color: colour.light,
    fontWeight: 'bold',
    fontFamily: fontFamily,
    fontSize: '1.125em',
    borderRadius: '30px',
    width: window.innerWidth * 0.4,
    textTransform: 'none',
    '&:hover': {
      color: colour.dark
   },
  });

  export const QuidvidButton = styled(Button)({
    background: colour.primary,
    border: 0,
    color: colour.dark,
    fontWeight: 'bold',
    fontFamily: fontFamily,
    fontSize: '1.125em',
    borderRadius: '30px',
    width: window.innerWidth * 0.9,
    textTransform: 'none',
    '&:hover': {
      background: `linear-gradient(180deg, ${colour.primary} 30%, ${colour.primaryMuted} 90%)`
   },
  });

  export const DesktopQuidvidButton = styled(Button)({
    background: colour.primary,
    border: 0,
    color: colour.dark,
    fontWeight: 'bold',
    fontFamily: fontFamily,
    fontSize: '1.125em',
    borderRadius: '30px',
    width: window.innerWidth * 0.4,
    textTransform: 'none',
    '&:hover': {
      background: `linear-gradient(180deg, ${colour.primary} 30%, ${colour.primaryMuted} 90%)`
   },
  });

  export const ShortDesktopQuidvidButton = styled(Button)({
    background: colour.primary,
    border: 0,
    color: colour.dark,
    fontWeight: 'bold',
    fontFamily: fontFamily,
    fontSize: '1.125em',
    borderRadius: '30px',
    width: window.innerWidth * 0.3,
    textTransform: 'none',
    '&:hover': {
      background: `linear-gradient(180deg, ${colour.primary} 30%, ${colour.primaryMuted} 90%)`
   },
  });

  export const DefaultButton = styled(Button)({
    background: colour.primaryMuted,
    border: 0,
    color: colour.light,
    fontWeight: 'bold',
    fontFamily: fontFamily,
    fontSize: '1.125em',
    borderRadius: '30px',
    width: window.innerWidth * 0.9,
    textTransform: 'none',
    '&:hover': {
      background: colour.primary,
      color: colour.dark
   },
  });

  export const SmallDefaultButton = styled(Button)({
    background: colour.primaryMuted,
    border: 0,
    color: colour.light,
    fontWeight: 'normal',
    fontFamily: fontFamily,
    fontSize: '1.125em',
    borderRadius: '30px',
    width: window.innerWidth * 0.4,
    textTransform: 'none',
    '&:hover': {
      background: colour.primaryMuted,
      color: colour.light
   },
  });

  export const UnselectedDefaultButton = styled(Button)({
    background: colour.light,
    border: `1px solid ${colour.primaryMuted}`,
    color: colour.primaryMuted,
    fontWeight: 'normal',
    fontFamily: fontFamily,
    fontSize: '1.125em',
    borderRadius: '30px',
    width: window.innerWidth * 0.4,
    textTransform: 'none',
    '&:hover': {
      background: colour.primaryMuted,
      color: colour.light
   },
  });

  export const DesktopSmallDefaultButton = styled(Button)({
    background: colour.primaryMuted,
    border: 0,
    color: colour.light,
    fontWeight: 'normal',
    fontFamily: fontFamily,
    fontSize: '1.125em',
    borderRadius: '30px',
    width: window.innerWidth * 0.2,
    textTransform: 'none',
    '&:hover': {
      background: colour.primaryMuted,
      color: colour.light
   },
  });

  export const DesktopUnselectedDefaultButton = styled(Button)({
    background: colour.light,
    border: `1px solid ${colour.primaryMuted}`,
    color: colour.primaryMuted,
    fontWeight: 'normal',
    fontFamily: fontFamily,
    fontSize: '1.125em',
    borderRadius: '30px',
    width: window.innerWidth * 0.2,
    textTransform: 'none',
    '&:hover': {
      background: colour.primaryMuted,
      color: colour.light
   },
  });


  export const DesktopDefaultButton = styled(Button)({
    background: colour.primaryMuted,
    border: 0,
    color: colour.light,
    fontWeight: 'bold',
    fontFamily: fontFamily,
    fontSize: '1.125em',
    borderRadius: '30px',
    width: window.innerWidth * 0.4,
    textTransform: 'none',
    '&:hover': {
      background: colour.primary,
      color: colour.dark
   },
  });

  export const Input = withStyles({
    root: {
      '& label': {
        color: colour.med,
        fontFamily: fontFamily
      },
      '& label.Mui-focused': {
        color: colour.med
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: colour.med,
      },
      '& .MuiOutlinedInput-root': {
        fontFamily: fontFamily,
        color: colour.dark,
        backgroundColor: '#F8F8F8',
        borderRadius: '30px',
        '& fieldset': {
          borderRadius: '30px',
          borderWidth: 0
        },
        '&:hover fieldset': {
          borderColor: colour.med,
        },
        '&.Mui-focused fieldset': {
          borderColor: colour.primary
        },
      },
      width: window.innerWidth * 0.9
    },
  })(TextField);

  export const DesktopInput = withStyles({
    root: {
      '& label': {
        color: colour.med,
        fontFamily: fontFamily
      },
      '& label.Mui-focused': {
        color: colour.med
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: colour.med,
      },
      '& .MuiOutlinedInput-root': {
        fontFamily: fontFamily,
        color: colour.dark,
        backgroundColor: '#F8F8F8',
        borderRadius: '30px',
        '& fieldset': {
          borderRadius: '30px',
          borderWidth: 0
        },
        '&:hover fieldset': {
          borderColor: colour.med,
        },
        '&.Mui-focused fieldset': {
          borderColor: colour.primary
        },
      },
      width: window.innerWidth * 0.4
    },
  })(TextField);

  export const DesktopSearchInput = withStyles({
    root: {
      '& label': {
        color: colour.med,
        fontFamily: fontFamily
      },
      '& label.Mui-focused': {
        color: colour.med
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: colour.med,
      },
      '& .MuiOutlinedInput-root': {
        fontFamily: fontFamily,
        color: colour.dark,
        backgroundColor: '#F8F8F8',
        borderRadius: '30px',
        '& fieldset': {
          borderRadius: '30px',
          borderWidth: 0
        },
        '&:hover fieldset': {
          borderColor: colour.med,
        },
        '&.Mui-focused fieldset': {
          borderColor: colour.primary
        },
      },
      width: window.innerWidth * 0.25
    },
  })(TextField);

  export const MultilineInput = withStyles({
    root: {
      '& label': {
        color: colour.med,
        fontFamily: fontFamily
      },
      '& label.Mui-focused': {
        color: colour.med
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: colour.med,
      },
      '& .MuiOutlinedInput-root': {
        fontFamily: fontFamily,
        color: colour.dark,
        backgroundColor: '#F8F8F8',
        borderRadius: '30px',
        height: window.innerHeight * 0.3,
        '& fieldset': {
          borderRadius: '30px',
          borderWidth: 0,
          height: window.innerHeight * 0.3
        },
        '&:hover fieldset': {
          borderColor: colour.med,
        },
        '&.Mui-focused fieldset': {
          borderColor: colour.primary
        },
      },
      width: window.innerWidth * 0.9
    },
  })(TextField);

  export const DesktopMultilineInput = withStyles({
    root: {
      '& label': {
        color: colour.med,
        fontFamily: fontFamily
      },
      '& label.Mui-focused': {
        color: colour.med
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: colour.med,
      },
      '& .MuiOutlinedInput-root': {
        fontFamily: fontFamily,
        color: colour.dark,
        backgroundColor: '#F8F8F8',
        borderRadius: '30px',
        height: window.innerHeight * 0.3,
        '& fieldset': {
          borderRadius: '30px',
          borderWidth: 0,
          height: window.innerHeight * 0.3
        },
        '&:hover fieldset': {
          borderColor: colour.med,
        },
        '&.Mui-focused fieldset': {
          borderColor: colour.primary
        },
      },
      width: window.innerWidth * 0.4
    },
  })(TextField);
