import React from 'react';
import { colour, fontFamily } from './GlobalStyles';
import { useHistory } from "react-router-dom";
import './Footer.css';

function Footer() {

    let history = useHistory();

    return (
        <nav className="footer">
            <p 
                onClick={() => history.push('./privacy')}
                style={{ cursor: 'pointer', textDecoration: 'underline', color: colour.med, fontFamily: fontFamily, fontSize: '0.75em' }}
            >
                Privacy Policy
            </p>
        
            <p onClick={() => history.push('./terms')}
                style={{ cursor: 'pointer', textDecoration: 'underline', color: colour.med, fontFamily: fontFamily, fontSize: '0.75em' }}
            >
                Terms {"&"} Conditions
            </p>
        
            <p 
                onClick={() => history.push('./aboutUs')}
                style={{ cursor: 'pointer', textDecoration: 'underline', color: colour.primaryMuted, fontFamily: fontFamily, fontSize: '0.75em', fontWeight: 'bold' }}
            >
                About Us
            </p>
        
            <p 
                onClick={() => history.push('./ourAnswersToYourPerfectlyReasonableQuestions')}
                style={{ cursor: 'pointer', textDecoration: 'underline', color: colour.dark, fontFamily: fontFamily, fontSize: '0.75em', fontWeight: 'bold' }}
            >
                FAQ
            </p>
        </nav>
    )
}

export default Footer;