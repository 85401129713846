import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as firebase from 'firebase/app';
import '@firebase/firestore';
import { useHistory } from 'react-router-dom';
import { sharedQuestions, sharedQuestionIndex, questionShareActive } from './actions';
import Header from './Header';
import Footer from './Footer';
import QuestionBanner from './QuestionBanner';
import FooterDesktop from './FooterDesktop';
import { isMobile } from 'react-device-detect';
import { fontFamily, colour, ActionButtonShort } from './GlobalStyles';
import VideocamIcon from '@material-ui/icons/Videocam';

function QuestionShare({ match }) {

    const shareLink = match.params.shareLink;
    const currentWidth = useSelector(state => state.width);
    const currentHeight = useSelector(state => state.height);
    const currentSharedQuestions = useSelector(state => state.sharedQuestions);
    const currentSharedQuestionIndex = useSelector(state => state.sharedQuestionIndex);
    const dispatch = useDispatch();
    const stableDispatch = useCallback(dispatch, []);
    let history = useHistory();

    useEffect(() => {

        const sharedQuestionQuery = firebase.firestore().collection("Shared Questions").doc("Data");
        sharedQuestionQuery.get()
        .then(doc => {
            if(!doc.exists) {
                console.log('No such document!');
                history.push('../error');
            }
            else {
                const data = doc.data();
                const questions = data.sharedQuestions;
                const questionIndex = questions.map((e) => { return e.id; }).indexOf(shareLink);

                if ( questionIndex === -1 ) {
                    history.push('../error');
                }
                else {
                    stableDispatch(sharedQuestions(questions));
                    stableDispatch(sharedQuestionIndex(questionIndex));
                }
            }
        })
        .catch(err => {
            console.log("error =", err);
        })
    }, [stableDispatch, shareLink, history])

    function acceptQuid() {
        dispatch(questionShareActive(true));
        history.push('../openQuestionRecordAnswer');
    }

    const styles = {
        centre: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        },
        text: {
            paddingLeft: isMobile === true ? currentWidth * 0.1 : currentWidth * 0.3
        }
    }
    
    return (
        <div>
            <Header/>
            {
                currentSharedQuestions.length === 0 ?
                null
                :
                <div>
                    <div style={styles.text}>
                        <h1 style={{ color: colour.dark, fontWeight: 'bold', fontSize: '1.375em', fontFamily: fontFamily }}>
                            Damn, someone is Popular...
                        </h1>
                    </div>
                    <div style={{ height: currentHeight * 0.1 }} />
                    <div style={styles.centre}>
                        <QuestionBanner
                            width={currentWidth}
                            height={currentHeight}
                            question={currentSharedQuestions[currentSharedQuestionIndex].question}
                            anonymous={currentSharedQuestions[currentSharedQuestionIndex].userName === 'anonymous' ? true : false}
                            questionerName={currentSharedQuestions[currentSharedQuestionIndex].userName}
                            creatorUserName="openQuestionShared"
                        />
                    </div>
                    <div style={{ height: currentHeight * 0.1 }} />
                    <div style={styles.centre}>
                        {
                            isMobile === false ?
                            <p style={{ fontSize: 24, color: colour.med, fontFamily: fontFamily, fontWeight: 'lighter', textAlign: 'center' }}>
                                You must be on mobile to accept or decline quidvids
                            </p>
                            :
                            currentSharedQuestions[currentSharedQuestionIndex].credit === true ?
                            <ActionButtonShort
                                onClick={() => acceptQuid()}
                            >
                                Free
                                <div style={{ width: currentWidth * 0.02 }} />
                                <VideocamIcon
                                    style={{ color: '#FFFFFF', fontSize: 40 }}
                                />
                            </ActionButtonShort>
                            :
                            <ActionButtonShort
                                onClick={() => acceptQuid()}
                            >
                                +£1 for
                                <div style={{ width: currentWidth * 0.02 }} />
                                <VideocamIcon
                                    style={{ color: '#FFFFFF', fontSize: 40 }}
                                />
                            </ActionButtonShort>
                        }
                    </div>
                </div>
            }
            {
                isMobile === true ?
                <Footer/>
                :
                <FooterDesktop/>
            }
        </div>
    )
}

export default QuestionShare;