import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Pay from './Pay';
import SignUp from './SignUp';
import Login from './Login';
import PrivacyPolicy from './PrivacyPolicy';
import TermsAndConditions from './Terms&Conditions';
import ShouldAddPayment from './ShouldAddPayment';
import AddPaymentMethod from './AddPaymentMethod';
import Home from './Home';
import Profile from './Profile';
import Error404 from './Error404';
import Question from './Question';
import QuestionSignUp from './QuestionSignUp';
import QuestionLogin from './QuestionLogin';
import QuestionAddPaymentMethod from './QuestionAddPaymentMethod';
import QuestionConfirmed from './QuestionConfirmed';
import ConfirmCard from './ConfirmCard';
import Account from './Account';
import BankAccountInfo from './BankAccountInfo';
import ReportBug from './ReportBug';
import Secret from './Secret';
import AboutUs from './AboutUs';
import Hate from './Hate';
import FAQ from './FAQ';
import RecordAnswer from './RecordAnswer';
import AccountInformation from './AccountInformation';
import UploadProfilePic from './UploadProfilePic';
import Transactions from './Transactions';
// import Testing from './Testing';
import UsdAccount from './UsdAccount';
import Watch from './Watch';
import QuestionCredit from './QuestionCredit';
import QuestionShare from './QuestionShare';
import OpenQuestion from './OpenQuestion';
import OpenQuestionPay from './OpenQuestionPay';
import OpenQuestionShare from './OpenQuestionShare';
import OpenQuestionSignUp from './OpenQuestionSignUp';
import OpenQuestionLogin from './OpenQuestionLogin';
import OpenQuestionRecordAnswer from './OpenQuestionRecordAnswer';
import RecordSignUp from './RecordSignUp';
import * as firebase from 'firebase';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { createStore } from 'redux';
import allReducer from './reducers';
import { Provider } from 'react-redux';
import ScrollToTop from './ScrollToTop';

const store = createStore(
  allReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)

const firebaseConfig = {
                        apiKey: "AIzaSyD2kv0RJMho7WEy8qCR4JHkRBqnolXfyB0",
                        authDomain: "downstairs-mixup.firebaseapp.com",
                        databaseURL: "https://downstairs-mixup.firebaseio.com",
                        projectId: "downstairs-mixup",
                        storageBucket: "downstairs-mixup.appspot.com",
                        messagingSenderId: "516984821087",
                        appId: "1:516984821087:web:92c49659498cc154c752ea",
                        measurementId: "G-VELQ3LBLBR"
                      };
  firebase.initializeApp(firebaseConfig);
  firebase.analytics();

  const routing = (
    <Provider
      store={store}
    >
      <Router>
        <ScrollToTop />
        <Switch>
          <Route exact path="/" component={App} />
          <Route exact path="/pay" component={Pay} />
          <Route exact path="/signUp" component={SignUp} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/shouldAddPayment" component={ShouldAddPayment} />
          <Route exact path="/addPaymentMethod" component={AddPaymentMethod} />
          <Route exact path="/home" component={Home} />
          <Route 
            path="/profile/:user"
            render={({ match }) => <Profile match={match} />}
          />
          <Route exact path="/question" component={Question} />
          <Route exact path="/questionSignUp" component={QuestionSignUp} />
          <Route exact path="/questionLogin" component={QuestionLogin} />
          <Route exact path="/questionAddPaymentMethod" component={QuestionAddPaymentMethod} />
          <Route exact path="/questionConfirmed" component={QuestionConfirmed} />
          <Route exact path="/confirmCard" component={ConfirmCard} />
          <Route exact path='/account' component={Account} />
          <Route exact path="/bankAccountInfo" component={BankAccountInfo} />
          <Route exact path="/reportBug" component={ReportBug} />
          <Route exact path="/secret" component={Secret} />
          <Route exact path="/aboutUs" component={AboutUs} />
          <Route exact path="/hate" component={Hate} />
          <Route exact path="/upload" component={UploadProfilePic} />
          <Route exact path="/ourAnswersToYourPerfectlyReasonableQuestions" component={FAQ} />
          <Route exact path="/recordAnswer" component={RecordAnswer} />
          <Route exact path="/accountInformation" component={AccountInformation} />
          <Route exact path="/transactions" component={Transactions} />
          <Route exact path="/usdAccount" component={UsdAccount} />
          <Route exact path="/openQuestion" component={OpenQuestion} />
          <Route exact path="/openQuestionPay" component={OpenQuestionPay} />
          <Route exact path="/openQuestionShare" component={OpenQuestionShare} />
          <Route exact path="/openQuestionSignUp" component={OpenQuestionSignUp} />
          <Route exact path="/openQuestionLogin" component={OpenQuestionLogin} />
          <Route exact path="/openQuestionRecordAnswer" component={OpenQuestionRecordAnswer} />
          <Route exact path="/recordSignUp" component={RecordSignUp} />
          <Route 
            path="/watch/:video"
            render={({ match }) => <Watch match={match} />}
          />
          <Route 
            path="/questionShare/:shareLink"
            render={({ match }) => <QuestionShare match={match} />}
          />
          <Route exact path="/questionCredits" component={QuestionCredit} />
          {/* <Route exact path="/testing" component={Testing} /> */}
          <Route exact path="/terms" component={TermsAndConditions} />
          <Route exact path="/privacy" component={PrivacyPolicy} />
          <Route path="/:id" component={Error404} />
        </Switch>
      </Router>
    </Provider>
)
    
    ReactDOM.render(
      routing, document.getElementById('root')
    );


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();