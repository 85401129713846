import React, { useEffect, useCallback } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import CardSection from './CardSection';
import { clientSecret, userId, stripeCustomerId, sources, userName, disabledCardButton } from './actions';
import { useSelector, useDispatch } from 'react-redux';
import * as firebase from 'firebase/app';
import '@firebase/firestore';
import { DefaultButton, colour, fontFamily, DesktopDefaultButton } from './GlobalStyles';
import { useHistory } from "react-router-dom";
import { isMobile } from 'react-device-detect';

export default function CardSetupForm() {
  const stripe = useStripe();
  const elements = useElements();
  const customerId = useSelector(state => state.stripeCustomerId);
  const currentClientSecret = useSelector(state => state.clientSecret);
  const currentUserId = useSelector(state => state.userId);
  const currentSources = useSelector(state => state.sources);
  const currentUserName = useSelector(state => state.userName);
  const currentHeight = useSelector(state => state.height);
  const disabledButton = useSelector(state => state.disabledCardButton);
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, []);
  const history = useHistory();

  if ( currentClientSecret !== "" ) {
    dispatch(disabledCardButton(false));
  }

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        stableDispatch(userId(user.uid));

        firebase.firestore().collection("Stripe_Customers").doc(user.uid).collection("Success").doc("Success")
        .onSnapshot(snapshot => {
          if (!snapshot.exists) {
            console.log("No such document!")
          }
          else {
            const data = snapshot.data();
            const paymentIntent = data.paymentIntent;
            const status = paymentIntent.status;
            
            if ( status === "succeeded" ) {
                alert("Succeeded");
                firebase.firestore().collection("Stripe_Customers").doc(user.uid).collection("Success").doc("Success").delete();
            }
            else if ( status === "error" ){
              alert(paymentIntent.message);
              firebase.firestore().collection("Stripe_Customers").doc(user.uid).collection("Success").doc("Success").delete();
            }
            else {
              alert("Oopp..something went wrong :(");
              firebase.firestore().collection("Stripe_Customers").doc(user.uid).collection("Success").doc("Success").delete();
            }
          }
        })

        firebase.firestore().collection("Stripe_Customers").doc(user.uid)
        .onSnapshot(snapshot => {
          if (!snapshot.exists) {
            console.log("No such document!")
          }
          else {
            const data = snapshot.data();
            const customerId = data.customer_id;
            const currentSources = data.sources;
            stableDispatch(stripeCustomerId(customerId));
            stableDispatch(sources(currentSources));
            setupIntent(user.uid, customerId);
          }
        })

        firebase.firestore().collection("Creator Profile").doc(user.uid)
        .onSnapshot(snapshot => {
          if (!snapshot.exists) {
            console.log("No such document!")
          }
          else {
            const data = snapshot.data();
            const currentUserName = data.userName;
            stableDispatch(userName(currentUserName));
          }
        })

        firebase.firestore().collection("Stripe_Customers").doc(user.uid).collection("Intent").doc("Intent")
        .onSnapshot(snapshot => {
          if (!snapshot.exists) {
            console.log("No such document!")
          }
          else {
            const data = snapshot.data();

            if ( data.intent !== undefined ) {
              const intent = data.intent;
              const currentClientSecret = intent.client_secret;
              stableDispatch(clientSecret(currentClientSecret));
            }
          }
        })
      }
      else {
        console.log("Not Logged in")
      }
    })
}, [stableDispatch])

function setupIntent(user, id) {
  firebase.firestore().collection("Stripe_Customers").doc(user).collection("SetupIntent").doc("SetupIntent").set({
    customerId: id
  })
  firebase.firestore().collection("Stripe_Customers").doc(user).collection("SetupIntent").doc("SetupIntent").delete();
}

  const handleSubmit = async (event) => {
    if ( currentClientSecret !== "" ) {
        dispatch(disabledCardButton(true));
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
        }

        const result = await stripe.confirmCardSetup(currentClientSecret, {
        payment_method: {
            card: elements.getElement(CardElement),
            billing_details: {
            name: currentUserName,
            },
        }
        });

        if (result.error) {
          console.log("error =", result.error);
          dispatch(disabledCardButton(false));

          if ( result.error.code === "card_declined" ) {
            alert("Oopps, your card was declined :( Please try again");

            setupIntent(currentUserId, customerId);
          }
          else if ( result.error.code === "incorrect_number" ) {
            alert("Oopps, your card number was incorrect :( Please try again");

            setupIntent(currentUserId, customerId);
          }
          else if ( result.error.code === "incomplete_number" ) {
            alert("Oopps, your card number was incomplete :( Please try again");

            setupIntent(currentUserId, customerId);
          }
          else if ( result.error.code === "incomplete_expiry" ) {
            alert("Oopps, your expiry date was incomplete :( Please try again");

            setupIntent(currentUserId, customerId);
          }
          else if ( result.error.code === "incomplete_cvc" ) {
            alert("Oopps, your cvc was incomplete :( Please try again");

            setupIntent(currentUserId, customerId);
          }
          else {
            alert("Oopps, there was an error :( Please try again");

            setupIntent(currentUserId, customerId);
          }
        } 
        else {
            dispatch(disabledCardButton(false));
            stripe.createToken(elements.getElement(CardElement))
            .then((tokenResult) => {
              const card = tokenResult.token.card;
              const last4 = card.last4;
              const brand = card.brand;
              const expiryMonth = card.exp_month;
              const expiryYear = card.exp_year;

              firebase.firestore().collection("Stripe_Customers").doc(currentUserId).collection("Intent").doc("Intent").delete();

              const sources = currentSources;
              const newSource = {
                                  paymentMethod: result.setupIntent.payment_method,
                                  brand: brand,
                                  last4: last4,
                                  expiryMonth: expiryMonth,
                                  expiryYear: expiryYear
                                };
              sources.unshift(newSource);
  
              firebase.firestore().collection("Stripe_Customers").doc(currentUserId).set({
                  sources
              }, {merge: true})

              history.push('../account');
            })
            .catch((err) => {
              console.log("token error =", err)
            })
        // The setup has succeeded. Display a success message and send
        // result.setupIntent.payment_method to your server to save the
        // card to a Customer
        }
    }   
    else {
        alert("empty client secret");
    }
  };
  
  const styles = {
    centre: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center'
    }
  }

  return (
    <div>
      <form>
        <CardSection />
        <div style={{ height: currentHeight * 0.02 }} />
        <div style={styles.centre}>
          {
            isMobile === true ?
            <DefaultButton 
              disabled={disabledButton}
              onClick={(event) => handleSubmit(event)}
            >
              Save Card
            </DefaultButton>
            :
            <DesktopDefaultButton 
              disabled={disabledButton}
              onClick={(event) => handleSubmit(event)}
            >
              Save Card
            </DesktopDefaultButton>
          }
          
        </div>
        <div style={{ height: currentHeight * 0.02 }} />
        <div style={styles.centre}>
          <p 
            style={{ cursor: 'pointer', fontSize: '1.125em', textAlign: 'center', color: colour.primaryMuted, fontWeight: '500', fontFamily: fontFamily }}
            onClick={() => history.push('./home')}
          >
            Nevermind, I'll do it later
          </p>
        </div>
      </form>
    </div>
  );
}