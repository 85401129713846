import React from 'react';
import { colour, ActionButton, fontFamily, DesktopActionButton, ShortDesktopQuidvidButton, QuidvidButton } from './GlobalStyles';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import Lottie from 'react-lottie-player';
import lottieJson from './bloop.json';

function LandingData() {

    const currentHeight = useSelector(state => state.height);
    const currentWidth = useSelector(state => state.width);
    let history = useHistory();

    function openStripe() {
        const win = window.open("https://www.stripe.com", '_blank');
        if (win != null) {
          win.focus();
        }
      }

    const styles = {
        outerContainer: {
            backgroundImage: `url(${require('./ink-background.png')}`,
            backgroundSize: 'cover',
            backgroundRepeat: 'repeat-x'
        },
        centre: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: currentWidth,
            backgroundColor: colour.light
        },
        width: {
            width: currentWidth * 0.8
        },
        row: {
            display: 'flex',
            flexDirection: 'row'
        },
        halfRow: {
            width: currentWidth * 0.5,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'flex-start'
        },
        half: {
            width: currentWidth * 0.5,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        },
        imageContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-end',
            width: currentWidth * 0.2
        },
        textContainer: {
            width: currentWidth * 0.3
        },
        ink: {
            width: currentWidth * 0.4
        },
        squidy: {
            width: currentWidth * 0.1,
        },
        darkContainer: {
            backgroundColor: colour.dark,
            width: currentWidth * 0.3,
            borderRadius: currentWidth * 0.01,
            paddingTop: currentHeight * 0.15,
            paddingLeft: currentHeight * 0.05,
            paddingRight: currentHeight * 0.05,
            paddingBottom: currentHeight * 0.15,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        },
        mobileBackground: {
            width: currentWidth,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundImage: `url(${require('./ink-background.png')}`,
            backgroundSize: 'cover',
            backgroundRepeat: 'repeat-x'
        },
        mobileText: {
            paddingLeft: currentWidth * 0.05,
            width: currentWidth * 0.7
        },
        mobileDark: {
            backgroundColor: colour.dark,
            width: currentWidth * 0.8,
            borderRadius: currentWidth * 0.05,
            padding: currentWidth * 0.05,
            height: currentHeight * 0.4
        },
        bloopContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        },
        bloop: {
            width: isMobile === true ? currentWidth * 0.6 : currentWidth * 0.3
        }
    }
    return (
        <div style={styles.outerContainer}>
            {
                isMobile === false ?
                <div style={styles.row}>
                    <div style={styles.halfRow}>
                        <div style={styles.imageContainer}>
                            <img
                                src={require('./squidy.svg')}
                                style={styles.squidy}
                                alt=""
                            />
                        </div>
                        <div style={styles.textContainer}>
                            <p style={{ color: colour.primaryMuted, textAlign: 'left', fontWeight: 'bold', fontSize: '1em', fontFamily: fontFamily }}>
                                @YouProbably{" "}
                                <span style={{ color: colour.med, textAlign: 'left', fontWeight: 'bold', fontSize: '1em', fontFamily: fontFamily }}>
                                    asked
                                </span>
                            </p>
                            <h3 style={{ color: colour.dark, textAlign: 'left', fontWeight: 'bold', fontSize: isMobile === true ? '1.375em' : 60, fontFamily: fontFamily }}>
                                So wtf is a quidvid anyway?
                            </h3>
                            <div style={{ height: currentHeight * 0.02 }} />
                            <ShortDesktopQuidvidButton
                                onClick={() => history.push('../signUp')}
                            >
                                Create new account
                            </ShortDesktopQuidvidButton>
                        </div>
                    </div>
                    <div style={styles.half}>
                        <div style={styles.darkContainer}>
                            <p style={{ color: colour.light, textAlign: 'left', fontWeight: 'bold', fontSize: '1.375em', fontFamily: fontFamily }}>
                                A quidvid is a request for an answer to a question in video form. 
                                The cost of this exchange is always a quid. 
                                Get quick answers to specific questions.
                            </p>
                        </div>
                    </div>
                </div>
                :
                <div style={styles.mobileBackground}>
                    <div style={styles.mobileText}>
                        <p style={{ color: colour.primaryMuted, textAlign: 'left', fontWeight: 'bold', fontSize: '1em', fontFamily: fontFamily }}>
                            @YouProbably{" "}
                            <span style={{ color: colour.med, textAlign: 'left', fontWeight: 'bold', fontSize: '1em', fontFamily: fontFamily }}>
                                asked
                            </span>
                        </p>
                        <h3 style={{ color: colour.dark, textAlign: 'left', fontWeight: 'bold', fontSize: isMobile === true ? '1.375em' : 60, fontFamily: fontFamily }}>
                            So wtf is a quidvid anyway?
                        </h3>
                    </div>
                    <div style={{ height: currentHeight * 0.02 }} />
                    <div style={styles.mobileDark}>
                        <div style={{ height: currentHeight * 0.02 }} />
                        <p style={{ color: colour.light, textAlign: 'center', fontWeight: 'regular', fontSize: '1.125em', fontFamily: fontFamily }}>
                            A quidvid is a request for an answer to a question in video form. 
                            The cost of this exchange is always a quid. 
                            Get quick answers to specific questions.
                        </p>
                    </div>
                    <div style={{ height: currentHeight * 0.02 }} />
                    <QuidvidButton
                        onClick={() => history.push('../signUp')}
                    >
                        Create new account
                    </QuidvidButton>
                </div>
            }
            <div style={{ height: currentHeight * 0.1 }} />
            <div style={styles.centre}>
                <div style={styles.width}>
                    <div style={{ height: currentHeight * 0.05 }} />
                    <h3 style={{ color: colour.primaryMuted, textAlign: 'center', fontWeight: 'bold', fontSize: isMobile === true ? '1.375em': 60, fontFamily: fontFamily }}>
                        How does it work?
                    </h3>
                    <div style={{ height: currentHeight * 0.03 }} />
                    <div style={{ height: currentHeight * 0.02 }} />
                    <p style={{ color: colour.dark, textAlign: 'center', fontWeight: 'bold', fontSize: '1.375em', fontFamily: fontFamily }}>
                        1. Quidvid request sent
                    </p>
                    <div style={{ height: currentHeight * 0.03 }} />
                    <div style={{ height: currentHeight * 0.01 }} />
                    <p style={{ color: colour.dark, textAlign: 'center', fontWeight: 'bold', fontSize: '1.375em', fontFamily: fontFamily }}>
                        2. Responder answers the quid with a vid
                    </p>
                    <div style={{ height: currentHeight * 0.03 }} />
                    <div style={{ height: currentHeight * 0.01 }} />
                    <p style={{ color: colour.dark, textAlign: 'center', fontWeight: 'bold', fontSize: '1.375em', fontFamily: fontFamily }}>
                        3. Asker gets charged a quid
                    </p>
                    <div style={{ height: currentHeight * 0.03 }} />
                    <div style={{ height: currentHeight * 0.01 }} />
                    <p style={{ color: colour.dark, textAlign: 'center', fontWeight: 'bold', fontSize: '1.375em', fontFamily: fontFamily }}>
                        4. Responder keeps the cash (minus the fees*)
                    </p>
                    <div style={{ height: currentHeight * 0.05 }} />
                    <div>
                </div>
                <div style={styles.bloopContainer}>
                    <Lottie
                      loop
                      animationData={lottieJson}
                      play
                      style={styles.bloop}
                    />
                </div>
                    <div style={styles.width}>
                        <p style={{ color: colour.med, textAlign: 'center', fontWeight: '500', fontSize: '0.875em', fontFamily: fontFamily }}>
                            *our service fee of 5p + Stripe payment processing fee of around 22p (depending on the details)
                        </p>
                        <div style={{ height: currentHeight * 0.02 }} />
                        <p style={{ color: colour.med, textAlign: 'center', fontWeight: '500', fontSize: '0.875em', fontFamily: fontFamily }}>
                            And yeah, we know — that seems like a fat chunk…it’s the cost of using a secure payment platform like Stripe. Don't get us started on PayPal..those pr*cks charge even more.
                        </p>
                        <div style={{ height: currentHeight * 0.02 }} />
                        <p style={{ color: colour.med, textAlign: 'center', fontWeight: '500', fontSize: '0.875em', fontFamily: fontFamily }}>
                            If this displeases you, feel free to leave a scathing review{" "}
                        <span
                            onClick={() => openStripe()}
                            style={{ color: colour.primaryMuted, textAlign: 'center', fontWeight: '500', fontSize: '1em', fontFamily: fontFamily, cursor: 'pointer' }}
                        >
                            here.
                        </span>
                        </p>
                    </div>
                    <div style={{ height: currentHeight * 0.05 }} />
                    <div style={styles.bloopContainer}>
                        {
                            isMobile === true ?
                            <ActionButton
                                onClick={() => history.push('../home')}
                            >
                                Get in on the action
                            </ActionButton>
                            :
                            <DesktopActionButton
                                onClick={() => history.push('../home')}
                            >
                                Get in on the action
                            </DesktopActionButton>
                        }
                    </div>
                    <div style={{ height: currentHeight * 0.1 }} />
                </div>
            </div>
        </div>
    )
}

export default LandingData;