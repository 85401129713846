import React, { useCallback, useEffect } from 'react';
import * as firebase from 'firebase/app';
import '@firebase/firestore';
import { useSelector, useDispatch } from 'react-redux';
import Checkbox from '@material-ui/core/Checkbox';
import { auth, email, password, userId, stayLoggedIn, userName, userList, authenticationDisabledButton, width, height } from './actions';
import { useHistory } from "react-router-dom";
import { colour, DesktopDefaultButton, DesktopInput, fontFamily } from './GlobalStyles';
import Lottie from 'react-lottie-player';
import lottieJson from './loading-animation.json';
//import FacebookButton from './FacebookButton';

function DesktopSignupComponent({ path }) {

    const currentWidth = useSelector(state => state.width);
    const currentHeight = useSelector(state => state.height);
    const newEmail = useSelector(state => state.email);
    const newPassword = useSelector(state => state.password);
    const stayLoggedInData = useSelector(state => state.stayLoggedIn);
    const currentUserName = useSelector(state => state.userName);
    const currentUsers = useSelector(state => state.userList);
    const disabledButton = useSelector(state => state.authenticationDisabledButton);
    const dispatch = useDispatch();
    let history = useHistory();
    const stableDispatch = useCallback(dispatch, []); 

    useEffect(() => {
        
      firebase.firestore().collection("Users").doc("List")
      .onSnapshot(snapshot => {
          if (!snapshot.exists) {
              console.log("No such document!")
          }
          else {
              const data = snapshot.data();
              const userData = data.users;
              stableDispatch(userList(userData)); 
          }
      })

      function resizeWindow () {
        const newWidth = window.innerWidth;
        const newHeight = window.innerHeight;
        stableDispatch(width(newWidth));
        stableDispatch(height(newHeight));
      };
      window.addEventListener("resize", resizeWindow);
      return () => window.removeEventListener("resize", resizeWindow);
    }, [history, stableDispatch]);

    function submit() {
      const userIndex = currentUsers.map((e) => { return e.userName; }).indexOf(currentUserName);

      if ( userIndex === -1 && currentUserName !== "" ) {
        dispatch(authenticationDisabledButton(true));
        firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
        .then(() => {
          // Existing and future Auth states are now persisted in the current
          // session only. Closing the window would clear any existing state even
          // if a user forgets to sign out.
          // ...
          // New sign-in will be persisted with session persistence.
          return firebase.auth().createUserWithEmailAndPassword(newEmail, newPassword);
        })
        .catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          dispatch(authenticationDisabledButton(false));
          if ( errorCode === "auth/invalid-email" ) {
            alert("Oopps..You entered an invalid e-mail address. Please try again!");
          }
          else if ( errorCode === "auth/user-disabled" ) {
            alert("Oopps..It looks like your profile has been disabled. Please contact us for further details");
          }
          else if ( errorCode === "auth/user-not-found" ) {
            alert("Oopps..We couldn't find your account in our database. Please try again!");
          }
          else if ( errorCode === "auth/weak-password" ) {
            alert("Oopps..It looks like you used a weak password. Please try again!");
          }
          else if ( errorCode === "auth/email-already-in-use" ) {
            alert("Oopps..It looks like this e-mail has already been used. Please try again with another e-mail address");
          }
          else {
            alert("Oopps...There was an error. Please try again!", errorMessage);
          }
        });
    
        firebase.auth().onAuthStateChanged((user) => {
          if (user) {
            dispatch(auth(true));
            dispatch(userId(user.uid));

            const db = firebase.firestore();
            const userDocRef = db.collection("Users").doc("List");

            db.runTransaction((transaction) => {
                return transaction.get(userDocRef).then((doc) => {
                    if (!doc.exists) {
                        console.log("Document does not exist!");
                    }
                    const users = doc.data().users;
                  
                    const newUser = {
                      userName: currentUserName,
                      userId: user.uid,
                      email: user.email,
                      photoURL: ""
                    }

                    users.push(newUser);
                    transaction.update(userDocRef, 
                        { 
                            users
                        });
                    return users;
                  
                });
            }).then(() => {
                console.log("Promise compiled with success");

                firebase.firestore().collection("Welcome_Email").doc(user.uid).set({
                  email: user.email
                })
                firebase.firestore().collection("Welcome_Email").doc(user.uid).delete();
    
                firebase.firestore().collection("Creator Profile").doc(user.uid).set({
                  userName: currentUserName,
                  email: user.email,
                  photoURL: "",
                  date: new Date()
              })
                dispatch(userName(""));
                dispatch(email(""));
                dispatch(password(""));
    
                history.push(path);
                dispatch(authenticationDisabledButton(false));
            }).catch((err) => {
                firebase.firestore().collection("Authentication Error").doc(user.uid).set({
                  error: err,
                  desktop: true
                })
                console.error(err);
            });
          }
          else {
            dispatch(auth(false));
    
          }
        });
      }
      else {
        alert("Your user name is taken!");
      }
    }

    const styles = {
        inkContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: currentWidth * 0.5
        },
        ink: {
            width: currentWidth * 0.3
        },
        centre: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start'
        },
          keepLogged: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          },
          forgotPassword: {
            display: 'flex',
            flexDirection: 'row',
            cursor: 'pointer'
          },
          row: {
            display: 'flex',
            flexDirection: 'row'
          },
          loading: {
            width: currentWidth * 0.2,
            position: 'absolute',
            zIndex: 1,
            bottom: 0
          }
    }

    return (
        <div style={styles.row}>
            {
              disabledButton === true ?
              <div style={styles.inkContainer}>
                <Lottie
                  loop
                  animationData={lottieJson}
                  play
                  style={styles.ink}
                />
              </div>
              :
              <div style={styles.inkContainer}>
                <img
                    src={require('./newaccount-ink.png')}
                    style={styles.ink}
                    alt=""
                />
              </div>
            }
            <div>
                <div>
                    <h3 style={{ fontFamily: fontFamily, color: colour.dark, fontSize: 65, fontWeight: 'bold' }}>
                        Good choice
                    </h3>
                </div>
                <div style={{ height: currentHeight * 0.02 }} />
                <div style={styles.centre}>
                    <DesktopInput
                      value={currentUserName}
                      id="outlined"
                      label="user name"
                      inputProps={{
                          autoCapitalize: 'none',
                      }}
                      variant="outlined" 
                      onChange={(text) => dispatch(userName(text.target.value.replace(/\s+/g, '')))}
                      style={styles.textField}
                    />
                    <div style={{ height: currentHeight * 0.02 }} />
                    <DesktopInput
                      value={newEmail}
                      id="outlined"
                      label="email"
                      inputProps={{
                          autoCapitalize: 'none',
                      }}
                      variant="outlined" 
                      onChange={(text) => dispatch(email(text.target.value.replace(/\s+/g, '')))}
                      style={styles.textField}
                    />
                    <div style={{ height: currentHeight * 0.02 }} />
                    <DesktopInput
                      value={newPassword}
                      id="outlined"
                      label="password"
                      type="password"
                      autoComplete="current-password"
                      inputProps={{
                          autoCapitalize: 'none',
                      }}
                      variant="outlined"
                      onKeyDown={(e) => e.key === 'Enter' ? submit() : null} 
                      onChange={(text) => dispatch(password(text.target.value))}
                      style={styles.textField}
                    />
                </div>
                <div style={styles.keepLogged}>
                    <Checkbox
                        checked={stayLoggedInData}
                        onChange={(state) => dispatch(stayLoggedIn(state.target.checked))}
                        name="checkedB"
                        label="Keep me logged in"
                        color="primary"
                    />
                    <p style={{ color: colour.med, fontSize: '1em', fontFamily: fontFamily }}>
                        Keep me logged in
                    </p>
                </div>
                <div style={styles.centre}>
                  <DesktopDefaultButton
                    disabled={disabledButton}
                    onClick={() => submit()}
                  >
                      That was easier than I expected
                  </DesktopDefaultButton>
                </div>
                <div style={{ height: currentHeight * 0.01 }} />
                
                {/* <FacebookButton 
                  path={path}
                /> */}
                
        </div>
        <div style={{ height: currentHeight * 0.02 }} />
    </div>
    )
}

export default DesktopSignupComponent;