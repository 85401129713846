import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { auth, userId, stripeCustomerId, sources, userName, width, height } from './actions';
import { useHistory } from "react-router-dom";
import * as firebase from 'firebase/app';
import '@firebase/firestore';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CardSetupForm from './CardSetupForm';
import CardPayForm from './CardPayForm';
import { fontFamily, colour } from './GlobalStyles';
import { isMobile } from 'react-device-detect';
import Footer from './Footer';
import FooterDesktop from './FooterDesktop';
import Header from './Header';
import Lottie from 'react-lottie-player';
import lottieJson from './loading-animation.json';
const stripePromise = loadStripe("pk_live_2mEru9mdQwdhFZRVB88jAe8C00EX61FoXX");

function AddPaymentMethod({ pay }) {

  const dispatch = useDispatch();
  const currentWidth = useSelector(state => state.width);
  const currentHeight = useSelector(state => state.height);
  const customerId = useSelector(state => state.stripeCustomerId);
  const disabledButton = useSelector(state => state.disabledCardButton);
  let history = useHistory();
  const stableDispatch = useCallback(dispatch, []);
    useEffect(() => {

        firebase.auth().onAuthStateChanged((user) => {
          if (user) {
            stableDispatch(auth(true));
            stableDispatch(userId(user.uid));

            function setupIntent() {

              firebase.auth().onAuthStateChanged((user) => {
                if (user) {
                  firebase.firestore().collection("Stripe_Customers").doc(user.uid).collection("SetupIntent").doc("SetupIntent").set({
                    customerId: customerId
                  })
                  firebase.firestore().collection("Stripe_Customers").doc(user.uid).collection("SetupIntent").doc("SetupIntent").delete();
                }
                else {
                }
              })
            }
            setupIntent();
    
            firebase.firestore().collection("Creator Profile").doc(user.uid)
            .onSnapshot(snapshot => {
              if (!snapshot.exists) {
                console.log("No such document!")
              }
              else {
                const data = snapshot.data();
                const currentUserName = data.userName;
                stableDispatch(userName(currentUserName));
              }
            })
        
            firebase.firestore().collection("Stripe_Customers").doc(user.uid)
            .onSnapshot(snapshot => {
              if (!snapshot.exists) {
                console.log("No such document!")
              }
              else {
                const data = snapshot.data();
                const customerId = data.customer_id;
                const currentSources = data.sources;
                stableDispatch(stripeCustomerId(customerId));
                stableDispatch(sources(currentSources));
              }
            })
          }
          else {
            history.push('./');
            stableDispatch(auth(false));
          }
        })
        
        function resizeWindow () {
          const newWidth = window.innerWidth;
          const newHeight = window.innerHeight;
          stableDispatch(width(newWidth));
          stableDispatch(height(newHeight));
        };
        window.addEventListener("resize", resizeWindow);
        return () => window.removeEventListener("resize", resizeWindow);
      }, [stableDispatch, history, customerId]);

    const styles = {
      header: {
        paddingLeft: currentWidth * 0.05
      },
      heading: {
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: currentWidth * 0.1,
        width: currentWidth * 0.8
      },
      centre: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      },
      smallWidth: {
        width: isMobile === true ? currentWidth * 0.9 : currentWidth * 0.4
      },
      loadingWidth: {
        width: currentWidth * 0.5
      },
      desktopContainer :{
        width: currentWidth * 0.5
      },
      pound: {
        width: currentWidth * 0.1,
        position: 'absolute',
        right: currentWidth * 0.4,
        zIndex: -1
      },
      ink: {
        width: currentWidth * 0.3
      },
      row: {
        display: 'flex',
        flexDirection: 'row',
      },
      mobileAnimation: {
        width: currentWidth * 0.5,
        position: 'absolute',
        zIndex: 1,
        left: currentWidth * 0.25
      },
      mobileAnimationContainer: {
        width: currentWidth,
        height: currentHeight,
        backgroundColor: 'rgba(255,255,255,0.5)',
        position: 'absolute',
        zIndex: 1
      }
    }

    return (
      <div>
        {
          pay === true ?
          null
          :
          <Header />
        }
          {
            isMobile === true ?
            <div>
              <div style={styles.centre}>
                  <h3 style={{ fontFamily: fontFamily, fontSize: '1.375em', textAlign: 'center', color: colour.dark }}>
                      Your* card details
                  </h3>
              </div>
              <div style={{ height: currentHeight * 0.1 }} />
              {
                disabledButton === true ?
                  <div style={styles.mobileAnimationContainer}>
                    <Lottie
                      loop
                      animationData={lottieJson}
                      play
                      style={styles.mobileAnimation}
                    />
                  </div>
                :
                null
              }
              <Elements stripe={stripePromise}>
                {
                  pay === true ?
                  <CardPayForm />
                  :
                  <CardSetupForm />
                }
              </Elements>
              <div style={{ height: currentHeight * 0.1 }} />
              <div style={styles.centre}>
                <div style={styles.smallWidth}>
                  <p style={{ fontSize: '0.75em', color: colour.med }}>
                    *or your mum's, or your sugar daddy's, or that visa gift card from nan
                    that you haven't used yet... we don't judge
                  </p>
                </div>
              </div>
            </div>
            :
            <div style={styles.row}>
              {
                disabledButton === true ?
                <div style={styles.loadingWidth}>
                  <Lottie
                    loop
                    animationData={lottieJson}
                    play
                    style={styles.ink}
                  />
                </div>
                :
                <div style={styles.loadingWidth} />
              }
              <div style={styles.desktopContainer}>
                <div style={styles.centre}>
                  <img
                    src={require('./pound-moving-left.svg')}
                    style={styles.pound}
                    alt=""
                  />
                    <h3 style={{ fontFamily: fontFamily, fontSize: 60, textAlign: 'center', color: colour.dark }}>
                        Your* card details
                    </h3>
                </div>
                <div style={{ height: currentHeight * 0.1 }} />
                <Elements stripe={stripePromise}>
                  {
                    pay === true ?
                    <CardPayForm />
                    :
                    <CardSetupForm />
                  }
                </Elements>
                <div style={{ height: currentHeight * 0.2 }} />
                <div style={styles.centre}>
                  <div style={styles.smallWidth}>
                    <p style={{ fontSize: '0.75em', color: colour.med }}>
                      *or your mum's, or your sugar daddy's, or that visa gift card from nan
                      that you haven't used yet... we don't judge
                    </p>
                  </div>
                </div>
              </div>
            </div>
          }
          {
            isMobile === true ?
            <Footer />
            :
            <FooterDesktop/>
          }
        </div>
    )
}

export default AddPaymentMethod;