import React, { useCallback, useEffect } from 'react';
import * as firebase from 'firebase/app';
import '@firebase/firestore';
import { useSelector, useDispatch } from 'react-redux';
import Checkbox from '@material-ui/core/Checkbox';
import { auth, loginEmail, loginPassword, userId, stayLoggedIn, authenticationDisabledButton, width, height } from './actions';
import { useHistory } from "react-router-dom";
import { colour, DesktopLoginButton, DesktopInput, fontFamily, DesktopQuidvidButton } from './GlobalStyles';
import Lottie from 'react-lottie-player';
import lottieJson from './loading-animation.json';
//import FacebookButton from './FacebookButton';

function DesktopLoginComponent({ path }) {

    const currentWidth = useSelector(state => state.width);
    const currentHeight = useSelector(state => state.height);
    const currentEmail = useSelector(state => state.loginEmail);
    const currentPassword = useSelector(state => state.loginPassword);
    const stayLoggedInData = useSelector(state => state.stayLoggedIn);
    const buttonDisabled = useSelector(state => state.authenticationDisabledButton);
    let history = useHistory();
    const dispatch = useDispatch();
    const stableDispatch = useCallback(dispatch, []);

    useEffect(() => {
        
      function resizeWindow () {
        const newWidth = window.innerWidth;
        const newHeight = window.innerHeight;
        stableDispatch(width(newWidth));
        stableDispatch(height(newHeight));
      };
      window.addEventListener("resize", resizeWindow);
      return () => window.removeEventListener("resize", resizeWindow);
    }, [history, stableDispatch]);

    function submit() {
        dispatch(authenticationDisabledButton(true));
        firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
        .then(() => {
          // Existing and future Auth states are now persisted in the current
          // session only. Closing the window would clear any existing state even
          // if a user forgets to sign out.
          // ...
          // New sign-in will be persisted with session persistence.
          return firebase.auth().signInWithEmailAndPassword(currentEmail, currentPassword);
        })
        .catch((error) => {
          // Handle Errors here.
          dispatch(authenticationDisabledButton(false));
          const errorCode = error.code;
          const errorMessage = error.message;
    
          if ( errorCode === "auth/invalid-email" ) {
            alert("Oopps..You entered an invalid e-mail address. Please try again!");
          }
          else if ( errorCode === "auth/user-disabled" ) {
            alert("Oopps..It looks like your profile has been disabled. Please contact us for further details");
          }
          else if ( errorCode === "auth/user-not-found" ) {
            alert("Oopps..We couldn't find your account in our database. Please try again!");
          }
          else if ( errorCode === "auth/weak-password" ) {
            alert("Oopps..It looks like you used a weak password. Please try again!");
          }
          else if ( errorCode === "auth/email-already-in-use" ) {
            alert("Oopps..It looks like this e-mail has already been used. Please try again with another e-mail address");
          }
          else {
            alert("Oopps...There was an error. Please try again!", errorMessage);
          }
        });
    
        firebase.auth().onAuthStateChanged((user) => {
          if (user) {
            dispatch(auth(true));
            dispatch(userId(user.uid));
            dispatch(loginEmail(""));
            dispatch(loginPassword(""));
            dispatch(authenticationDisabledButton(false));
            history.push(path);
          }
          else {
            dispatch(auth(false));
    
          }
        });
      }

    const styles = {
        inkContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: currentWidth * 0.5
        },
        ink: {
            width: currentWidth * 0.3
        },
        centre: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start'
        },
          keepLogged: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          },
          forgotPassword: {
            display: 'flex',
            flexDirection: 'row',
            cursor: 'pointer'
          },
          row: {
            display: 'flex',
            flexDirection: 'row'
          }
    }

    return (
        <div style={styles.row}>
            {
              buttonDisabled === true ?
              <div style={styles.inkContainer}>
                <Lottie
                  loop
                  animationData={lottieJson}
                  play
                  style={styles.ink}
                />
              </div>
              :
              <div style={styles.inkContainer}>
                <img
                    src={require('./newaccount-ink.png')}
                    style={styles.ink}
                    alt=""
                />
              </div>
            }
            <div>
                <div>
                    <h3 style={{ fontFamily: fontFamily, color: colour.dark, fontSize: 65, fontWeight: 'bold' }}>
                        Welcome back {'<3'}
                    </h3>
                </div>
                <div style={{ height: currentHeight * 0.02 }} />
                <div style={styles.centre}>
                    <DesktopInput
                        value={currentEmail}
                        id="outlined"
                        label="email"
                        inputProps={{
                            autoCapitalize: 'none',
                        }}
                        variant="outlined" 
                        onChange={(text) => dispatch(loginEmail(text.target.value))}
                        style={styles.textField}
                    />
                    <div style={{ height: currentHeight * 0.02 }} />
                    <DesktopInput
                        value={currentPassword}
                        id="outlined"
                        label="password"
                        type="password"
                        autoComplete="current-password"
                        inputProps={{
                            autoCapitalize: 'none',
                        }}
                        variant="outlined"
                        onKeyDown={(e) => e.key === 'Enter' ? submit() : null} 
                        onChange={(text) => dispatch(loginPassword(text.target.value))}
                        style={styles.textField}
                    />
                </div>
                <div style={styles.keepLogged}>
                    <Checkbox
                        checked={stayLoggedInData}
                        onChange={(state) => dispatch(stayLoggedIn(state.target.checked))}
                        name="checkedB"
                        label="Keep me logged in"
                        color="primary"
                    />
                    <p style={{ color: colour.med, fontSize: '1em', fontFamily: fontFamily }}>
                        Keep me logged in
                    </p>
                </div>
                <div>
                <div style={styles.centre}>
                    <DesktopLoginButton 
                        disabled={buttonDisabled}
                        onClick={() => submit()}
                    >
                        Login
                    </DesktopLoginButton>
                    <div style={{ height: currentHeight * 0.02 }} />
                    <div style={styles.row}>
                        <div style={styles.forgotPassword}>
                            <p 
                                onClick={() => alert("Navigate somewhere")}
                                style={{ cursor: 'pointer', color: colour.primaryMuted, fontWeight: 'bold', fontFamily: fontFamily, fontsize: '1.125em' }}
                            >
                                sh*t, I forgot
                            </p>
                        </div>
                    </div>
                    <div style={{ height: currentHeight * 0.05 }} />
                    <div style={styles.centre}>
                        <p style={{ color: colour.med, fontWeight: '500', fontFamily: fontFamily, fontsize: '0.75' }}>
                            (Or if you needed more options)
                        </p> 
                        <div style={{ height: currentHeight * 0.02 }} />
                        <DesktopQuidvidButton 
                            onClick={() => history.push('./signUp')}
                        >
                            Create new account
                        </DesktopQuidvidButton>
                    </div>
                </div>
            </div>
        </div>
        <div style={{ height: currentHeight * 0.02 }} />
            {
              /*
              <FacebookButton
                path={path}
              />
              */
            }
    </div>
    )
}

export default DesktopLoginComponent;