export const auth = (auth) => {
    return {
        type: 'signIn',
        payload: auth
    }
}

export const email = (email) => {
    return {
        type: 'email',
        payload: email
    }
}

export const password = (password) => {
    return {
        type: 'password',
        payload: password
    }
}

export const userId = (userId) => {
    return {
        type: 'userId',
        payload: userId
    }
}

export const width = (width) => {
    return {
        type: 'width',
        payload: width
    }
}

export const height = (height) => {
    return {
        type: 'height',
        payload: height
    }
}

export const stayLoggedIn = (stayLoggedIn) => {
    return {
        type: 'stayLoggedIn',
        payload: stayLoggedIn
    }
}

export const stripeCustomerId = (stripeCustomerId) => {
    return {
        type: 'stripeCustomerId',
        payload: stripeCustomerId
    }
}

export const clientSecret = (clientSecret) => {
    return {
        type: 'clientSecret',
        payload: clientSecret
    }
}

export const sources = (sources) => {
    return {
        type: 'sources',
        payload: sources
    }
}

export const loginEmail = (loginEmail) => {
    return {
        type: 'loginEmail',
        payload: loginEmail
    }
}

export const loginPassword = (loginPassword) => {
    return {
        type: 'loginPassword',
        payload: loginPassword
    }
}

export const userName = (userName) => {
    return {
        type: 'userName',
        payload: userName
    }
}

export const newQuestionText = (newQuestionText) => {
    return {
        type: 'newQuestionText',
        payload: newQuestionText
    }
}

export const newVideoBlob = (newVideoBlob) => {
    return {
        type: 'newVideoBlob',
        payload: newVideoBlob
    }
}

export const videoUpload = (videoUpload) => {
    return {
        type: 'videoUpload',
        payload: videoUpload
    }
}

export const paymentCurrency = (paymentCurrency) => {
    return {
        type: 'paymentCurrency',
        payload: paymentCurrency
    }
}

export const searchField = (searchField) => {
    return {
        type: 'searchField',
        payload: searchField
    }
}

export const creatorList = (creatorList) => {
    return {
        type: 'creatorList',
        payload: creatorList
    }
}

export const searchFieldFilter = (searchFieldFilter) => {
    return {
        type: 'searchFieldFilter',
        payload: searchFieldFilter
    }
}

export const disabledCardButton = (disabledCardButton) => {
    return {
        type: 'disabledCardButton',
        payload: disabledCardButton
    }
}

export const playVideo = (playVideo) => {
    return {
        type: 'playVideo',
        payload: playVideo
    }
}

export const quidIndex = (quidIndex) => {
    return {
        type: 'quidIndex',
        payload: quidIndex
    }
}

export const feedData = (feedData) => {
    return {
        type: 'feedData',
        payload: feedData
    }
}

export const ownProfile = (ownProfile) => {
    return {
        type: 'ownProfile',
        payload: ownProfile
    }
}

export const profileUserId = (profileUserId) => {
    return {
        type: 'profileUserId',
        payload: profileUserId
    }
}

export const profileUserName = (profileUserName) => {
    return {
        type: 'profileUserName',
        payload: profileUserName
    }
}

export const questioner = (questioner) => {
    return {
        type: 'questioner',
        payload: questioner
    }
}

export const anonymous = (anonymous) => {
    return {
        type: 'anonymous',
        payload: anonymous
    }
}

export const accountNumber = (accountNumber) => {
    return {
        type: 'accountNumber',
        payload: accountNumber
    }
}

export const sortCode = (sortCode) => {
    return {
        type: 'sortCode',
        payload: sortCode
    }
}

export const bug = (bug) => {
    return {
        type: 'bug',
        payload: bug
    }
}

export const bugReports = (bugReports) => {
    return {
        type: 'bugReports',
        payload: bugReports
    }
}

export const secret = (secret) => {
    return {
        type: 'secret',
        payload: secret
    }
}

export const secretArray = (secretArray) => {
    return {
        type: 'secretArray',
        payload: secretArray
    }
}

export const secretAnonymous = (secretAnonymous) => {
    return {
        type: 'secretAnonymous',
        payload: secretAnonymous
    }
}

export const newQuestions = (newQuestions) => {
    return {
        type: 'newQuestions',
        payload: newQuestions
    }
}

export const totalEarnings = (totalEarnings) => {
    return {
        type: 'totalEarnings',
        payload: totalEarnings
    }
}

export const totalQuidvids = (totalQuidvids) => {
    return {
        type: 'totalQuidvids',
        payload: totalQuidvids
    }
}

export const userPicture = (userPicture) => {
    return {
        type: 'userPicture',
        payload: userPicture
    }
}

export const hate = (hate) => {
    return {
        type: 'hate',
        payload: hate
    }
}

export const hateArray = (hateArray) => {
    return {
        type: 'hateArray',
        payload: hateArray
    }
}

export const viewingProfileName = (viewingProfileName) => {
    return {
        type: 'viewingProfileName',
        payload: viewingProfileName
    }
}

export const viewingProfileUserPicture = (viewingProfileUserPicture) => {
    return {
        type: 'viewingProfileUserPicture',
        payload: viewingProfileUserPicture
    }
}

export const viewingProfileTotalQuidvids = (viewingProfileTotalQuidvids) => {
    return {
        type: 'viewingProfileTotalQuidvids',
        payload: viewingProfileTotalQuidvids
    }
}

export const userList = (userList) => {
    return {
        type: 'userList',
        payload: userList
    }
}

export const authenticationDisabledButton = (authenticationDisabledButton) => {
    return {
        type: 'authenticationDisabledButton',
        payload: authenticationDisabledButton
    }
}

export const profileNewQuestions = (profileNewQuestions) => {
    return {
        type: 'profileNewQuestions',
        payload: profileNewQuestions
    }
}

export const askedQuestionsList = (askedQuestionsList) => {
    return {
        type: 'askedQuestionsList',
        payload: askedQuestionsList
    }
}

export const questionVideo = (questionVideo) => {
    return {
        type: 'questionVideo',
        payload: questionVideo
    }
}

export const questionVideoIndex = (questionVideoIndex) => {
    return {
        type: 'questionVideoIndex',
        payload: questionVideoIndex
    }
}

export const answeredQuestions = (answeredQuestions) => {
    return {
        type: 'answeredQuestions',
        payload: answeredQuestions
    }
}

export const askedQuestionIndex = (askedQuestionIndex) => {
    return {
        type: 'askedQuestionIndex',
        payload: askedQuestionIndex
    }
}

export const userIndex = (userIndex) => {
    return {
        type: 'userIndex',
        payload: userIndex
    }
}

export const loadingIndex = (loadingIndex) => {
    return {
        type: 'loadingIndex',
        payload: loadingIndex
    }
}

export const declineQuidModal = (declineQuidModal) => {
    return {
        type: 'declineQuidModal',
        payload: declineQuidModal
    }
}

export const transactions = (transactions) => {
    return {
        type: 'transactions',
        payload: transactions
    }
}

export const accountCreated = (accountCreated) => {
    return {
        type: 'accountCreated',
        payload: accountCreated
    }
}

export const activeBalance = (activeBalance) => {
    return {
        type: 'activeBalance',
        payload: activeBalance
    }
}

export const bottomFeed = (bottomFeed) => {
    return {
        type: 'bottomFeed',
        payload: bottomFeed
    }
}

export const usdAccountNumber = (usdAccountNumber) => {
    return {
        type: 'usdAccountNumber',
        payload: usdAccountNumber
    }
}

export const usdRoutingNumber = (usdRoutingNumber) => {
    return {
        type: 'usdRoutingNumber',
        payload: usdRoutingNumber
    }
}

export const completeName = (completeName) => {
    return {
        type: 'completeName',
        payload: completeName
    }
}

export const streetAddress = (streetAddress) => {
    return {
        type: 'streetAddress',
        payload: streetAddress
    }
}

export const zipCode = (zipCode) => {
    return {
        type: 'zipCode',
        payload: zipCode
    }
}

export const usState = (usState) => {
    return {
        type: 'usState',
        payload: usState
    }
}

export const usDetails = (usDetails) => {
    return {
        type: 'usDetails',
        payload: usDetails
    }
}

export const usAccountType = (usAccountType) => {
    return {
        type: 'usAccountType',
        payload: usAccountType
    }
}

export const watchCode = (watchCode) => {
    return {
        type: 'watchCode',
        payload: watchCode
    }
} 

export const copyToClipboard = (copyToClipboard) => {
    return {
        type: 'copyToClipboard',
        payload: copyToClipboard
    }
}

export const questionCredits = (questionCredits) => {
    return {
        type: 'questionCredits',
        payload: questionCredits
    }
}

export const sharedQuestions = (sharedQuestions) => {
    return {
        type: 'sharedQuestions',
        payload: sharedQuestions
    }
}

export const sharedQuestionIndex = (sharedQuestionIndex) => {
    return {
        type: 'sharedQuestionIndex',
        payload: sharedQuestionIndex
    }
}

export const sharedQuestionURL = (sharedQuestionURL) => {
    return {
        type: 'sharedQuestionURL',
        payload: sharedQuestionURL
    }
}

export const questionShareActive = (questionShareActive) => {
    return {
        type: 'questionShareActive',
        payload: questionShareActive
    }
}