import React from "react";
import "./Video.css";
import ReactPlayer from 'react-player';
import { useSelector, useDispatch } from 'react-redux';
import { playVideo, authenticationDisabledButton, loadingIndex } from './actions';
import { colour, fontFamily } from './GlobalStyles';
import { useHistory } from "react-router-dom";
import { isMobile } from 'react-device-detect';
import Lottie from 'react-lottie-player';
import lottieJson from './loading-animation.json';

function Video({ item, index, length }) {

const videoPlaying = useSelector(state => state.playVideo);
const currentWidth = useSelector(state => state.width);
const currentHeight = useSelector(state => state.height);
const loading = useSelector(state => state.authenticationDisabledButton);
const currentLoadingIndex = useSelector(state => state.loadingIndex);
let history = useHistory();
const dispatch = useDispatch();

const pausePlay = (index, play) => {
    if ( index === play ) {
        
        dispatch(playVideo(null));
    }
    else {
        
        dispatch(playVideo(index));
    }
}

const onBuffer = (index) => {
    dispatch(authenticationDisabledButton(true));
    dispatch(loadingIndex(index));
}

const onBufferEnd = () => { 
    dispatch(authenticationDisabledButton(false));
    dispatch(loadingIndex(0))
}

function navigate(path) {

    if ( path !== "anonymous" ) {
        dispatch(playVideo(null));
        history.push(`../profile/${path}`);
    }
}

const styles = {
    userPhoto: {
        width: isMobile === true ? currentWidth * 0.1 : currentWidth * 0.03,
        height: isMobile === true ? currentWidth * 0.1 : currentWidth * 0.03,
        borderRadius: isMobile === true ? currentWidth * 0.1 : currentWidth * 0.015,
    },
    data: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        flexWrap: 'wrap',
        paddingLeft: isMobile === true ? currentWidth * 0.03 : 0
    },
    question: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        height: isMobile === false ? currentHeight * 0.45 : currentHeight * 0.4,
        paddingLeft: currentWidth * 0.05,
        paddingTop: currentHeight * 0.01
    },
    questionText: {
        color: colour.dark, 
        fontSize: isMobile === false ? 30 : currentHeight > 600 ? '1em' : item.userQuestion.length < 200 ? '0.875em' : '0.75em', 
        fontFamily: fontFamily, 
        fontWeight: 'bold', 
        lineHeight: currentHeight > 600 ? '1.125em' : '1em'
    },
    pointerQuestionText: {
        color: colour.dark, 
        fontSize: isMobile === false ? 30 : currentHeight > 600 ? '1em' : item.userQuestion.length < 200 ? '0.875em' : '0.75em', 
        fontFamily: fontFamily, 
        fontWeight: 'bold', 
        lineHeight: currentHeight > 600 ? '1.125em' : '1em',
        cursor: 'pointer'
    },
    questioner: {
        color: colour.primaryMuted, 
        fontSize: isMobile === true ? '0.75em' : 24, 
        fontFamily: fontFamily, 
        fontWeight: '500', cursor: 'pointer'
    },
    creator: {
        fontWeight: isMobile === true ? '500' : 'bold', 
        fontSize: isMobile === true ? '1em' : 26, 
        color: colour.primaryMuted, 
        cursor: 'pointer'
    },
    quidvidTotal: {
        fontSize: isMobile === true ? '0.75em' : 28, 
        color: colour.med, 
        fontFamily: fontFamily,
        fontWeight: 'lighter'
    },
    creatorImage: {
        width: isMobile === true ? currentWidth * 0.1 : currentWidth * 0.05,
        height: isMobile === true ? currentWidth * 0.1 : currentWidth * 0.05,
        borderRadius: isMobile === true ? currentWidth * 0.1 : currentWidth * 0.025,
    },
    loading: {
        width: currentWidth * 0.2,
        position: 'absolute',
        zIndex: 1,
        right: currentWidth * 0.05
    }
}

    //iPhone SE = 320 + 454
    //iPhone X = 375 + 635

  return (
    <div className={isMobile === true ? "card" : "desktopCard"}>
        <div className={isMobile === false ? "sidebarDesktop" : index === length - 1 ? "sidebarLast" : "sidebar"}>
            <div style={styles.data}>
                <img
                    src={item.userName === 'anonymous' ? require('./anonymous.svg') : item.userPhoto === "" ? require('./anonymous.svg') : item.userPhoto}
                    style={styles.userPhoto}
                    alt=""
                />
                <div style={{ width: currentWidth * 0.01 }} />
                <p 
                    onClick={() => navigate(item.userName)}
                    style={styles.questioner}
                >
                    @{item.userName}{" "}
                    <span style={{ color: colour.med, fontSize: '1em', fontFamily: fontFamily }}>
                        asked
                    </span>
                </p>
            </div>
           <div style={styles.question}>
                <p 
                    onClick={() => item.code !== undefined ? history.push(`./watch/${item.code}`) : null}
                    style={item.code !== undefined ? styles.pointerQuestionText : styles.questionText}
                >
                   {item.userQuestion}
                </p>
           </div>
            <div style={styles.data}>
                <div>
                    <img
                        src={item.creatorImage === "" ? require('./anonymous.svg') : item.creatorImage}
                        style={styles.creatorImage}
                        alt=""
                    />
                </div>
                <div style={{ width: currentWidth * 0.01 }} />
                <div>
                    <p 
                        onClick={() => navigate(item.creatorName)}
                        style={styles.creator}
                    >
                        @{item.creatorName}
                    </p>
                    <p style={styles.quidvidTotal}>
                        {item.totalQuidvids} {item.totalQuidvids === 1 ? 'quidvid' : 'quidvids'}
                    </p>
                </div>
            </div>
        </div>
        {
            loading === true && index === currentLoadingIndex ?
            <Lottie
                loop
                animationData={lottieJson}
                play
                style={styles.loading}
            />
            :
            null
        }
        <ReactPlayer
            className={isMobile === false ? "video__playerDesktop" : index === length - 1 ? "video__playerLast" : "video__player"}
            url={item.creatorVideo}
            playing={index === videoPlaying ? true : false}
            onClick={() => pausePlay(index, videoPlaying)}
            loop={false}
            controls={true}
            height={isMobile === true ? "70%" : "100%"}
            width={isMobile === true ? "50%" : "100%"}
            onBufferEnd={() => onBufferEnd()}
            onBuffer={() => onBuffer(index)}
        />
    </div>
  );
}

export default Video;