import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as firebase from 'firebase/app';
import '@firebase/firestore';
import { width, height, auth, userId } from './actions';
import Feed from './Feed';
import SearchComponent from './SearchComponent';
import Header from './Header';
import NavBar from './NavBar';
import { isMobile } from 'react-device-detect';
import NavBarDesktop from './NavBarDesktop';

function Home() {

    const currentHeight = useSelector(state => state.height);
    const currentWidth = useSelector(state => state.width);
    const dispatch = useDispatch();
    const stableDispatch = useCallback(dispatch, []);

    useEffect(() => {

        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
              stableDispatch(auth(true));
              stableDispatch(userId(user.uid));

            }
            else {
                stableDispatch(auth(false));
                stableDispatch(userId(""));
            }
        })

      function resizeWindow () {
        const newWidth = window.innerWidth;
        const newHeight = window.innerHeight;
        stableDispatch(width(newWidth));
        stableDispatch(height(newHeight));
      };
      window.addEventListener("resize", resizeWindow);
      return () => window.removeEventListener("resize", resizeWindow);
  }, [stableDispatch]);

  const styles = {
    navbar: {
      position: 'fixed', 
      zIndex: 1,
      bottom: 0, 
      left: 0,
      opacity: 1
    },
    navbarDesktop: {
      position: 'absolute', 
      zIndex: 1,
      left: 0,
      opacity: 1,
      bottom: currentHeight * 0.1
    },
    row: {
      display: 'flex',
      flexDirection: 'row'
    },
    search: {
      width: currentWidth * 0.3
    },
    feed: {
      width: currentWidth * 0.7
    }
  }

    return (
      <div>
        <Header />
        {
          isMobile === false ?
          <div style={styles.row}>
            <div style={styles.search}>
              <SearchComponent/>
            </div>
            <div style={styles.feed}>
              <Feed />
            </div>
          </div>
          :
          <div>
            <SearchComponent/>
            <Feed />
          </div>
        }
        {
          isMobile === true ?
          <div style={styles.navbar}>
            <NavBar />
          </div>
          :
          <div style={styles.navbarDesktop}>
            <NavBarDesktop />
          </div>
        }
      </div>
    )
}

export default Home;