import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import Header from './Header';
import { fontFamily, colour, Input, DefaultButton, DesktopInput, DesktopDefaultButton } from './GlobalStyles';
import { width, height, accountNumber, sortCode } from './actions';
import { useSelector, useDispatch } from 'react-redux';
import * as firebase from 'firebase/app';
import '@firebase/firestore';
import { isMobile } from 'react-device-detect';

function BankAccountInfo() {

    const currentHeight = useSelector(state => state.height);
    const currentWidth = useSelector(state => state.width);
    const accountNumberData = useSelector(state => state.accountNumber);
    const sortCodeData = useSelector(state => state.sortCode);
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {

        firebase.auth().onAuthStateChanged( user => {
            if (user) {
                firebase.firestore().collection("Creator Profile").doc(user.uid)
                .onSnapshot(snapshot => {
                    if (!snapshot.exists) {
                        console.log('No such document!');
                    }
                    else {
                        const data = snapshot.data();
                        if ( data.accountNumber !== undefined ) {
                            const accountNumberData = data.accountNumber;
                            dispatch(accountNumber(accountNumberData));
                        }

                        if ( data.sortCode !== undefined ) {
                            const sortCodeData = data.sortCode;
                            dispatch(sortCode(sortCodeData));
                        }
                    }
                })
            }
        })

        resizeWindow();
        window.addEventListener("resize", resizeWindow);
        return () => window.removeEventListener("resize", resizeWindow);
    });

    function resizeWindow () {
        const newWidth = window.innerWidth;
        const newHeight = window.innerHeight;
        dispatch(width(newWidth));
        dispatch(height(newHeight));
      };

      function saveCard() {
        if ( accountNumberData.length !== 8 ) {
            alert("Oopps..your account number is not 8 digits. You may want to check it again!");
        }
        else if ( sortCodeData.length !== 6 ) {
            alert("Oopps..your sort code is not 6 digits. You may want to check it again!");
        }
        else {

            firebase.auth().onAuthStateChanged( user => {
                if (user) {
                    firebase.firestore().collection("Creator Profile").doc(user.uid).set({
                        accountNumber: accountNumberData,
                        sortCode: sortCodeData,
                        usDetails: false
                    }, {merge: true})

                    dispatch(accountNumber(""));
                    dispatch(sortCode(""));
                    history.push('../account');
                }
            })
        }
      }

    const styles = {
        centre: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        },
        payoutText: {
            width: isMobile === true ? currentWidth * 0.9 : currentWidth * 0.4
        }
    }

    return (
        <div>
            <Header />
            <div style={styles.centre}>
                <h1 style={{ fontWeight: 'bold', fontSize: '1.375em', fontFamily: fontFamily, color: colour.dark, textAlign: 'center' }}>
                    Account Number {'&'} Sort Code
                </h1>
            </div>
            <div style={{ height: currentHeight * 0.05 }} />
            <div style={styles.centre}>
                <div style={styles.payoutText}>
                    <p style={{ color: colour.med, fontWeight: 'bold', fontFamily: fontFamily, fontSize: '0.875em' }}>
                        Payouts occur Monthly. 
                        <p style={{ fontWeight: 'normal' }}>
                            Your balance must be at 
                            least £10 before fees in order to receive payout, 
                            otherwise it will be pushed to the following month.    
                        </p>
                    </p>
                </div>
            </div>
            <div style={{ height: currentHeight * 0.05 }} />
            {
                isMobile === true ?
                <div style={styles.centre}>
                    <Input
                        value={accountNumberData}
                        id="outlined"
                        label="Account Number"
                        inputProps={{
                            autoCapitalize: 'none',
                        }}
                        variant="outlined" 
                        onChange={(text) => isNaN(Number(text.target.value)) === true ? null : text.target.value.length === 9 ? null : dispatch(accountNumber(text.target.value))}
                        style={styles.textField}
                    />
                    <div style={{ height: currentHeight * 0.02 }} />
                    <Input
                        value={sortCodeData}
                        id="outlined"
                        label="Sort Code"
                        inputProps={{
                            autoCapitalize: 'none',
                        }}
                        variant="outlined" 
                        onChange={(text) => isNaN(Number(text.target.value)) === true ? null : text.target.value.length === 7 ? null : dispatch(sortCode(text.target.value))}
                        style={styles.textField}
                    />
                </div>
            :
            <div style={styles.centre}>
                <DesktopInput
                    value={accountNumberData}
                    id="outlined"
                    label="Account Number"
                    inputProps={{
                        autoCapitalize: 'none',
                    }}
                    variant="outlined" 
                    onChange={(text) => isNaN(Number(text.target.value)) === true ? null : text.target.value.length === 9 ? null : dispatch(accountNumber(text.target.value))}
                    style={styles.textField}
                />
                <div style={{ height: currentHeight * 0.02 }} />
                <DesktopInput
                    value={sortCodeData}
                    id="outlined"
                    label="Sort Code"
                    inputProps={{
                        autoCapitalize: 'none',
                    }}
                    variant="outlined" 
                    onChange={(text) => isNaN(Number(text.target.value)) === true ? null : text.target.value.length === 7 ? null : dispatch(sortCode(text.target.value))}
                    style={styles.textField}
                />
            </div>
            }
            <div style={{ height: currentHeight * 0.03 }} />
            {
                isMobile === true ?
                <div style={styles.centre}>
                    <DefaultButton
                        onClick={() => saveCard()}
                    >
                        Save it
                    </DefaultButton>
                </div>
                :
                <div style={styles.centre}>
                    <DesktopDefaultButton
                        onClick={() => saveCard()}
                    >
                        Save it
                    </DesktopDefaultButton>
                </div>
            }
            <div style={{ height: currentHeight * 0.05 }} />
            <p
                onClick={() => history.push('../account')}
                style={{ fontSize: '1.125em', color: colour.primaryMuted, fontWeight: '500', fontFamily: fontFamily, textAlign: 'center', cursor: 'pointer' }}
            >
                Cancel
            </p>
            <div style={{ height: currentHeight * 0.05 }} />
            <p
                onClick={() => history.push('./usdAccount')}
                style={{ fontWeight: '500', color: colour.accentMuted, fontSize: '1.125em', fontFamily: fontFamily, cursor: 'pointer', textAlign: 'center', textDecorationLine: 'underline' }}
            >
                I want to use a USD Account instead {'>'}
            </p>
            <div style={{ height: currentHeight * 0.05 }} />
        </div>
    )
}

export default BankAccountInfo;