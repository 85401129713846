import React, { useEffect, useCallback } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import CardSection from './CardSection';
import { clientSecret, userId, stripeCustomerId, sources, userName, disabledCardButton, profileNewQuestions, userPicture, askedQuestionsList, profileUserId, sharedQuestionURL } from './actions';
import { useSelector, useDispatch } from 'react-redux';
import * as firebase from 'firebase/app';
import '@firebase/firestore';
import { useHistory } from "react-router-dom";
import { QuidvidButton, DesktopQuidvidButton } from './GlobalStyles';
import { isMobile } from 'react-device-detect';

export default function OpenQuestionCardPayForm() {
  const stripe = useStripe();
  const elements = useElements();
  const customerId = useSelector(state => state.stripeCustomerId);
  const currentClientSecret = useSelector(state => state.clientSecret);
  const currentUserId = useSelector(state => state.userId);
  const currentSources = useSelector(state => state.sources);
  const currentUserName = useSelector(state => state.userName);
  //const currency = useSelector(state => state.paymentCurrency);
  const currentHeight = useSelector(state => state.height);
  const disabledButton = useSelector(state => state.disabledCardButton);
  const newQuestion = useSelector(state => state.newQuestionText);
  const anonymousState = useSelector(state => state.anonymous);
  const currentUserPicture = useSelector(state => state.userPicture);
  const currentProfileUserId = useSelector(state => state.profileUserId);
  const askedQuestions = useSelector(state => state.askedQuestionsList);
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, []);
  let history = useHistory();
  const currentSharedQuestions = useSelector(state => state.sharedQuestions);
  const currentStripeCustomerId = useSelector(state => state.stripeCustomerId);
  const questionerName = useSelector(state => state.questioner);

  if ( currentClientSecret !== "" ) {
    dispatch(disabledCardButton(false));
  }

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        stableDispatch(userId(user.uid));

        if ( currentProfileUserId !== "" ) {
          firebase.firestore().collection("Creator Profile").doc(currentProfileUserId)
          .onSnapshot(snapshot => {
            if (!snapshot.exists) {
              console.log("No such document!")
            }
            else {
              const data = snapshot.data();

              if ( data.newQuestions !== undefined ) {
                const newQuestionsData = data.newQuestions;
                stableDispatch(profileNewQuestions(newQuestionsData));
              }
              
            }
          })
        }

        firebase.firestore().collection("Creator Profile").doc(user.uid)
        .onSnapshot(snapshot => {
          if (!snapshot.exists) {
            console.log("No such document!")
          }
          else {
            const data = snapshot.data();

            if ( data.newQuestions !== undefined ) {
              const newQuestionsData = data.newQuestions;
              stableDispatch(askedQuestionsList(newQuestionsData));
            }
            if ( data.userName !== undefined ) {
              const userNameData = data.userName;
              stableDispatch(userName(userNameData));
            }
            if ( data.photoURL !== undefined ) {
              const userPhoto = data.photoURL;
              stableDispatch(userPicture(userPhoto));
            }
          }
        })

        firebase.firestore().collection("Stripe_Customers").doc(user.uid).collection("Success").doc("Success")
        .onSnapshot(snapshot => {
          if (!snapshot.exists) {
            console.log("No such document!")
          }
          else {
            const data = snapshot.data();
            const paymentIntent = data.paymentIntent;
            const status = paymentIntent.status;
            
            if ( status === "succeeded" ) {
                alert("Succeeded");
                firebase.firestore().collection("Stripe_Customers").doc(user.uid).collection("Success").doc("Success").delete();
            }
            else if ( status === "error" ){
              alert(paymentIntent.message);
              firebase.firestore().collection("Stripe_Customers").doc(user.uid).collection("Success").doc("Success").delete();
            }
            else {
              alert("Oopp..something went wrong :(");
              firebase.firestore().collection("Stripe_Customers").doc(user.uid).collection("Success").doc("Success").delete();
            }
          }
        })

        firebase.firestore().collection("Stripe_Customers").doc(user.uid)
        .onSnapshot(snapshot => {
          if (!snapshot.exists) {
            console.log("No such document!")
          }
          else {
            const data = snapshot.data();
            const customerId = data.customer_id;
            const currentSources = data.sources;
            stableDispatch(stripeCustomerId(customerId));
            stableDispatch(sources(currentSources));
            setupIntent(user.uid, customerId);
          }
        })

        firebase.firestore().collection("Stripe_Customers").doc(user.uid).collection("Intent").doc("Intent")
        .onSnapshot(snapshot => {
          if (!snapshot.exists) {
            console.log("No such document!")
          }
          else {
            const data = snapshot.data();

            if ( data.intent !== undefined ) {
              const intent = data.intent;
              const currentClientSecret = intent.client_secret;
              stableDispatch(clientSecret(currentClientSecret));
            }
          }
        })
      }
      else {
        console.log("Not Logged in")
      }
    })
}, [stableDispatch, currentProfileUserId])

function setupIntent(user, id) {
  firebase.firestore().collection("Stripe_Customers").doc(user).collection("SetupIntent").doc("SetupIntent").set({
    customerId: id
  })
  firebase.firestore().collection("Stripe_Customers").doc(user).collection("SetupIntent").doc("SetupIntent").delete();
}

  const handleSubmit = async (event) => {
    if ( currentClientSecret !== "" ) {
        dispatch(disabledCardButton(true));
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
        }

        const result = await stripe.confirmCardSetup(currentClientSecret, {
        payment_method: {
            card: elements.getElement(CardElement),
            billing_details: {
            name: currentUserName,
            },
        }
        });

        if (result.error) {
          console.log("error =", result.error);
          dispatch(disabledCardButton(false));

          if ( result.error.code === "card_declined" ) {
            alert("Oopps, your card was declined :( Please try again");

            setupIntent(currentUserId, customerId);
          }
          else if ( result.error.code === "incorrect_number" ) {
            alert("Oopps, your card number was incorrect :( Please try again");

            setupIntent(currentUserId, customerId);
          }
          else if ( result.error.code === "incomplete_number" ) {
            alert("Oopps, your card number was incomplete :( Please try again");

            setupIntent(currentUserId, customerId);
          }
          else if ( result.error.code === "incomplete_expiry" ) {
            alert("Oopps, your expiry date was incomplete :( Please try again");

            setupIntent(currentUserId, customerId);
          }
          else if ( result.error.code === "incomplete_cvc" ) {
            alert("Oopps, your cvc was incomplete :( Please try again");

            setupIntent(currentUserId, customerId);
          }
          else {
            alert("Oopps, there was an error :( Please try again");

            setupIntent(currentUserId, customerId);
          }
        } 
        else {
            stripe.createToken(elements.getElement(CardElement))
            .then((tokenResult) => {
              const card = tokenResult.token.card;
              const last4 = card.last4;
              const brand = card.brand;
              const expiryMonth = card.exp_month;
              const expiryYear = card.exp_year;

              firebase.firestore().collection("Stripe_Customers").doc(currentUserId).collection("Intent").doc("Intent").delete();

              const sources = currentSources;
              const newSource = {
                                  paymentMethod: result.setupIntent.payment_method,
                                  brand: brand,
                                  last4: last4,
                                  expiryMonth: expiryMonth,
                                  expiryYear: expiryYear
                                };
              sources.unshift(newSource);
  
              firebase.firestore().collection("Stripe_Customers").doc(currentUserId).set({
                  sources
              }, {merge: true})
              makeId(15, result)
          })
          .catch((err) => {
            console.log("token error =", err)
          })
      // The setup has succeeded. Display a success message and send
      // result.setupIntent.payment_method to your server to save the
      // card to a Customer
        }
    }   
    else {
        alert("empty client secret");
    }
  };

  function makeId(length, result) {
    var id           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
       id += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    const askedQuestionsIdIndex = currentSharedQuestions.map((e) => { return e.id; }).indexOf(id);

    if ( askedQuestionsIdIndex === -1 ) {
        saveQuestion(result, id)
    }
    else {
        makeId(length, result);
    }
}

  function saveQuestion(result, id) {
    dispatch(disabledCardButton(true));
        const question = {
            question: newQuestion,
            userName: anonymousState === true ? 'anonymous' : questionerName,
            paymentMethod: result.setupIntent.payment_method,
            customerId: currentStripeCustomerId,
            userImage: currentUserPicture,
            userId: currentUserId,
            date: new Date(),
            id: id,
            credit: false
        }
        
        const sharedQuestions = currentSharedQuestions;
        sharedQuestions.push(question);

        firebase.firestore().collection("Shared Questions").doc("Data").set({
            sharedQuestions
        }, {merge: true})
        
        const newAskedQuestion = {
            question: newQuestion,
            paymentMethod: result.setupIntent.payment_method,
            currentStripeCustomerId: currentStripeCustomerId,
            date: new Date(),
            id: id,
            credit: false
        }
        askedQuestions.push(newAskedQuestion)

        firebase.firestore().collection("Creator Profile").doc(currentUserId).set({
            askedQuestions
        }, {merge: true})

        dispatch(sharedQuestionURL(id));
        dispatch(profileUserId(""));
        dispatch(profileNewQuestions([]));
        history.push('./openQuestionShare');
        dispatch(disabledCardButton(false));
  }
  
  const styles = {
    centre: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center'
    }
  }

  return (
    <div>
      <form>
        <CardSection />
        <div style={{ height: currentHeight * 0.02 }} />
        <div style={styles.centre}>
          {
            isMobile === true ?
            <QuidvidButton
              disabled={disabledButton}
              onClick={(event) => handleSubmit(event)}
            >
              Ask my question for £1
            </QuidvidButton>
            :
            <DesktopQuidvidButton
              disabled={disabledButton}
              onClick={(event) => handleSubmit(event)}
            >
              Ask my question for £1
            </DesktopQuidvidButton>
          }
        </div>
      </form>
    </div>
  );
}