import React, { useRef, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isMobile } from 'react-device-detect';
import Header from './Header';
import Footer from './Footer';
import FooterDesktop from './FooterDesktop';
import { useHistory } from 'react-router-dom';
import { colour, fontFamily, ActionButton, DesktopActionButton } from './GlobalStyles';
import * as firebase from 'firebase/app';
import '@firebase/firestore';
import { videoUpload , auth, userId, userIndex, userList } from './actions';
import Lottie from 'react-lottie-player';
import lottieJson from './loading-animation.json';

function UploadProfilePic() {

    const textInput = useRef(null);
    const dispatch = useDispatch();
    const stableDispatch = useCallback(dispatch, []);
    const currentHeight = useSelector(state => state.height);
    const currentWidth = useSelector(state => state.width);
    const currentUserId = useSelector(state => state.userId);
    const userListData = useSelector(state => state.userList);
    const loading = useSelector(state => state.videoUpload);
    const currentUserIndex = useSelector(state => state.userIndex);
    let history = useHistory();

    useEffect(() => {
        firebase.auth().onAuthStateChanged(user => {
            if (user) {
                stableDispatch(auth(true));
                stableDispatch(userId(user.uid));

                firebase.firestore().collection("Users").doc("List")
                .onSnapshot(snapshot => {
                    if (!snapshot.exists) {
                        console.log('No such document!')
                    }
                    else {
                        const data = snapshot.data();
                        const users = data.users;
                        const currentUserIndex = users.map((e) => { return e.userId; }).indexOf(user.uid);
                        stableDispatch(userList(users));
                        stableDispatch(userIndex(currentUserIndex));
                    }
                })
            }
            else {
                stableDispatch(auth(false));
                stableDispatch(userId(""));
                history.push('./');
            }
        })
    }, [stableDispatch, history])

    function uploadData() {
        textInput.current.click();
    }

    const onChangeFile = async (event) => {
        event.stopPropagation();
        event.preventDefault();
        const file = URL.createObjectURL(event.target.files[0]);
        const type = event.target.files[0].type;

        if ( type.includes("image") ) {
            submitImage(file)
        }
        else {
            alert("Please select an image ;)")
        }
    }

    const submitImage = async (file) => {
        const code = "profileImage";
        const response = await fetch(file);
        const blob = await response.blob();
    
        const ref = firebase.storage().ref(`Creator Images/${currentUserId}`).child(code);
        const uploadTask = ref.put(blob);
        new Promise((resolve, reject) => {
            uploadTask.on(
                'state_changed',
                (snapshot) => {
                const progress = snapshot.bytesTransferred / snapshot.totalBytes * 100;
                console.log('Upload is ' + progress + '% done');
                dispatch(videoUpload(progress));
                },
                (error) => {
                reject(error);  // added this line
                console.log(error)
                },
                () => {
                queryImage(code);
                resolve();  // added this line
                }
            );
            });
      }
    
      const queryImage = (imageName) => {
    
        const creatorImages = firebase.storage().ref(`Creator Images/${currentUserId}`).child(imageName);   
        creatorImages.getDownloadURL()
            .then((url) => {
                              const image = url;
                              
                              firebase.firestore().collection("Creator Profile").doc(currentUserId).set({
                                photoURL: image
                              }, {merge: true})

                              const db = firebase.firestore();
                              const userDocRef = db.collection("Users").doc("List");
                  
                              db.runTransaction((transaction) => {
                                  return transaction.get(userDocRef).then((doc) => {
                                      if (!doc.exists) {
                                          console.log("Document does not exist!");
                                      }
                                      const users = doc.data().users;
                                      const userIndex = users.map((e) => { return e.userId; }).indexOf(currentUserId);
                                      users[userIndex].photoURL = image;
                  
                                      transaction.update(userDocRef, 
                                          { 
                                              users
                                          });
                                      return users;
                                    
                                  });
                            }).then(() => {
                                console.log('Photo uploaded successfully');
                                dispatch(videoUpload(""));
                            }).catch((err) => {
                                console.error(err);
                                dispatch(videoUpload(""));
                            });
                            })
            .catch(err => {
                            console.log('Error getting creator image', err);
                            dispatch(videoUpload(""));
                        })
    }

    const styles = {
        centre: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        },
        upload: {
            width: currentWidth * 0.2,
            cursor: 'pointer'
        },
        loading: {
            widht: currentWidth * 0.3,
            position: 'absolute',
            zIndex: 1
        }
    }

    console.log("userListData =", userListData)
    console.log("currentUserIndex =", currentUserIndex);

    return (
        <div>
            <Header />
            <div style={styles.centre}>
                <h1 style={{ fontFamily: fontFamily, color: colour.dark, fontSize: isMobile === true ? '1.375em' : 60, fontWeight: 'bold', textAlign: 'center' }}>
                    Profile Picture
                </h1>
                <div style={{ height: currentHeight * 0.02 }} />
                <div>
                    <input type='file' id='file' ref={textInput} style={{ display: 'none' }} onChange={onChangeFile} />
                </div>
                {
                    loading === "" ?
                    null
                    :
                    <Lottie
                        loop
                        animationData={lottieJson}
                        play
                        style={styles.loading}
                    />
                }
                <img
                    src={userListData.length === 0 ? require('./upload.svg') : currentUserIndex === -1 ? require('./upload.svg') : currentUserIndex === 0 ? require('./upload.svg') : currentUserIndex === null ? require('./upload.svg') : userListData[currentUserIndex].photoURL === "" ? require('./upload.svg') : userListData[currentUserIndex].photoURL}
                    style={styles.upload}
                    alt=""
                    onClick={() => uploadData()}
                />
                <div style={{ height: currentHeight * 0.02 }} />
                {
                    userListData.length === 0 ?
                    <div>
                        <p style={{ fontFamily: fontFamily, color: colour.med, fontSize: '1em', textAlign: 'center', fontWeight: 'lighter' }}>
                            Upload
                        </p>
                        <div style={{ height: currentHeight * 0.05 }} />
                        <p 
                            onClick={() => history.push('./shouldAddPayment')}
                            style={{ cursor: 'pointer', color: colour.primaryMuted, fontFamily: fontFamily, fontSize: '1.125em', textAlign: 'center' }}
                        >
                            I can't be arsed right now
                        </p>
                    </div>
                    :
                    currentUserIndex === 0 ?
                    <div>
                        <p style={{ fontFamily: fontFamily, color: colour.med, fontSize: '1em', textAlign: 'center', fontWeight: 'lighter' }}>
                            Upload
                        </p>
                        <div style={{ height: currentHeight * 0.05 }} />
                        <p 
                            onClick={() => history.push('./shouldAddPayment')}
                            style={{ cursor: 'pointer', color: colour.primaryMuted, fontFamily: fontFamily, fontSize: '1.125em', textAlign: 'center' }}
                        >
                            I can't be arsed right now
                        </p>
                    </div>
                    :
                    currentUserIndex === -1 ?
                    <div>
                        <p style={{ fontFamily: fontFamily, color: colour.med, fontSize: '1em', textAlign: 'center', fontWeight: 'lighter' }}>
                            Upload
                        </p>
                        <div style={{ height: currentHeight * 0.05 }} />
                        <p 
                            onClick={() => history.push('./shouldAddPayment')}
                            style={{ cursor: 'pointer', color: colour.primaryMuted, fontFamily: fontFamily, fontSize: '1.125em', textAlign: 'center' }}
                        >
                            I can't be arsed right now
                        </p>
                    </div>
                    :
                    userListData[currentUserIndex].photoURL === "" ?
                    <div>
                        <p style={{ fontFamily: fontFamily, color: colour.med, fontSize: '1em', textAlign: 'center', fontWeight: 'lighter' }}>
                            Upload
                        </p>
                        <div style={{ height: currentHeight * 0.05 }} />
                        <p 
                            onClick={() => history.push('./shouldAddPayment')}
                            style={{ cursor: 'pointer', color: colour.primaryMuted, fontFamily: fontFamily, fontSize: '1.125em', textAlign: 'center' }}
                        >
                            I can't be arsed right now
                        </p>
                    </div>
                    :
                    isMobile === true ?
                    <ActionButton
                        onClick={() => history.push('./shouldAddPayment')}
                    >
                        Next
                    </ActionButton>
                    :
                    <DesktopActionButton
                        onClick={() => history.push('./shouldAddPayment')}
                    >
                        Next
                    </DesktopActionButton>
                }
                {
                    isMobile === true ?
                    <div style={{ height: currentHeight * 0.2 }} />
                    :
                    null
                }
            </div>
            {
                isMobile === true ?
                <Footer/>
                :
                <FooterDesktop/>
            }
        </div>
    )
}

export default UploadProfilePic;