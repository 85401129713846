import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { width, height } from './actions';
import { useHistory } from "react-router-dom";
import Header from './Header';
import { DefaultButton, colour, fontFamily, DesktopDefaultButton } from './GlobalStyles';
import * as firebase from 'firebase/app';
import '@firebase/firestore';
import { isMobile } from 'react-device-detect';
import Footer from './Footer';
import FooterDesktop from './FooterDesktop'

function ShouldAddPayment() {

    const dispatch = useDispatch();
    const currentWidth = useSelector(state => state.width);
    const currentHeight = useSelector(state => state.height);
    let history = useHistory();
    const stableDispatch = useCallback(dispatch, [])

    useEffect(() => {
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
            }
            else {
                history.push('../home');
            }
        })

        function resizeWindow () {
            const newWidth = window.innerWidth;
            const newHeight = window.innerHeight;
            stableDispatch(width(newWidth));
            stableDispatch(height(newHeight));
          };

        window.addEventListener("resize", resizeWindow);
        return () => window.removeEventListener("resize", resizeWindow);
      }, [stableDispatch, history]);


    const styles = {
        centre: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        },
        title: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: currentWidth * 0.8
        },
        header: {
            paddingLeft: currentWidth * 0.05
        },
        heading: {
            display: 'flex',
            flexDirection: 'column',
            paddingLeft: currentWidth * 0.1,
            width: currentWidth * 0.8
        },
        desktopContainer: {
            width: currentWidth * 0.4,
            paddingLeft: currentWidth * 0.1
        },
        row: {
            display: 'flex',
            flexDirection: 'row'
        },
        pound: {
            width: currentWidth * 0.1,
            position: 'absolute',
            left: currentWidth * 0.4,
            top: currentHeight * 0.25,
            zIndex: -1
        }
    }

    return (
        <div>
            <Header />
            <div style={{ height: currentHeight * 0.1 }} />
            {
                isMobile === true ?
                <div style={styles.centre}>
                    <div style={styles.title}>
                        <h3 style={{ color: colour.dark, textAlign: 'center', fontWeight: 'bold', fontSize: '1.375', fontFamily: fontFamily }}>
                            Well done!
                            Shall we add a payment method now?
                        </h3>
                    </div>
                    <div style={{ height: currentHeight * 0.05 }} />
                    <div style={styles.centre}>
                        <DefaultButton
                            onClick={() => history.push('./AddPaymentMethod')}
                        >
                            Sure, why not
                        </DefaultButton>
                    </div>
                    <div style={{ height: currentHeight * 0.05 }} />
                    <div style={styles.centre}>
                        <p 
                            style={{ cursor: 'pointer', color: colour.primaryMuted, textAlign: 'center', fontWeight: '500', fontSize: '1.125', fontFamily: fontFamily }}
                            onClick={() => history.push('./home')}
                        >
                            I can't be arsed right now
                        </p>
                    </div>
                </div>
                :
                <div style={styles.desktopContainer}>
                    <img
                        src={require('./pound-moving-right.svg')}
                        style={styles.pound}
                        alt=""
                    />
                    <h1 style={{ fontFamily: fontFamily, color: colour.dark, fontSize: '70', fontWeight: 'bold' }}>
                        Well done!
                        Should we add a
                        payment method now?
                    </h1>
                    <div style={{ height: currentHeight * 0.05 }} />
                    <DesktopDefaultButton
                        onClick={() => history.push('./AddPaymentMethod')}
                    >
                        Sure, why not
                    </DesktopDefaultButton>
                    <div style={{ height: currentHeight * 0.05 }} />
                    <div style={styles.centre}>
                        <p 
                            style={{ cursor: 'pointer', color: colour.primaryMuted, textAlign: 'center', fontWeight: '500', fontSize: '1.125', fontFamily: fontFamily }}
                            onClick={() => history.push('./home')}
                        >
                            I can't be arsed right now
                        </p>
                    </div>
                </div>
            }
            {
                isMobile === true ?
                <Footer />
                :
                <FooterDesktop />
            }
        </div>
    )
}

export default ShouldAddPayment;