import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import Header from './Header';
import { fontFamily, colour } from './GlobalStyles';
import { auth, userId } from './actions';
import { useSelector, useDispatch } from 'react-redux';
import * as firebase from 'firebase/app';
import '@firebase/firestore';
import { isMobile } from "react-device-detect";
import NavBar from './NavBar';
import NavBarDesktop from './NavBarDesktop';

function Account() {

    const currentHeight = useSelector(state => state.height);
    const currentWidth = useSelector(state => state.width);
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        firebase.auth().onAuthStateChanged( user => {
            if (user) {
            }
            else {
                history.push('../home');
            }
        })
    })

  function logout() {
      firebase.auth().signOut();
      dispatch(userId(""));
      dispatch(auth(false));
  }

    const styles = {
        centre: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        },
        container: {
            width: currentWidth * 0.8
        },
        title: {
            paddingLeft: currentWidth * 0.1,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
        },
        secondTitleMobile: {
            paddingLeft: currentWidth * 0.1,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start'
        },
        logoutIcon: {
            width: isMobile === true ? currentWidth * 0.08 : currentWidth * 0.02,
            cursor: 'pointer'
        },
        logout: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center'
        },
        icon: {
            width: isMobile === true ? currentWidth * 0.1 : currentWidth * 0.02
        },
        navbar: {
            position: 'fixed', 
            zIndex: 1,
            bottom: 0, 
            left: 0,
            opacity: 1
        },
    }

    return(
        <div>
            <Header/>
            <div style={styles.centre}>
                <h1 style={{ fontWeight: 'bold', fontSize: '1.375em', fontFamily: fontFamily, color: colour.dark, textAlign: 'center' }}>
                    Account
                </h1>
            </div>
            <div style={{ height: currentHeight * 0.05 }} />
            <div style={styles.container}>
                <div style={styles.title}>
                    <img
                        src={require('./profile-info.svg')}
                        style={styles.icon}
                        alt=""
                    />
                    <div style={{ width: isMobile === true ? currentWidth * 0.03 : currentWidth * 0.01 }} />
                    <h2 
                        onClick={() => history.push('./accountInformation')}
                        style={{ fontWeight: 'bold', fontSize: '1.125em', fontFamily: fontFamily, color: colour.dark, cursor: 'pointer' }}
                    >
                        My Info
                    </h2>
                </div>
                <div style={{ height: currentHeight * 0.03 }} />
                <div style={isMobile === true ? styles.secondTitleMobile : styles.title}>
                    <img
                        src={require('./pound-black.svg')}
                        style={styles.icon}
                        alt=""
                    />
                    <div style={{ width: isMobile === true ? currentWidth * 0.03 : currentWidth * 0.01 }} />
                    <h2 
                        onClick={() => history.push('./transactions')}
                        style={{ fontWeight: 'bold', fontSize: '1.125em', fontFamily: fontFamily, color: colour.dark, cursor: 'pointer' }}
                    >
                        Payment {'&'} Transaction History
                    </h2>
                </div>
            </div>
            <div style={{ height: currentHeight * 0.09 }} />
            <div style={styles.container}>
                <div style={styles.title}>
                    <h2 
                        onClick={() => history.push('./reportBug')}
                        style={{ fontWeight: 'bold', fontSize: '1.125em', fontFamily: fontFamily, color: colour.primaryMuted, cursor: 'pointer' }}
                    >
                        Get Help/Report a Bug
                    </h2>
                </div>
                <div style={{ height: currentHeight * 0.02 }} />
                <div 
                    style={styles.title}
                >
                    <h2 
                        onClick={() => history.push('./secret')}
                        style={{ fontWeight: 'bold', fontSize: '1.125em', fontFamily: fontFamily, color: colour.accentMuted, cursor: 'pointer' }}>
                        Tell us a Secret
                    </h2>
                </div>
                <div style={{ height: currentHeight * 0.02 }} />
                <div 
                    style={styles.title}
                >
                    <h2 
                        onClick={() => history.push('./hate')}
                        style={{ fontWeight: 'bold', fontSize: '1.125em', fontFamily: fontFamily, color: colour.primaryMuted, cursor: 'pointer' }}
                    >
                        Proclaim Your Hate
                    </h2>
                </div>
            </div>
            <div style={{ height: currentHeight * 0.05 }} />
            <div 
                style={styles.logout}
            >
                <img
                    src={require('./logout.svg')}
                    style={styles.logoutIcon}
                    alt="logoutIcon"
                    onClick={() => logout()}
                />
                <div style={{ width: currentWidth * 0.01 }} />
                 <h2 
                    onClick={() => logout()}
                    style={{ fontWeight: 'bold', fontSize: '1.125em', fontFamily: fontFamily, color: colour.med, cursor: 'pointer' }}
                >
                    Logout
                </h2>
            </div>
            <div style={{ height: currentHeight * 0.02 }} />
            {
                isMobile === true ?
                <div style={styles.navbar}>
                    <NavBar/>
                </div>
                :
                <NavBarDesktop />
            }
            <div style={{ height: currentHeight * 0.15 }} />
        </div>
    )
}

export default Account;