import React, { useEffect, useCallback } from 'react';
import Header from './Header';
import { fontFamily, colour, Input, DefaultButton, DesktopDefaultButton, DesktopInput, SmallDefaultButton, UnselectedDefaultButton, DesktopSmallDefaultButton, DesktopUnselectedDefaultButton } from './GlobalStyles';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { usdAccountNumber, usdRoutingNumber, completeName, streetAddress, zipCode, usState, usAccountType } from './actions';
import { isMobile } from 'react-device-detect';
import * as firebase from 'firebase/app';
import '@firebase/firestore';

function UsdAccount() {

    const currentHeight = useSelector(state => state.height);
    const currentWidth = useSelector(state => state.width);
    const accountNumberData = useSelector(state => state.usdAccountNumber);
    const routingNumberData = useSelector(state => state.usdRoutingNumber);
    const currentCompleteName = useSelector(state => state.completeName);
    const currentStreetAddress = useSelector(state => state.streetAddress);
    const currentZipCode = useSelector(state => state.zipCode)  
    const currentUsState = useSelector(state => state.usState);
    const currentUsAccountType = useSelector(state => state.usAccountType);
    let history = useHistory();
    const dispatch = useDispatch();
    const stableDispatch = useCallback(dispatch, []);
    
    useEffect(() => {
        firebase.auth().onAuthStateChanged( user => {
            if (user) {

                const accountDetails = firebase.firestore().collection("Creator Profile").doc(user.uid);
                accountDetails.get()
                .then(doc => {
                    if (!doc.exists) {
                        console.log('No such document!');
                    }
                    else {
                        const data = doc.data();
                        if ( data.usdAccountNumber !== undefined ) {
                            const dbUsdAccountNumber = data.usdAccountNumber;
                            stableDispatch(usdAccountNumber(dbUsdAccountNumber));
                        }
                        if ( data.usdRoutingNumber !== undefined ) {
                            const dbUsdRoutingNumber = data.usdRoutingNumber;
                            stableDispatch(usdRoutingNumber(dbUsdRoutingNumber));
                        }
                        if ( data.completeName !== undefined ) {
                            const dbCompleteName = data.completeName;
                            stableDispatch(completeName(dbCompleteName));
                        }
                        if ( data.streetAddress !== undefined ) {
                            const dbStreetAddress = data.streetAddress;
                            stableDispatch(streetAddress(dbStreetAddress));
                        }
                        if ( data.zipCode !== undefined ) {
                            const dbZipCode = data.zipCode;
                            stableDispatch(zipCode(dbZipCode));
                        }
                        if ( data.usState !== undefined ) {
                            const dbUsState = data.usState;
                            stableDispatch(usState(dbUsState));
                        }
                        if ( data.usAccountType !== undefined ) {
                            const dbUsAccountType = data.usAccountType;
                            stableDispatch(usAccountType(dbUsAccountType));
                        }
                    }
                })
                .catch((err) => {
                    console.log("Error =", err);
                })
            }
            else {
                history.push('./');
            }
        })
    }, [stableDispatch, history])

    function save() {

        if ( 
                accountNumberData !== "" &&  
                routingNumberData !== "" &&
                currentCompleteName !== "" &&
                currentStreetAddress !== "" &&
                currentZipCode !== "" &&
                currentUsState !== ""
            ) {
            firebase.auth().onAuthStateChanged( user => {
                if (user) {

                    firebase.firestore().collection("Creator Profile").doc(user.uid).update({
                        usdAccountNumber: accountNumberData,
                        usdRoutingNumber: routingNumberData,
                        completeName: currentCompleteName,
                        streetAddress: currentStreetAddress,
                        zipCode: currentZipCode,
                        usState: currentUsState,
                        usDetails: true,
                        usAccountType: currentUsAccountType
                    })
                }
            })

            alert("Bank Account Details saved :)");
            history.push('./account');
        }
        else {
            alert("Oopps..please check that you filled in all fields")
        }
    }

    const styles = {
        centre: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        },
        payoutText: {
            width: isMobile === true ? currentWidth * 0.9 : currentWidth * 0.4
        },
        row: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center'
        }
    }

    return (
        <div>
            <Header />
            <div style={styles.centre}>
                <h1 style={{ fontWeight: 'bold', fontSize: '1.375em', fontFamily: fontFamily, color: colour.dark, textAlign: 'center' }}>
                    USD Account Info
                </h1>
            </div>
            <div style={{ height: currentHeight * 0.05 }} />
            <div style={styles.centre}>
                <div style={styles.payoutText}>
                    <p style={{ color: colour.med, fontWeight: 'bold', fontFamily: fontFamily, fontSize: '0.875em' }}>
                        Payouts occur Monthly. 
                        <p style={{ fontWeight: 'normal' }}>
                            Your balance must be at 
                            least £10 before fees in order to receive payout, 
                            otherwise it will be pushed to the following month.    
                        </p>
                    </p>
                </div>
            </div>
            <div style={{ height: currentHeight * 0.02 }} />
            {
                currentUsAccountType === 'checking' && isMobile === false ?
                <div style={styles.row}>
                    <DesktopSmallDefaultButton
                        onClick={() => dispatch(usAccountType("checking"))}
                    >
                        Checking
                    </DesktopSmallDefaultButton>
                    <div style={{ width: currentWidth * 0.02 }} />
                    <DesktopUnselectedDefaultButton
                        onClick={() => dispatch(usAccountType("savings"))}
                    >
                        Savings
                    </DesktopUnselectedDefaultButton>
                </div>
                :
                currentUsAccountType === 'savings' && isMobile === false ?
                <div style={styles.row}>
                    <DesktopUnselectedDefaultButton
                        onClick={() => dispatch(usAccountType("checking"))}
                    >
                        Checking
                    </DesktopUnselectedDefaultButton>
                    <div style={{ width: currentWidth * 0.02 }} />
                    <DesktopSmallDefaultButton
                        onClick={() => dispatch(usAccountType("savings"))}
                    >
                        Savings
                    </DesktopSmallDefaultButton>
                </div>
                :
                currentUsAccountType === 'checking' && isMobile === true ?
                <div style={styles.row}>
                    <SmallDefaultButton
                        onClick={() => dispatch(usAccountType("checking"))}
                    >
                        Checking
                    </SmallDefaultButton>
                    <div style={{ width: currentWidth * 0.02 }} />
                    <UnselectedDefaultButton
                        onClick={() => dispatch(usAccountType("savings"))}
                    >
                        Savings
                    </UnselectedDefaultButton>
                </div>
                :
                currentUsAccountType === 'savings' && isMobile === true ?
                <div style={styles.row}>
                    <UnselectedDefaultButton
                        onClick={() => dispatch(usAccountType("checking"))}
                    >
                        Checking
                    </UnselectedDefaultButton>
                    <div style={{ width: currentWidth * 0.02 }} />
                    <SmallDefaultButton
                        onClick={() => dispatch(usAccountType("savings"))}
                    >
                        Savings
                    </SmallDefaultButton>
                </div>
                :
                null
            }
            <div style={{ height: currentHeight * 0.02 }} />
            {
                isMobile === true ?
                <div style={styles.centre}>
                    <Input
                        value={accountNumberData}
                        id="outlined"
                        label="Account Number"
                        inputProps={{
                            autoCapitalize: 'none',
                        }}
                        variant="outlined" 
                        onChange={(text) => dispatch(usdAccountNumber(text.target.value))}
                        style={styles.textField}
                    />
                    <div style={{ height: currentHeight * 0.02 }} />
                    <Input
                        value={routingNumberData}
                        id="outlined"
                        label="Routing Number"
                        inputProps={{
                            autoCapitalize: 'none',
                        }}
                        variant="outlined" 
                        onChange={(text) => dispatch(usdRoutingNumber(text.target.value))}
                        style={styles.textField}
                    />
                    <div style={{ height: currentHeight * 0.02 }} />
                    <p style={{ color: colour.primaryMuted, fontSize: '0.875em', fontWeight: 'lighter', fontFamily: fontFamily, textAlign: 'center' }}>
                        (USD accounts require a billing address)
                    </p>
                    <div style={{ height: currentHeight * 0.02 }} />
                    <Input
                        value={currentCompleteName}
                        id="outlined"
                        label={`First & Last Name`}
                        inputProps={{
                            autoCapitalize: 'none',
                        }}
                        variant="outlined" 
                        onChange={(text) => dispatch(completeName(text.target.value))}
                        style={styles.textField}
                    />
                    <div style={{ height: currentHeight * 0.02 }} />
                    <Input
                        value={currentStreetAddress}
                        id="outlined"
                        label="Street Address"
                        inputProps={{
                            autoCapitalize: 'none',
                        }}
                        variant="outlined" 
                        onChange={(text) => dispatch(streetAddress(text.target.value))}
                        style={styles.textField}
                    />
                    <div style={{ height: currentHeight * 0.02 }} />
                    <Input
                        value={currentZipCode}
                        id="outlined"
                        label="Zip Code"
                        inputProps={{
                            autoCapitalize: 'none',
                        }}
                        variant="outlined" 
                        onChange={(text) => dispatch(zipCode(text.target.value))}
                        style={styles.textField}
                    />
                    <div style={{ height: currentHeight * 0.02 }} />
                    <Input
                        value={currentUsState}
                        id="outlined"
                        label="State"
                        inputProps={{
                            autoCapitalize: 'none',
                        }}
                        variant="outlined" 
                        onChange={(text) => dispatch(usState(text.target.value))}
                        style={styles.textField}
                    />
                    <div style={{ height: currentHeight * 0.05 }} />
                </div>
                :
                <div style={styles.centre}>
                <DesktopInput
                    value={accountNumberData}
                    id="outlined"
                    label="Account Number"
                    inputProps={{
                        autoCapitalize: 'none',
                    }}
                    variant="outlined" 
                    onChange={(text) => dispatch(usdAccountNumber(text.target.value))}
                    style={styles.textField}
                />
                <div style={{ height: currentHeight * 0.02 }} />
                <DesktopInput
                    value={routingNumberData}
                    id="outlined"
                    label="Routing Number"
                    inputProps={{
                        autoCapitalize: 'none',
                    }}
                    variant="outlined" 
                    onChange={(text) => dispatch(usdRoutingNumber(text.target.value))}
                    style={styles.textField}
                />
                <div style={{ height: currentHeight * 0.02 }} />
                <p style={{ color: colour.primaryMuted, fontSize: '0.875em', fontWeight: 'lighter', fontFamily: fontFamily, textAlign: 'center' }}>
                    (USD accounts require a billing address)
                </p>
                <div style={{ height: currentHeight * 0.02 }} />
                <DesktopInput
                    value={currentCompleteName}
                    id="outlined"
                    label={`First & Last Name`}
                    inputProps={{
                        autoCapitalize: 'none',
                    }}
                    variant="outlined" 
                    onChange={(text) => dispatch(completeName(text.target.value))}
                    style={styles.textField}
                />
                <div style={{ height: currentHeight * 0.02 }} />
                <DesktopInput
                    value={currentStreetAddress}
                    id="outlined"
                    label="Street Address"
                    inputProps={{
                        autoCapitalize: 'none',
                    }}
                    variant="outlined" 
                    onChange={(text) => dispatch(streetAddress(text.target.value))}
                    style={styles.textField}
                />
                <div style={{ height: currentHeight * 0.02 }} />
                <DesktopInput
                    value={currentZipCode}
                    id="outlined"
                    label="Zip Code"
                    inputProps={{
                        autoCapitalize: 'none',
                    }}
                    variant="outlined" 
                    onChange={(text) => dispatch(zipCode(text.target.value))}
                    style={styles.textField}
                />
                <div style={{ height: currentHeight * 0.02 }} />
                <DesktopInput
                    value={currentUsState}
                    id="outlined"
                    label="State"
                    inputProps={{
                        autoCapitalize: 'none',
                    }}
                    variant="outlined" 
                    onChange={(text) => dispatch(usState(text.target.value))}
                    style={styles.textField}
                />
                <div style={{ height: currentHeight * 0.05 }} />
            </div>
            }
            <div style={styles.centre}>
                {
                    isMobile === true ?
                    <DefaultButton
                        onClick={() => save()}
                    >
                        Save
                    </DefaultButton>
                    :
                    <DesktopDefaultButton
                        onClick={() => save()}
                    >
                        Save
                    </DesktopDefaultButton>
                }
                <div style={{ height: currentHeight * 0.03 }} />
                <p
                    style={{ fontWeight: '500', color: colour.primaryMuted, fontFamily: fontFamily, fontSize: '1.125em', cursor: 'pointer' }}
                    onClick={() => history.goBack()}
                >
                    Cancel
                </p>
            </div>
            <div style={{ height: currentHeight * 0.05 }} />
        </div>
    )
}

export default UsdAccount;