import React from 'react';
import Header from './Header';
import QuestionBanner from './QuestionBanner';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { colour, fontFamily, DefaultButton, DesktopDefaultButton } from './GlobalStyles';
import { isMobile } from 'react-device-detect';
import OpenQuestionCardPayForm from './OpenQuestionCardPayForm';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { disabledCardButton, profileUserId, profileNewQuestions, sharedQuestionURL } from './actions';
import * as firebase from 'firebase/app';
import '@firebase/firestore';
const stripePromise = loadStripe("pk_live_2mEru9mdQwdhFZRVB88jAe8C00EX61FoXX");

function OpenQuestionPay() {

    const currentWidth = useSelector(state => state.width);
    const currentHeight = useSelector(state => state.height);
    const newQuestion = useSelector(state => state.newQuestionText);
    const anonymousState = useSelector(state => state.anonymous);
    const questionerName = useSelector(state => state.questioner);
    const currentQuestionCredits = useSelector(state => state.questionCredits);
    const sources = useSelector(state => state.sources);
    const currentSharedQuestions = useSelector(state => state.sharedQuestions);
    const currentUserId = useSelector(state => state.userId);
    const currentStripeCustomerId = useSelector(state => state.stripeCustomerId);
    const currentUserPicture = useSelector(state => state.userPicture);
    const askedQuestions = useSelector(state => state.askedQuestionsList);
    let history = useHistory();
    const dispatch = useDispatch();

    if ( newQuestion === "" ) {
        history.push('./openQuestion');
    }

    function makeId(length, type) {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
           result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        const askedQuestionsIdIndex = currentSharedQuestions.map((e) => { return e.id; }).indexOf(result);

        if ( askedQuestionsIdIndex === -1 ) {
            if ( type === 'pay' ) {
                pay(result);
            }
            else if ( type === 'free' ) {
                free(result);
            }
        }
        else {
            makeId(length, type);
        }
    }

    function pay(id) {
        dispatch(disabledCardButton(true));
        const question = {
            question: newQuestion,
            userName: anonymousState === true ? 'anonymous' : questionerName,
            paymentMethod: sources[0].paymentMethod,
            customerId: currentStripeCustomerId,
            userImage: currentUserPicture,
            userId: currentUserId,
            date: new Date(),
            id: id,
            credit: false
        }
        
        const sharedQuestions = currentSharedQuestions;
        sharedQuestions.push(question);

        firebase.firestore().collection("Shared Questions").doc("Data").set({
            sharedQuestions
        }, {merge: true})
        
        const newAskedQuestion = {
            question: newQuestion,
            paymentMethod: sources[0].paymentMethod,
            currentStripeCustomerId: currentStripeCustomerId,
            date: new Date(),
            id: id,
            credit: false
        }
        askedQuestions.push(newAskedQuestion)

        firebase.firestore().collection("Creator Profile").doc(currentUserId).set({
            askedQuestions
        }, {merge: true})

        dispatch(sharedQuestionURL(id));
        dispatch(profileUserId(""));
        dispatch(profileNewQuestions([]));
        history.push('./openQuestionShare');
        dispatch(disabledCardButton(false));
    }

    function free(id) {
        dispatch(disabledCardButton(true));
        const question = {
            question: newQuestion,
            userName: anonymousState === true ? 'anonymous' : questionerName,
            userImage: currentUserPicture,
            userId: currentUserId,
            date: new Date(),
            id: id,
            credit: true
        }
        
        const sharedQuestions = currentSharedQuestions;
        sharedQuestions.push(question);

        firebase.firestore().collection("Shared Questions").doc("Data").set({
            sharedQuestions
        }, {merge: true})
        
        const newAskedQuestion = {
            question: newQuestion,
            date: new Date(),
            id: id,
            credit: true
        }
        askedQuestions.push(newAskedQuestion)

        firebase.firestore().collection("Creator Profile").doc(currentUserId).set({
            askedQuestions,
            questionCredits: currentQuestionCredits - 1
        }, {merge: true})

        dispatch(sharedQuestionURL(id));
        dispatch(profileUserId(""));
        dispatch(profileNewQuestions([]));
        history.push('./openQuestionShare');
        dispatch(disabledCardButton(false));
    }

    const styles = {
        centre: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        },
        justChecking: {
            paddingLeft: isMobile === true ? currentWidth * 0.1 : currentWidth * 0.3
        }
    }

    return (
        <div>
            <Header />
            <QuestionBanner
                width={currentWidth}
                height={currentHeight}
                question={newQuestion}
                anonymous={anonymousState}
                questionerName={questionerName}
                creatorUserName='openQuestion'
            />
            <div style={{ height: currentHeight * 0.1 }} />
            {
                currentQuestionCredits === 0 && sources.length === 0 ?
                <Elements stripe={stripePromise}>
                    <OpenQuestionCardPayForm />
                </Elements>
                :
                currentQuestionCredits === 0 && sources.length !== 0 ?
                <div>
                    <div style={styles.justChecking}>
                        <h1 style={{ color: colour.dark, fontFamily: fontFamily, fontSize: '1.375em', fontWeight: 'bold' }}>
                            Just checking...
                        </h1>
                    </div>
                    <div style={{ height: currentHeight * 0.05 }} />
                    <div style={styles.centre}>
                        <p style={{ color: colour.med, fontSize: '1.125em', fontWeight: 'bold', fontFamily: fontFamily }}>
                            {sources[0].brand} ending in {sources[0].last4}
                        </p>
                        <div style={{ height: currentHeight * 0.1 }} />
                        {
                            isMobile === true ?
                            <DefaultButton
                                onClick={() => makeId(15, 'pay')}
                            >
                                Generate Question Link for £1
                            </DefaultButton>
                            :
                            <DesktopDefaultButton
                                onClick={() => makeId(15, 'pay')}
                            >
                                Generate Question Link for £1
                            </DesktopDefaultButton>
                        }
                    </div>
                </div>
                :
                <div>
                    <div style={styles.justChecking}>
                        <h1 style={{ color: colour.dark, fontFamily: fontFamily, fontSize: '1.375em', fontWeight: 'bold' }}>
                            Just checking...
                        </h1>
                    </div>
                    <div style={{ height: currentHeight * 0.05 }} />
                    <div style={styles.centre}>
                        <p style={{ color: colour.med, fontSize: '1.125em', fontWeight: 'bold', fontFamily: fontFamily }}>
                            {currentQuestionCredits} Credits Remaining
                        </p>
                        <div style={{ height: currentHeight * 0.1 }} />
                        {
                            isMobile === true ?
                            <DefaultButton
                                onClick={() => makeId(15, 'free')}
                            >
                                Generate Question Link
                            </DefaultButton>
                            :
                            <DesktopDefaultButton
                                onClick={() => makeId(15, 'free')}
                            >
                                Generate Question Link
                            </DesktopDefaultButton>
                        }
                    </div>
                </div>
            }
        </div>
    )
}

export default OpenQuestionPay;