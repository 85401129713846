import React from 'react';
import { useHistory } from "react-router-dom";
import { QuidvidButton, DesktopQuidvidButton } from './GlobalStyles';
import { isMobile } from 'react-device-detect';

  function VidForQuid({ currentHeight }) {

    let history = useHistory();

    const styles = {
        centre: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        },
    }

    function navigate() {
        history.push('../question');
    }

    return (
        <div style={styles.centre}>
            <div style={{ height: currentHeight * 0.05 }} />
            {
                isMobile === true ?
                <QuidvidButton
                    onClick={() => navigate()}
                >
                    vid for quid
                </QuidvidButton>
                :
                <DesktopQuidvidButton
                    onClick={() => navigate()}
                >
                    vid for quid
                </DesktopQuidvidButton>
            }
       </div>
    )
  }

  export default VidForQuid;