import React, { useEffect, useCallback } from "react";
import "./Feed.css";
import { width, height, feedData, bottomFeed } from "./actions";
import { useSelector, useDispatch } from 'react-redux';
import * as firebase from 'firebase/app';
import '@firebase/firestore';
import Video from './Video';
import { isMobile } from 'react-device-detect';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';

function Feed() {

    const feed = useSelector(state => state.feedData);
    const currentBottomFeed = useSelector(state => state.bottomFeed);
    const dispatch = useDispatch();
    const stableDispatch = useCallback(dispatch, []);
    const scrollRef = useBottomScrollListener(() => callback());

    function callback() {
        const feedLength = feed.length;

        if ( currentBottomFeed + 5 > feedLength ) {
            dispatch(bottomFeed(feedLength));
        }
        else {
            dispatch(bottomFeed(currentBottomFeed + 5));
        }
    }

    useEffect(() => {
        const feedDataQuery = firebase.firestore().collection("Feed").doc("Data")
        feedDataQuery.get()
        .then((doc) => {
            if (!doc.exists) {
                console.log("No such document!")
            }
            else {
                const data = doc.data();
                const feedEntries = data.feed;
                stableDispatch(feedData(feedEntries)); 
            }
        })
        .catch((error) => {
            console.log("Error getting document:", error);
        });

        function resizeWindow () {
            const newWidth = window.innerWidth;
            const newHeight = window.innerHeight;
            stableDispatch(width(newWidth));
            stableDispatch(height(newHeight));
        };

        window.addEventListener("resize", resizeWindow);
        return () => window.removeEventListener("resize", resizeWindow);
    }, [stableDispatch]);

    const smallerArray = feed.slice(0, currentBottomFeed);
  return (
        <div className={isMobile === true ? "quidvidViewer" : ""}>
            <div ref={scrollRef} className={isMobile === true ? "quidvidViewer__cards" : "quidvidViewer__cardsDesktop"}>
                {
                smallerArray.map((item, index) =>
                    <Video
                        item={item}
                        index={index}
                        length={feed.length}
                    />
                )}
            </div>
        </div>
  )
}

export default Feed;