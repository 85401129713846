import React, { useEffect, useCallback } from 'react';
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { width, height, newQuestionText, anonymous, viewingProfileName } from './actions';
import { isMobile } from "react-device-detect";
import { colour, fontFamily, ActionButton } from './GlobalStyles';
import NavBar from './NavBar';
import Header from './Header';
import NavBarDesktop from './NavBarDesktop';

function QuestionConfirmed() {

    const dispatch = useDispatch();
    const currentHeight = useSelector(state => state.height);
    const currentWidth = useSelector(state => state.width);
    const viewingProfileUserName = useSelector(state => state.viewingProfileName);
    const viewingProfileUserPicture = useSelector(state => state.viewingProfileUserPicture);
    const anonymousState = useSelector(state => state.anonymous);
    const currentUserName = useSelector(state => state.userName);
    const newQuestion = useSelector(state => state.newQuestionText);
    const stableDispatch = useCallback(dispatch, []);
    let history = useHistory();

    useEffect(() => {
        if ( newQuestion === "" ) {
            history.push('./home');
        }

        function resizeWindow () {
            const newWidth = window.innerWidth;
            const newHeight = window.innerHeight;
            stableDispatch(width(newWidth));
            stableDispatch(height(newHeight));
        };
        window.addEventListener("resize", resizeWindow);
        return () => window.removeEventListener("resize", resizeWindow);
    }, [history, stableDispatch, newQuestion]);

    function backToAction() {
        dispatch(anonymous(false));
        dispatch(viewingProfileName(""));
        dispatch(newQuestionText(""));
        history.push('./home');
    }

      const styles = {
          centre: {
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center'
          },
          width: {
              width: currentWidth * 0.8, 
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center'
          },
          container: {
            border: `1px solid ${colour.primary}`,
            borderRadius: '30px',
            width: currentWidth * 0.8,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          },
          innerContainer: {
            width: currentWidth * 0.6
          },
          row: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center'
          },
          creatorImage: {
            width: currentWidth * 0.05,
            height: currentWidth * 0.05,
            borderRadius: currentWidth * 0.025,
          },
          questioner: {
              paddingLeft: isMobile === false ? currentWidth * 0.5 : currentWidth * 0.1
          },
          navbar: {
            position: 'fixed', 
            zIndex: 1,
            bottom: 0, 
            left: currentWidth * 0.1,
            opacity: 1
        }
      }

      return (
        <div>
            <Header/>
                <div style={styles.centre}>
                    <div style={styles.width}>
                        <div>
                            <h3 style={{ textAlign: 'center', color: colour.dark, fontSize: '1.375em', fontFamily: fontFamily, fontWeight: 'bold' }}>
                                The ball is in their court...
                            </h3>
                            <div style={{ height: currentHeight * 0.03 }} />
                            <p style={{ textAlign: 'center', color: colour.dark, fontFamily: fontFamily, fontSize: '1.125em', fontWeight: 'lighter' }}>
                                They have 48 hours to respond or they lose the quid and you keep it
                            </p>
                        </div>
                        <div style={{ height: currentHeight * 0.05 }} />
                        <div style={styles.container}>
                            <div style={styles.innerContainer}>
                                <div style={{ height: currentHeight * 0.05 }} />
                                <p style={{ fontWeight: '500', fontSize: '0.875em', textAlign: 'center', fontFamily: fontFamily, color: colour.med }}>
                                    Quidvid request sent to
                                </p>
                                <div style={{ height: currentHeight * 0.05 }} />
                                <div style={styles.row}>
                                    <img
                                        src={viewingProfileUserPicture === "" ? require('./anonymous.svg') : viewingProfileUserPicture}
                                        style={styles.creatorImage}
                                        alt="creatorImage"
                                    />
                                    <div style={{ width: currentWidth * 0.01 }} />
                                    <p style={{ textAlign: 'center', color: colour.primaryMuted, fontSize: '1.125em', fontFamily: fontFamily, fontWeight: '500' }}>
                                        @{viewingProfileUserName}
                                    </p>
                                </div>
                                <div style={{ height: currentHeight * 0.05 }} />
                                <p style={{ textAlign: 'center', color: colour.dark, fontSize: '1.125em', fontFamily: fontFamily, fontWeight: 'bold' }}>
                                    {newQuestion}
                                </p>
                                <div style={{ height: currentHeight * 0.05 }} />
                                <div style={styles.questioner}>
                                    <p style={{ textAlign: 'center', color: colour.med, fontSize: '1.125em', fontFamily: fontFamily, fontWeight: 'bold' }}>
                                        - {anonymousState === true ? 'anonymous' : currentUserName}
                                    </p>
                                </div>
                                <div style={{ height: currentHeight * 0.05 }} />
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ height: currentHeight * 0.05 }} />
                <div style={styles.centre}>
                    <ActionButton
                        onClick={() => backToAction()}
                    >
                        Back to the action
                    </ActionButton>
                </div>
                <div style={{ height: currentHeight * 0.2 }} />
                {
                    isMobile === true ?
                    <div style={styles.navbar}>
                        <NavBar/>
                    </div>
                    :
                    <NavBarDesktop />
                }
                
          </div>
      )
}

export default QuestionConfirmed;