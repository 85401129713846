import React, { useEffect, useCallback } from 'react';
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { colour, fontFamily } from './GlobalStyles';
import { auth, userId, userName } from './actions';
import * as firebase from 'firebase/app';
import '@firebase/firestore';

function NavBar() {

    const currentWidth = useSelector(state => state.width);
    const currentHeight = useSelector(state => state.height);
    const authState = useSelector(state => state.auth);
    const currentUserName = useSelector(state => state.userName);
    const dispatch = useDispatch();
    const stableDispatch = useCallback(dispatch, []);
    let history = useHistory();
    const path = window.location.pathname;

    useEffect(() => {
        firebase.auth().onAuthStateChanged(user => {
          if (user) {
              stableDispatch(auth(true));
              stableDispatch(userId(user.uid));

              firebase.firestore().collection("Creator Profile").doc(user.uid)
              .onSnapshot(snapshot => {
                  if (!snapshot.exists) {
                      console.log('No such document!')
                  }
                  else {
                      const data = snapshot.data();
                      const name = data.userName;
                      stableDispatch(userName(name));
                  }
              })
          }
        })
    }, [stableDispatch])

    const styles = {
        container: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            alignItems: 'center',
            paddingBottom: currentHeight * 0.01,
            backgroundColor: 'rgba(255,255,255,0.5)',
            width: currentWidth
        },
        iconContainer: {
            width: currentWidth * 0.15,
            height: currentWidth * 0.15,
            borderRadius: currentWidth * 0.075,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        },
        icon: {
            width: currentWidth * 0.1,
            cursor: 'pointer'
        }
    }

    return (
        <div style={styles.container}>
            <div 
                style={styles.iconContainer}
                onClick={() => history.push('../home')}
            >
                <img
                    src={path === "/home" ? require('./feed-active.svg') : require('./feed-inactive.svg')}
                    style={styles.icon}
                    alt=""
                    onClick={() => history.push('../home')}
                />
                {
                    path === "/home" ?
                    <p style={{ color: colour.dark, fontSize: '0.75em', fontWeight: 'bold', fontFamily: fontFamily, textAlign: 'center'  }}>
                        FEED
                    </p>
                    :
                    <p style={{ color: colour.light  }}>
                        
                    </p>
                }
            </div>
            <div 
                style={styles.iconContainer}
                onClick={() => history.push('../openQuestion')}
            >
                <img
                    src={path === "/openQuestion" ? require('./ask-active.svg') : require('./ask-inactive.svg')}
                    style={styles.icon}
                    alt=""
                    onClick={() => history.push('../openQuestion')}
                />
                {
                    path === "/openQuestion" ?
                    <p style={{ color: colour.dark, fontSize: '0.75em', fontWeight: 'bold', fontFamily: fontFamily, textAlign: 'center'  }}>
                        ASK
                    </p>
                    :
                    <p style={{ color: colour.light  }}>
                        
                    </p>
                }
            </div>
            <div 
                style={styles.iconContainer}
                onClick={() => authState === true ? history.push(`../profile/${currentUserName}`) : history.push('../login')}
            >
                <img
                    src={path === `/profile/${currentUserName}` ? require('./profile-active.svg') : require('./profile-inactive.svg')}
                    style={styles.icon}
                    alt=""
                    onClick={() => authState === true ? history.push(`../profile/${currentUserName}`) : history.push('../login')}
                />
                {
                    path === `/profile/${currentUserName}` ?
                    <p style={{ color: colour.dark, fontSize: '0.75em', fontWeight: 'bold', fontFamily: fontFamily, textAlign: 'center'  }}>
                        ME
                    </p>
                    :
                    <p style={{ color: colour.light  }}>
                        
                    </p>
                }
            </div>
        </div>
    )
}

export default NavBar;