import React, { useRef, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isAndroid } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import { colour, fontFamily } from './GlobalStyles';
import VideoRecorder from 'react-video-recorder';
import { newVideoBlob, videoUpload, userId, answeredQuestions, feedData, userPicture, userName, totalQuidvids, totalEarnings } from './actions';
import * as firebase from 'firebase/app';
import '@firebase/firestore';

function OpenQuestionRecoardAnswer() {

    const questionShareActive = useSelector(state => state.questionShareActive);
    const currentWidth = useSelector(state => state.width);
    const currentHeight = useSelector(state => state.height);
    const currentVideoBlob = useSelector(state => state.newVideoBlob);
    const currentUserId = useSelector(state => state.userId);
    const answers = useSelector(state => state.answeredQuestions);
    const currentFeedData = useSelector(state => state.feedData);
    const sharedQuestions = useSelector(state => state.sharedQuestions);
    const sharedQuestionIndex = useSelector(state => state.sharedQuestionIndex);
    const currentUserPicture = useSelector(state => state.userPicture);
    const currentUserName = useSelector(state => state.userName);
    const totalQuidvidsData = useSelector(state => state.totalQuidvids);
    const currentTotalEarnings = useSelector(state => state.totalEarnings);
    const currency = useSelector(state => state.paymentCurrency);
    let history = useHistory();
    const dispatch = useDispatch();
    const stableDispatch = useCallback(dispatch, []);
    const videoRef = useRef(null);

    if ( questionShareActive === false ) {
        history.push('./home');
    }

    useEffect(() => {

        firebase.firestore().collection("Feed").doc("Data")
        .onSnapshot(snapshot => {
            if (!snapshot.exists) {
                console.log('No such document!');
            }
            else {
                const data = snapshot.data();
                const feed = data.feed;
                stableDispatch(feedData(feed));
            }
        })

        firebase.auth().onAuthStateChanged(user => {
            if (user) {
                stableDispatch(userId(user.uid));

                firebase.firestore().collection("Creator Profile").doc(user.uid)
                .onSnapshot(snapshot => {
                    if (!snapshot.exists) {
                        console.log('No such document!');
                    }
                    else {
                        const data = snapshot.data();

                        if ( data.userName !== undefined ) {
                            const name = data.userName;
                            stableDispatch(userName(name));
                        }
                        if ( data.photoURL !== undefined ) {
                            const photo = data.photoURL;
                            stableDispatch(userPicture(photo));
                        }
                        if ( data.answers !== undefined ) {
                            const questions = data.answers;
                            stableDispatch(answeredQuestions(questions));
                        }
                        if ( data.totalQuidvids !== undefined ) {
                            const totalQuidvidsNumber = data.totalQuidvids;
                            stableDispatch(totalQuidvids(totalQuidvidsNumber));
                        }
                        if ( data.totalEarnings !== undefined ) {
                            const totalEarningsNumber = data.totalEarnings;
                            stableDispatch(totalEarnings(totalEarningsNumber));
                        }
                    }
                })
            }
            else {

            }
        })
    }, [stableDispatch])

    function uploadData() {
        textInput.current.click();

    }

    const textInput = useRef(null);

    const onChange = async (event) => {
        event.stopPropagation();
        event.preventDefault();
        const file = URL.createObjectURL(event.target.files[0]);
        const type = event.target.files[0].type;
        
        if ( type.includes("video") && currentUserId === "" ) {
            const response = await fetch(file);
            const blob = await response.blob();
            dispatch(newVideoBlob(blob));
            history.push('./recordSignUp');
        }
        else if ( type.includes("video") && currentUserId !== "" ) {
            const code = Math.random().toString(36).substring(7);
            const response = await fetch(file);
            const blob = await response.blob();
        
            const ref = firebase.storage().ref(`Creator Videos/${currentUserId}`).child(code);
            const uploadTask = ref.put(blob);
            new Promise((resolve, reject) => {
                uploadTask.on(
                    'state_changed',
                    (snapshot) => {
                    const progress = snapshot.bytesTransferred / snapshot.totalBytes * 100;
                    console.log('Upload is ' + progress + '% done');
                    dispatch(videoUpload(progress));
                    },
                    (error) => {
                    reject(error);  // added this line
                    console.log(error)
                    },
                    () => {
                    queryVideo(code);
                    resolve();  // added this line
                    }
                );
            });
        }
    }

    function record() {
        if ( isAndroid === true ) {
            uploadData();
        }
        else {
            videoRef.current.handleOpenVideoInput();
        }
    }

    const submitVideo = async () => {
        
        if ( currentUserId === "" ) {
            history.push('./recordSignUp');
        }
        else {
            const code = Math.random().toString(36).substring(7);
            const blob = currentVideoBlob
        
            const ref = firebase.storage().ref(`Creator Videos/${currentUserId}`).child(code);
            const uploadTask = ref.put(blob);
            new Promise((resolve, reject) => {
                uploadTask.on(
                    'state_changed',
                    (snapshot) => {
                    const progress = snapshot.bytesTransferred / snapshot.totalBytes * 100;
                    console.log('Upload is ' + progress + '% done');
                    dispatch(videoUpload(progress));
                    },
                    (error) => {
                    reject(error);  // added this line
                    console.log(error)
                    },
                    () => {
                    queryVideo(code);
                    resolve();  // added this line
                    }
                );
            });
        }     
    }

    const queryVideo = (imageName) => {
    
        const creatorVideos = firebase.storage().ref(`Creator Videos/${currentUserId}`).child(imageName);   
        creatorVideos.getDownloadURL()
            .then((url) => {
                                makeId(15, url);
                            })
            .catch(err => {
                            console.log('Error getting creator video', err);
                            dispatch(videoUpload(""));
                            dispatch(newVideoBlob(""));
                        })
    }

    function makeId(length, url) {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
           result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }

        const resultIndex = currentFeedData.map((e) => { return e.code; }).indexOf(result);
        if ( resultIndex !== -1 ) {
            makeId(length, url);
        }
        else if ( sharedQuestions[sharedQuestionIndex].credit === true ) {
            freeQuestion(url, result);
        }
        else {
            charge(url, result);
        }
    }

    function freeQuestion(url, result) {
        dispatch(videoUpload(""));
                    alert("Free question answered!");

                    const db = firebase.firestore();
                    const sharedQuestionsRef = db.collection("Shared Questions").doc("Data");
                    db.runTransaction((transaction) => {
                        return transaction.get(sharedQuestionsRef).then((doc) => {
                            if (!doc.exists) {
                                console.log("Document does not exist!");
                            }
                            const newSharedQuestions = doc.data().sharedQuestions;
                            const questionIndex = newSharedQuestions.map((e) => { return e.id; }).indexOf(sharedQuestions[sharedQuestionIndex].id);
                            console.log("question Index =", questionIndex)
                            newSharedQuestions.splice(questionIndex, 1);
                            console.log("newSharedQuestions =", newSharedQuestions)
                            transaction.update(sharedQuestionsRef, 
                                { 
                                    sharedQuestions: newSharedQuestions
                                });
                            return newSharedQuestions;
                          
                        });
                    }).then(() => {
                        console.log('free splice transaction complete');
                    }).catch((err) => {
                        console.error(err);
                    });

                    const feedRef = db.collection("Feed").doc("Data");
        
                    db.runTransaction((transaction) => {
                        return transaction.get(feedRef).then((doc) => {
                            if (!doc.exists) {
                                console.log("Document does not exist!");
                            }
                            const feed = doc.data().feed;
                            const newFeedPost = {
                                creatorImage: currentUserPicture,
                                creatorUserId: currentUserId,
                                creatorName: currentUserName,
                                creatorVideo: url,
                                totalQuidvids: totalQuidvidsData + 1,
                                userName: sharedQuestions[sharedQuestionIndex].userName,
                                userPhoto: sharedQuestions[sharedQuestionIndex].userImage,
                                userQuestion: sharedQuestions[sharedQuestionIndex].question,
                                userUserId: sharedQuestions[sharedQuestionIndex].userId,
                                code: result
                            }
                            feed.unshift(newFeedPost);
                            
                            transaction.update(feedRef, 
                                { 
                                    feed
                                });
                            return feed;
                          
                        });
                    }).then(() => {
                        console.log("Promise compiled with success");

                        const newAnswer = {
                            date: new Date(),
                            question: sharedQuestions[sharedQuestionIndex].question,
                            questioner: sharedQuestions[sharedQuestionIndex].userName,
                            questionerImage: sharedQuestions[sharedQuestionIndex].userImage,
                            questionerUserId: sharedQuestions[sharedQuestionIndex].userId,
                            creatorVideo: url,
                            code: result
                        }
                        answers.unshift(newAnswer);
    
                        firebase.firestore().collection("Creator Profile").doc(currentUserId).set({
                            answers,
                            totalQuidvids: totalQuidvidsData + 1,
                            totalEarnings: currentTotalEarnings
                        }, {merge: true})
                        dispatch(videoUpload(""));
                        dispatch(newVideoBlob(""));
                        history.push('./home');
                    }).catch((err) => {
                        console.error(err);
                    });
    }

    function charge(url, result) {

        if ( sharedQuestions[sharedQuestionIndex].paymentMethod !== undefined && sharedQuestions[sharedQuestionIndex].customerId !== undefined ) {
            dispatch(videoUpload("charging card"));
            firebase.firestore().collection("Stripe_Customers").doc(currentUserId).collection("Charges").doc("Recent").set({
                amount: 100,
                customerId: sharedQuestions[sharedQuestionIndex].customerId,
                paymentMethod: sharedQuestions[sharedQuestionIndex].paymentMethod,
                currency: currency
            })
  
            firebase.firestore().collection("Stripe_Customers").doc(currentUserId).collection("Charges").doc("Recent").delete();

            firebase.firestore().collection("Stripe_Customers").doc(currentUserId).collection("Success").doc("Success")
            .onSnapshot(snapshot => {
              if (!snapshot.exists) {
                console.log("No such document!")
              }
              else {
                const data = snapshot.data();
                const paymentIntent = data.paymentIntent;
                const status = paymentIntent.status;
                
                if ( status === "succeeded" ) {
                    dispatch(videoUpload(""));
                    alert("You just earned £1!");
                    firebase.firestore().collection("Stripe_Customers").doc(currentUserId).collection("Success").doc("Success").delete();   

                    const db = firebase.firestore();
                    const sharedQuestionsRef = db.collection("Shared Questions").doc("Data");
                    db.runTransaction((transaction) => {
                        return transaction.get(sharedQuestionsRef).then((doc) => {
                            if (!doc.exists) {
                                console.log("Document does not exist!");
                            }
                            const newSharedQuestions = doc.data().sharedQuestions;
                            const questionIndex = newSharedQuestions.map((e) => { return e.id; }).indexOf(sharedQuestions[sharedQuestionIndex].id);
                            
                            newSharedQuestions.splice(questionIndex, 1);
                            transaction.update(sharedQuestionsRef, 
                                { 
                                    sharedQuestions: newSharedQuestions
                                });
                            return newSharedQuestions;
                          
                        });
                    }).then(() => {
                        console.log('paid splice transaction complete');
                    }).catch((err) => {
                        console.error(err);
                    });

                    const feedRef = db.collection("Feed").doc("Data");
        
                    db.runTransaction((transaction) => {
                        return transaction.get(feedRef).then((doc) => {
                            if (!doc.exists) {
                                console.log("Document does not exist!");
                            }
                            
                            const feed = doc.data().feed;
                            const newFeedPost = {
                                creatorImage: currentUserPicture,
                                creatorUserId: currentUserId,
                                creatorName: currentUserName,
                                creatorVideo: url,
                                totalQuidvids: totalQuidvidsData + 1,
                                userName: sharedQuestions[sharedQuestionIndex].userName,
                                userPhoto: sharedQuestions[sharedQuestionIndex].userImage,
                                userQuestion: sharedQuestions[sharedQuestionIndex].question,
                                userUserId: sharedQuestions[sharedQuestionIndex].userId,
                                code: result
                            }
                            feed.unshift(newFeedPost);
                            
                            transaction.update(feedRef, 
                                { 
                                    feed
                                });
                            return feed;
                          
                        });
                    }).then(() => {
                        console.log("Promise compiled with success");

                        const newAnswer = {
                            date: new Date(),
                            question: sharedQuestions[sharedQuestionIndex].question,
                            questioner: sharedQuestions[sharedQuestionIndex].userName,
                            questionerImage: sharedQuestions[sharedQuestionIndex].userImage,
                            questionerUserId: sharedQuestions[sharedQuestionIndex].userId,
                            creatorVideo: url,
                            code: result
                        }
                        answers.unshift(newAnswer);
    
                        firebase.firestore().collection("Creator Profile").doc(currentUserId).set({
                            answers,
                            totalQuidvids: totalQuidvidsData + 1,
                            totalEarnings: currentTotalEarnings + 1
                        }, {merge: true})
                        dispatch(videoUpload(""));
                        dispatch(newVideoBlob(""));
                        history.push('./home');
                    }).catch((err) => {
                        console.error(err);
                    });
                }
                else if ( status === "error" ){
                    dispatch(videoUpload(""));
                    alert("Oopps, we're sorry..your customer's card was declined. We have deleted their question, and you will be navigated back to your profile :(");
                    firebase.firestore().collection("Stripe_Customers").doc(currentUserId).collection("Success").doc("Success").delete();

                    history.push('./home');
                }
                else {
                    dispatch(videoUpload(""));
                    alert("Oopps, we're sorry..your customer's card was declined. We have deleted their question, and you will be navigated back to your profile :(");
                    firebase.firestore().collection("Stripe_Customers").doc(currentUserId).collection("Success").doc("Success").delete();

                    history.push('./home');
                }
              }
            })
        }
        else {
            alert("Ooppss.. we're sorry but your customer does not have a card on file. Their question has been deleted.");
            history.push('./home');
        }
    }

    function retake() {
        videoRef.current.handleStopRecording();
        videoRef.current.handleOpenVideoInput();
    }

    const styles = {
        video: {
            height: currentHeight, 
            width: currentWidth
        },
        uploadTime: {
            position: 'absolute',
            zIndex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            bottom: currentHeight * 0.4,
            left: 0
        },
        submit: {
            position: 'absolute',
            zIndex: 1,
            bottom: currentHeight * 0.05,
            left: currentWidth * 0.7,
            cursor: 'pointer'
        },
        upload: {
            width: currentWidth * 0.3
        },
        retakeIcon: {
            width: currentWidth * 0.1
        },
        retake: {
            position: 'absolute',
            zIndex: 1,
            bottom: currentHeight * 0.07,
            left: currentWidth * 0.02,
            backgroundColor: colour.dark,
            width: currentWidth * 0.2,
            height: currentWidth * 0.2,
            borderRadius: currentWidth * 0.1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer'
        },
        uploadVideo: {
            position: 'absolute',
            zIndex: 1,
            bottom: currentHeight * 0.02,
            left: currentWidth * 0.02,
            backgroundColor: colour.dark,
            width: currentWidth * 0.2,
            height: currentWidth * 0.2,
            borderRadius: currentWidth * 0.1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer'
        },
        takeVideo: {
            backgroundColor: colour.primary,
            width: currentWidth * 0.4,
            borderRadius: currentWidth * 0.02,
            height: currentHeight * 0.1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            position: 'absolute',
            zIndex: 1,
            bottom: currentHeight * 0.2,
            left: currentWidth * 0.3
        },
        loading: {
            width: currentWidth
        }
    }

    return (
        <div style={styles.video}>
            <div
                ref={el => { console.log(el); videoRef.current = el; }}
            />
            <input type='file' id='file' ref={textInput} style={{ display: 'none' }} onChange={onChange} />   
            <VideoRecorder 
                onRecordingComplete={(videoBlob) => {
                    dispatch(newVideoBlob(videoBlob));
                }}
                style={{ height: currentHeight, width: currentWidth }}
                ref={el => { console.log(el); videoRef.current = el; }}
            />
            {
                currentVideoBlob === "" ?
                <div 
                    style={styles.takeVideo}
                    onClick={() => record()}
                >
                    <p style={{ color: colour.dark, textAlign: 'center', fontWeight: 'bold', fontSize: '1.375', fontFamily: fontFamily }}>
                        Record Video
                    </p>
                </div>
                :
                <div style={styles.submit}>
                    <img
                        src={require('./send-video.svg')}
                        style={styles.upload}
                        alt="upload"
                        onClick={() => submitVideo()}
                    />
                </div>
            }
            {
                currentVideoBlob === "" ?
                <div 
                    onClick={() => uploadData()}
                    style={styles.uploadVideo}>
                    <p 
                        style={{ color: colour.light, fontFamily: fontFamily, fontSize: '0.75em', fontWeight: 'bold', textAlign: 'center' }}
                    >
                        Upload
                    </p>
                </div>
                :
                <div style={styles.retake}>
                    <img
                        src={require('./back.svg')}
                        style={styles.retakeIcon}
                        alt="upload"
                        onClick={() => retake()}
                    />
                    <p style={{ color: colour.light, fontFamily: fontFamily, fontSize: '0.75em', fontWeight: 'bold', textAlign: 'center' }}>
                        Retake
                    </p>
                </div>
            }
        </div>
    )
}

export default OpenQuestionRecoardAnswer;