import React, { useEffect, useCallback } from 'react';
import * as firebase from 'firebase/app';
import '@firebase/firestore';
import { useSelector, useDispatch } from 'react-redux';
import { width, height } from './actions';
import { useHistory } from "react-router-dom";
import SignupComponent from './SignupComponent';
import Header from './Header';
import { fontFamily, colour } from './GlobalStyles';
import { isMobile } from 'react-device-detect';
import Footer from './Footer';
import FooterDesktop from './FooterDesktop';
  
  function RecordSignUp() {

    const currentWidth = useSelector(state => state.width);
    const currentHeight = useSelector(state => state.height);
    const dispatch = useDispatch();
    const stableDispatch = useCallback(dispatch, []);
    let history = useHistory();

    useEffect(() => {

      firebase.auth().onAuthStateChanged( user => {
        if (user) {
          history.push('./');
        }
        else {
        }
      })

      function resizeWindow () {
        const newWidth = window.innerWidth;
        const newHeight = window.innerHeight;
        stableDispatch(width(newWidth));
        stableDispatch(height(newHeight));
      };
      window.addEventListener("resize", resizeWindow);
      return () => window.removeEventListener("resize", resizeWindow);
    }, [history, stableDispatch]);

    const styles = {
        welcome: {
          paddingLeft: currentWidth * 0.05
        },
        header: {
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: currentWidth * 0.9,
          paddingTop: currentHeight * 0.05
        },
    }
      return (
          <div>
            <Header />
            {
              isMobile === true ?
              <div style={styles.welcome}>
                <h3 style={{ fontFamily: fontFamily, color: colour.dark }}>
                  Good choice
                </h3>
              </div>
              :
              null
            }
            <div style={{ height: currentHeight * 0.05 }} />
              <SignupComponent 
                path="openQuestion"
              />
            {
              isMobile === true ?
              <Footer />
              :
              <FooterDesktop />
            }
        </div>
      )
  }

  export default RecordSignUp;