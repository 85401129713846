import React, { useEffect, useCallback } from 'react';
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import * as firebase from 'firebase/app';
import '@firebase/firestore';
import { width, height, questioner, auth, userId, stripeCustomerId, sources, disabledCardButton, questionCredits } from './actions';
import { fontFamily, colour } from './GlobalStyles';
import Header from './Header';

import AskQuestionInput from './AskQuestionInput';

function Question() {

    const currentWidth = useSelector(state => state.width);
    const currentHeight = useSelector(state => state.height);
    const currentProfileUserId = useSelector(state => state.profileUserId);
    const currentProfileUserName = useSelector(state => state.profileUserName);
    const currentviewingProfileUserPicture = useSelector(state => state.viewingProfileUserPicture);
    const dispatch = useDispatch();
    const stableDispatch = useCallback(dispatch, []);
    let history = useHistory();

    useEffect(() => {
        if ( currentProfileUserId === "" ) {
            history.push('./');
        }
        else {
            firebase.auth().onAuthStateChanged(user => {
                if (user) {
                    stableDispatch(auth(true));
                    stableDispatch(userId(user.uid));
            
                    firebase.firestore().collection("Creator Profile").doc(user.uid)
                    .onSnapshot(snapshot => {
                        if (!snapshot.exists) {
                            console.log("No such document!")
                        }
                        else {
                            const data = snapshot.data();
                            const userNameData = data.userName;
                            stableDispatch(questioner(userNameData));

                            if ( data.questionCredits !== undefined ) {
                                const credits = data.questionCredits;
                                stableDispatch(questionCredits(credits));
                            }
                        }
                    })

                    firebase.firestore().collection("Stripe_Customers").doc(user.uid)
                    .onSnapshot(snapshot => {
                        if (!snapshot.exists) {
                            console.log("No such document!")
                        }
                        else {
                            const data = snapshot.data();
                            const customerId = data.customer_id;
                            const currentSources = data.sources;
                            stableDispatch(stripeCustomerId(customerId));
                            stableDispatch(sources(currentSources));
                            stableDispatch(disabledCardButton(false));
                        }
                    })
                }
                else {
                    stableDispatch(auth(false));
                    stableDispatch(userId(""));
                    stableDispatch(questioner(""));
                }
            })
        }

        function resizeWindow () {
            const newWidth = window.innerWidth;
            const newHeight = window.innerHeight;
            stableDispatch(width(newWidth));
            stableDispatch(height(newHeight));
          };
      
        resizeWindow();
        window.addEventListener("resize", resizeWindow);
        return () => window.removeEventListener("resize", resizeWindow);
    }, [stableDispatch, currentProfileUserId, history]);

    const styles = {
        title: {
            paddingLeft: currentWidth * 0.05
        },
        centre: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        },
        row: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
        },
        profileImage: {
            width: currentWidth * 0.05,
            height: currentWidth * 0.05,
            borderRadius: currentWidth * 0.025
        }
    }

    return (
        <div>
            <Header />
            <div style={{ height: currentHeight * 0.05 }} />
            <div style={styles.centre}>
                <h3 style={{ fontSize: '1.375em', fontFamily: fontFamily, color: colour.dark }}>
                    What would you like to ask?
                </h3>
                <div style={styles.row}>
                    <img
                        src={currentviewingProfileUserPicture === "" ? require('./anonymous.svg') : currentviewingProfileUserPicture}
                        style={styles.profileImage}
                        alt=""
                    />
                    <div style={{ height: currentHeight * 0.01 }} />
                    <p style={{ fontSize: '1.125em', fontFamily: fontFamily, color: colour.primaryMuted }}>
                        @{currentProfileUserName}
                    </p>
                </div>
            </div>
            <div style={{ height: currentHeight * 0.05 }} />
           <AskQuestionInput
            openQuestion={false}
           />
           <div style={{ height: currentHeight * 0.05 }} />
        </div>
    )
}

export default Question;