import React from 'react';
import { useHistory } from "react-router-dom";
import Header from './Header';
import { fontFamily, colour } from './GlobalStyles';
import { useSelector } from 'react-redux';

function FAQ() {

    const currentWidth = useSelector(state => state.width);
    const currentHeight = useSelector(state => state.height);
    const history = useHistory();
    const questionAnswer = [
        {
            question: 'Wtf is a quidvid',
            answer: 'A quidvid is a request for an answer to a question in video form. The cost of aforementioned exchange is a quid. And what’s a quid between friends?'
        },
        {
            question: 'Who can send me quidvid requests?',
            answer: 'Anyone who clicks on your profile can send you requests, but they must put forward a quid to send the request'
        },
        {
            question: 'Do I have to answer the question?',
            answer: 'Of course not. (However, you don’t get paid if you decline to respond)'
        },
        {
            question: 'How do I get paid?',
            answer: 'We send a bank transfer every 2 weeks. We are working on giving you more control over that, so hang tight. Thank you for your patience <3'
        },
        {
            question: 'Do I get to keep all of the money?',
            answer: 'We take a small commission to cover the Stripe service charge plus our service fee. The rest is yours. There is no limit to how many quidvids you receive.'
        },
        {
            question: 'Why is my bank transfer different than the total in my profile?',
            answer: 'Your profile displays your all-time gross earnings. Your bank transfer reflects what you made in the previous two weeks, minus the commission.'
        },
        {
            question: 'Why do you save my credit card information?',
            answer: 'To make sure that you only get charged if they answer your question. In order to do this, we must save your card info to your account, but it is encrypted, so even we can’t see it.'
        },
        {
            question: 'When do you charge my card?',
            answer: 'Your card only gets charged when the person submits a video response to the question you asked.'
        },
        {
            question: 'Why are you lot so vulgar?',
            answer: 'Because life is bloody short, that’s why. We never claimed to be good influences, alright?'
        },
        {
            question: 'What is your logo?',
            answer: 'It’s a squid……..get it.. quid…squid ;)'
        }
    ]

    const styles = {
        container: {
            background: colour.gradient,
            paddingTop: currentHeight * 0.02,
            height: currentHeight * 0.2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        },
        width: {
            width: currentWidth * 0.8
        },
        centre: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
        }
    }

    return (
        <div>
            <Header />
            <div style={styles.container}>
                <div style={styles.width}>
                    <h1 style={{ color: colour.dark, fontFamily: fontFamily, fontWeight: 'bold', fontSize: '1.375em', textAlign: 'center' }}>
                        Answers to your perfectly reasonable questions
                    </h1>
                </div>
            </div>
            <div style={{ height: currentHeight * 0.05 }} />
            {
                questionAnswer.map((item, index) =>
                <div style={styles.centre}>
                    <div style={styles.width}>
                        <h2 style={{ color: colour.dark, fontFamily: fontFamily, fontWeight: 'bold', fontSize: '1.125em', textAlign: 'center' }}>
                            {item.question}
                        </h2>
                    </div>
                    <div style={{ height: currentHeight * 0.02 }} />
                    <div style={styles.width}>
                        <h2 style={{ color: colour.med, fontFamily: fontFamily, fontSize: '1em', fontWeight: 'lighter' }}>
                            {item.answer}
                        </h2>
                    </div>
                    <div style={{ height: currentHeight * 0.05 }} />
                </div>
                )
            } 
             <div style={styles.centre}>
                <h1 style={{ color: colour.dark, fontFamily: fontFamily, fontWeight: 'bold', fontSize: '1.375em', textAlign: 'center' }}>
                    Anything Else?
                </h1>
                <div style={{ height: currentHeight * 0.02 }} />
                <h2 
                    onClick={() => history.push('./reportBug')}
                    style={{ fontWeight: 'bold', fontSize: '1.125em', fontFamily: fontFamily, color: colour.primaryMuted, cursor: 'pointer' }}
                >
                    Get Help/Report a Bug
                </h2>
                <div style={{ height: currentHeight * 0.02 }} />
                <div 
                    style={styles.title}
                    onClick={() => history.push('./secret')}
                >
                    <h2 style={{ fontWeight: 'bold', fontSize: '1.125em', fontFamily: fontFamily, color: colour.accentMuted, cursor: 'pointer' }}>
                        Tell us a Secret
                    </h2>
                </div>
                <div style={{ height: currentHeight * 0.02 }} />
                <h2 
                    onClick={() => history.push('./hate')}
                    style={{ fontWeight: 'bold', fontSize: '1.125em', fontFamily: fontFamily, color: colour.primaryMuted, cursor: 'pointer' }}
                >
                    Proclaim Your Hate
                </h2>
            </div>
        </div>
    )
}

export default FAQ;