import React, { useEffect, useCallback } from 'react';
import AskQuestionInput from './AskQuestionInput';
import { useSelector, useDispatch } from 'react-redux';
import Header from './Header';
import { colour, fontFamily } from './GlobalStyles';
import { auth, userId, questionCredits, stripeCustomerId, sources, disabledCardButton, width, height, questioner, userPicture, askedQuestionsList, sharedQuestions } from './actions';
import * as firebase from 'firebase/app';
import '@firebase/firestore';
import NavBar from './NavBar';
import NavBarDesktop from './NavBarDesktop';
import { isMobile } from 'react-device-detect';

function OpenQuestion() {

    const currentHeight = useSelector(state => state.height);
    const dispatch = useDispatch();
    const stableDispatch = useCallback(dispatch, []);

    useEffect(() => {

        const sharedQuestionQuery = firebase.firestore().collection("Shared Questions").doc("Data");
        sharedQuestionQuery.get()
        .then(doc => {
            if(!doc.exists) {
                console.log('No such document!');
            }
            else {
                const data = doc.data();
                const questions = data.sharedQuestions;
                stableDispatch(sharedQuestions(questions));
            }
        })
        .catch(err => {
            console.log("error =", err);
        })

        firebase.auth().onAuthStateChanged( user => {
            if (user) {
                firebase.firestore().collection("Creator Profile").doc(user.uid)
                .onSnapshot(snapshot => {
                    if (!snapshot.exists) {
                        console.log('No such document');
                    }
                    else {
                        const data = snapshot.data();
                        const userNameData = data.userName;
                        stableDispatch(questioner(userNameData));

                        if ( data.photoURL !== undefined ) {
                            const userPhoto = data.photoURL;
                            stableDispatch(userPicture(userPhoto));
                        }

                        if ( data.askedQuestions !== undefined ) {
                            const questions = data.askedQuestions;
                            stableDispatch(askedQuestionsList(questions));
                        }

                        if ( data.questionCredits !== undefined ) {
                            const credits = data.questionCredits;
                            stableDispatch(questionCredits(credits));
                        }
                    }
                })

                firebase.firestore().collection("Stripe_Customers").doc(user.uid)
                .onSnapshot(snapshot => {
                    if (!snapshot.exists) {
                        console.log('No such document');
                    }
                    else {
                        const data = snapshot.data();
                        const customerId = data.customer_id;
                        const currentSources = data.sources;
                        stableDispatch(stripeCustomerId(customerId));
                        stableDispatch(sources(currentSources));
                        stableDispatch(disabledCardButton(false));
                    }
                })
            }
            else {
                stableDispatch(auth(false));
                stableDispatch(userId(""));
            }
        })

        function resizeWindow () {
            const newWidth = window.innerWidth;
            const newHeight = window.innerHeight;
            stableDispatch(width(newWidth));
            stableDispatch(height(newHeight));
          };
    
        window.addEventListener("resize", resizeWindow);
        return () => window.removeEventListener("resize", resizeWindow);
    }, [stableDispatch])

    const styles = {
        centre: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        },
        navbar: {
            position: 'fixed', 
            zIndex: 1,
            bottom: 0, 
            left: 0,
            opacity: 1
        },
    }

    return (
        <div>
            <Header/>
            <div style={styles.centre}>
                <h1 style={{ color: colour.dark, fontFamily: fontFamily, fontSize: '1.375em', textAlign: 'center', fontWeight: 'bold' }}>
                    Ask Anyone Anything
                </h1>
            </div>
            <div style={{ height: currentHeight * 0.05 }} />
            <AskQuestionInput
                openQuestion={true}
            />
            {
                isMobile === true ?
                <div style={styles.navbar}>
                    <NavBar/>
                </div>
                :
                <NavBarDesktop />
            }
        </div>
    )
}

export default OpenQuestion;