import React from 'react';
import { CardElement } from '@stripe/react-stripe-js';
import { fontFamily, colour } from './GlobalStyles';
import './CardSectionStyles.css';
import { isMobile } from 'react-device-detect';

const CARD_ELEMENT_OPTIONS = {
  hidePostalCode: true,
  style: {
    base: {
      color: colour.med,
      fontFamily: fontFamily,
      fontSmoothing: "antialiased",
      fontSize: '1em',
      "::placeholder": {
        color: colour.med,
      }
    },
    invalid: {
      color: "#FA755A",
      iconColor: "#FA755A",
    },
  },
};

function CardSection() {

  const styles = {
    centre: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    }
  }

  return (
    <label style={styles.centre}>
      <CardElement
        className={isMobile === true ? "StripeElement" : "StripeElementDesktop"}
        options={CARD_ELEMENT_OPTIONS} 
    />
    </label>
  );
};

export default CardSection;