import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import Header from './Header';
import { fontFamily, colour, QuidvidButton, MultilineInput } from './GlobalStyles';
import { bug, bugReports } from './actions';
import { useSelector, useDispatch } from 'react-redux';
import * as firebase from 'firebase/app';
import '@firebase/firestore';
import { isMobile } from 'react-device-detect';

function ReportBug() {

    const currentHeight = useSelector(state => state.height);
    const bugData = useSelector(state => state.bug);
    const bugReportData = useSelector(state => state.bugReports);
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {

        firebase.firestore().collection("Bugs").doc("Report")
        .onSnapshot(snapshot => {
            if (!snapshot.exists) {
                console.log('No such document!')
            }
            else {
                const data = snapshot.data();
                
                if ( data.reports !== undefined ) {
                    const reports = data.reports;
                    dispatch(bugReports(reports));
                }
            }
        })
    })

    function sendBug() {
        if ( bugData !== "" ) {

            firebase.auth().onAuthStateChanged( user => {
                if (user) {
                    const reports = bugReportData;
                    const newReport = {
                                        date: new Date(),
                                        bug: bugData,
                                        userId: user.uid,
                                        email: user.email
                                    };
                    reports.push(newReport);

                    firebase.firestore().collection("Bugs").doc("Report").set({
                        reports
                    }, {merge: true})

                    dispatch(bug(""));
                    dispatch(bugReports(reports));
                    alert("Thank you for your feedback!");
                    history.goBack();
                }
            })
        }
    }

    const styles = {
        centre: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        },
        input: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        }
    }

    return (
        <div>
            <Header />
            <div style={styles.centre}>
                <h1
                    style={{ fontSize: '1.375em', fontFamily: fontFamily, color: colour.dark, fontWeight: 'bold', textAlign: 'center' }}
                >
                    We aren't perfect
                </h1>
            </div>
            <div style={{ height: currentHeight * 0.02 }} />
            <div style={styles.input}>
                <MultilineInput
                    value={bugData}
                    id="outlined"
                    label=""
                    placeholder="Let us know how we can improve. And don’t hold back, we can handle some criticism, alright? We all f*ck up sometimes <3"
                    rows={isMobile === true ? "6" : "8"}
                    inputProps={{
                        autoCapitalize: 'none'
                    }}
                    variant="outlined" 
                    onKeyDown={(e) => e.key === 'Enter' ? sendBug() : null} 
                    onChange={(text) => dispatch(bug(text.target.value))}
                    style={styles.textField}
                    multiline
                />
            </div>
            <div style={{ height: currentHeight * 0.05 }} />
            <div style={styles.centre}>
                <QuidvidButton
                    onClick={() => sendBug()}
                >
                    Send Report
                </QuidvidButton>
            </div>
            <div style={{ height: currentHeight * 0.05 }} />
            <p 
                onClick={() => history.push('./account')}
                style={{ color: colour.med, textAlign: 'center', fontSize: '1.125em', fontFamily: fontFamily, fontWeight: 'bold', cursor: 'pointer' }}
            >
                nevermind
            </p>
        </div>
    )
}

export default ReportBug;