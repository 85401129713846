import React, { useEffect, useCallback } from 'react';
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import * as firebase from 'firebase/app';
import '@firebase/firestore';
import { width, height, userId, profileNewQuestions, askedQuestionsList, userName, userPicture, profileUserId, disabledCardButton } from './actions';
import QuestionBanner from './QuestionBanner';
import { DefaultButton, colour, fontFamily } from './GlobalStyles';
import Header from './Header';

function QuestionCredit() {

    const dispatch = useDispatch();
    const currentWidth = useSelector(state => state.width);
    const currentHeight = useSelector(state => state.height);
    const newQuestion = useSelector(state => state.newQuestionText);
    const anonymousState = useSelector(state => state.anonymous);
    const questionerName = useSelector(state => state.questioner);
    const currentProfileUserName = useSelector(state => state.profileUserName);
    const sourceList = useSelector(state => state.sources);
    const viewingProfileUserName = useSelector(state => state.viewingProfileName);
    const viewingProfileUserPicture = useSelector(state => state.viewingProfileUserPicture);
    const currentUserName = useSelector(state => state.userName);
    const currentUserPicture = useSelector(state => state.userPicture);
    const currentUserId = useSelector(state => state.userId);
    const askedQuestions = useSelector(state => state.askedQuestionsList);
    const currentProfileUserId = useSelector(state => state.profileUserId)
    const currentProfileNewQuestions = useSelector(state => state.profileNewQuestions);
    const disabled = useSelector(state => state.disabledCardButton);
    const userList = useSelector(state => state.userList);
    const currentCredits = useSelector(state => state.questionCredits);
    let history = useHistory();
    const stableDispatch = useCallback(dispatch, []);
    useEffect(() => {

    if ( newQuestion === "" ) {
        history.push('./home');
    }
    else if ( currentCredits === 0 ) {
        history.push('./confirmCard');
    }

        firebase.auth().onAuthStateChanged((user) => {
            if (user) {

                    stableDispatch(userId(user.uid));

                    firebase.firestore().collection("Creator Profile").doc(currentProfileUserId)
                    .onSnapshot(snapshot => {
                      if (!snapshot.exists) {
                        console.log("No such document!")
                      }
                      else {
                        const data = snapshot.data();
            
                        if ( data.newQuestions !== undefined ) {
                          const newQuestionsData = data.newQuestions;
                          stableDispatch(profileNewQuestions(newQuestionsData));
                        }
                        
                      }
                    })
            
                    firebase.firestore().collection("Creator Profile").doc(user.uid)
                    .onSnapshot(snapshot => {
                      if (!snapshot.exists) {
                        console.log("No such document!")
                      }
                      else {
                        const data = snapshot.data();
            
                        if ( data.askedQuestions !== undefined ) {
                          const askedQuestionsData = data.askedQuestions;
                          stableDispatch(askedQuestionsList(askedQuestionsData));
                        }
                        if ( data.userName !== undefined ) {
                            const userNameData = data.userName;
                            stableDispatch(userName(userNameData));
                          }
                        if ( data.photoURL !== undefined ) {
                            const userPhoto = data.photoURL;
                            stableDispatch(userPicture(userPhoto));
                        }
                      }
                    })
            }
        })

        function resizeWindow () {
          const newWidth = window.innerWidth;
          const newHeight = window.innerHeight;
          stableDispatch(width(newWidth));
          stableDispatch(height(newHeight));
      };
        window.addEventListener("resize", resizeWindow);
        return () => window.removeEventListener("resize", resizeWindow);
    }, [stableDispatch, currentProfileUserId, history, newQuestion, currentCredits]);

    function submit() {

        dispatch(disabledCardButton(true));
       
        const id = Math.random().toString(36).substring(7);
        const askedQuestionsIdIndex = askedQuestions.map((e) => { return e.id; }).indexOf(id);

        if ( askedQuestionsIdIndex === -1 ) {
          const question = {
            question: newQuestion,
            userName: anonymousState === true ? 'anonymous' : currentUserName,
            userImage: currentUserPicture,
            userId: currentUserId,
            date: new Date(),
            id: id,
            credit: true
          }
          
          const newQuestions = currentProfileNewQuestions;
          newQuestions.push(question);

          firebase.firestore().collection("Creator Profile").doc(currentProfileUserId).set({
              newQuestions
          }, {merge: true})
          
          const newAskedQuestion = {
              question: newQuestion,
              creatorUserName: viewingProfileUserName,
              creatorImage: viewingProfileUserPicture,
              creatorUserId: currentProfileUserId,
              date: new Date(),
              id: id,
              credit: true
          }
          askedQuestions.push(newAskedQuestion)
          const newQuestionCredits = currentCredits - 1;

          firebase.firestore().collection("Creator Profile").doc(currentUserId).set({
              askedQuestions,
              questionCredits: newQuestionCredits
          }, {merge: true})

          const creatorUserIndex = userList.map((e) => { return e.userId; }).indexOf(currentProfileUserId);
          const creatorEmail = userList[creatorUserIndex].email;
          firebase.firestore().collection("Notification_Email").doc(currentUserId).set({
              email: creatorEmail,
              link: `https://www.quidvid.com/profile/${viewingProfileUserName}`
          })
      
          firebase.firestore().collection("Notification_Email").doc(currentUserId).delete();

          dispatch(profileUserId(""));
          dispatch(profileNewQuestions([]));
          history.push('./questionConfirmed');
          dispatch(disabledCardButton(false));
        }
        else {
          submit();
        }
    }

    const styles = {
        centre: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        },
        heading: {
            paddingLeft: currentWidth * 0.1
        }
    }

    return (
        <div>
            <Header />
            <QuestionBanner
                width={currentWidth}
                height={currentHeight}
                question={newQuestion}
                anonymous={anonymousState}
                questionerName={questionerName}
                creatorUserName={currentProfileUserName}
            />
            <div style={{ height: currentHeight * 0.1 }} />
            {
              sourceList.length !== 0 ?
              <div style={styles.heading}>
                <h3 style={{ fontFamily: fontFamily, color: colour.dark, fontSize: '1.375em', fontWeight: 'bold' }}>
                    Just checking
                </h3>
              </div>
              :
              null
            }
            <div style={{ height: currentHeight * 0.1 }} />
            <div style={styles.centre}>
                <p style={{ fontWeight: 'bold', color: colour.med, textAlign: 'center', fontSize: '1.125em', fontFamily: fontFamily }}>
                    {currentCredits} credits remaining
                </p>
            </div>
            <div style={{ height: currentHeight * 0.1 }} />
            <div style={styles.centre}>
                <DefaultButton
                  disabled={disabled}
                  onClick={() => submit()}
                >
                    Ask my question for 1 Credit
                </DefaultButton>
            </div>
            <div style={{ height: currentHeight * 0.05 }} />
        </div>
    )
}

export default QuestionCredit;