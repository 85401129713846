import React, { useEffect, useCallback } from 'react';
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { width, height } from './actions';
import QuestionBanner from './QuestionBanner';
import LoginComponent from './LoginComponent';
import { colour, fontFamily } from './GlobalStyles';
import Header from './Header';
import DesktopLoginComponent from './DesktopLoginComponent';
import { isMobile } from 'react-device-detect';

function QuestionLogin() {

    const dispatch = useDispatch();
    const currentWidth = useSelector(state => state.width);
    const currentHeight = useSelector(state => state.height);
    const newQuestion = useSelector(state => state.newQuestionText);
    const anonymousState = useSelector(state => state.anonymous);
    const questionerName = useSelector(state => state.questioner);
    const currentProfileUserName = useSelector(state => state.profileUserName);
    const stableDispatch = useCallback(dispatch, []);
    let history = useHistory();

    useEffect(() => {
      
        if ( newQuestion === "" ) {
            history.push('./home');
        }
        
        function resizeWindow () {
            const newWidth = window.innerWidth;
            const newHeight = window.innerHeight;
            stableDispatch(width(newWidth));
            stableDispatch(height(newHeight));
          };
        window.addEventListener("resize", resizeWindow);
        return () => window.removeEventListener("resize", resizeWindow);
    }, [history, stableDispatch, newQuestion]);

    const styles = {
        centre: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        }
    }

    return (
        <div>
            <Header />
            <QuestionBanner
                width={currentWidth}
                height={currentHeight}
                question={newQuestion}
                anonymous={anonymousState}
                questionerName={questionerName}
                creatorUserName={currentProfileUserName}
            />
            <div style={{ height: currentHeight * 0.02 }} />
            <div style={styles.centre}>
                <h2 style={{ fontFamily: fontFamily, color: colour.dark, fontSize: '1.375em' }}>
                    Sorry bout that, mate {'<3'}
                </h2>
                <div style={{ height: currentHeight * 0.02 }} />
                {
                    isMobile === true ?
                    <LoginComponent
                        path="./addPaymentMethod"
                    />
                    :
                    <DesktopLoginComponent 
                        path="./addPaymentMethod"
                    />
                }
                <div style={{ height: currentHeight * 0.02 }} />
                <div style={styles.centre}>
                    <p 
                        style={{ cursor: 'pointer', color: colour.primaryMuted, fontWeight: 'bold', fontSize: '1.125em', fontFamily: fontFamily }}
                        onClick={() => history.push('./questionSignup')}
                    >
                        {'<'} jk, I need to set one up
                    </p>
                </div>
            </div>
        </div>
    )
}

export default QuestionLogin;