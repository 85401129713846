import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import Header from './Header';
import { fontFamily, colour, QuidvidButton, MultilineInput } from './GlobalStyles';
import { hate, hateArray } from './actions';
import { useSelector, useDispatch } from 'react-redux';
import * as firebase from 'firebase/app';
import '@firebase/firestore';
import { isMobile } from 'react-device-detect';

function Hate() {

    const currentHeight = useSelector(state => state.height);
    const currentHate = useSelector(state => state.hate);
    const allHateArray = useSelector(state => state.hateArray);
    const history = useHistory();
    const dispatch = useDispatch();
    
    useEffect(() => {

        firebase.firestore().collection("Hate").doc("Data")
        .onSnapshot(snapshot => {
            if (!snapshot.exists) {
                console.log('No such document!')
            }
            else {
                const data = snapshot.data();
                
                if ( data.hateData !== undefined ) {
                    const hateData = data.hateData;
                    dispatch(hateArray(hateData));
                }
            }
        })
    })

    function sendHate() {
        if ( currentHate !== "" ) {

            firebase.auth().onAuthStateChanged( user => {
                if (user) {
                    const hateData = allHateArray;
                    const newHate = {
                                        date: new Date(),
                                        hate: currentHate,
                                        userId: user.uid,
                                        email: user.email
                                    };
                    hateData.push(newHate);

                    firebase.firestore().collection("Hate").doc("Data").set({
                        hateData
                    }, {merge: true})

                    dispatch(hate(""));
                    dispatch(hateArray(hateData));
                    alert("Thank you for your feedback!");
                    history.goBack();
                }
            })
        }
    }

    const styles = {
        centre: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        },
        input: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        }
    }

    return (
        <div>
            <Header />
            <div style={styles.centre}>
                <h1
                    style={{ fontSize: '1.375em', fontFamily: fontFamily, color: colour.dark, fontWeight: 'bold', textAlign: 'center' }}
                >
                    Proclaim Your Hate
                </h1>
            </div>
            <div style={{ height: currentHeight * 0.02 }} />
            <div style={styles.input}>
                <MultilineInput
                    value={currentHate}
                    id="outlined"
                    label=""
                    placeholder="All jokes aside, we are all ears."
                    rows={isMobile === true ? "6" : "8"}
                    inputProps={{
                        autoCapitalize: 'none'
                    }}
                    variant="outlined" 
                    onKeyDown={(e) => e.key === 'Enter' ? sendHate() : null} 
                    onChange={(text) => dispatch(hate(text.target.value))}
                    style={styles.textField}
                    multiline
                />
            </div>
            <div style={{ height: currentHeight * 0.02 }} />
            <div style={styles.centre}>
                <QuidvidButton
                    onClick={() => sendHate()}
                >
                    Send Hate
                </QuidvidButton>
            </div>
            <div style={{ height: currentHeight * 0.05 }} />
            <p 
                onClick={() => history.push('./account')}
                style={{ color: colour.med, textAlign: 'center', fontSize: '1.125em', fontFamily: fontFamily, fontWeight: 'bold', cursor: 'pointer' }}
            >
                you're alright
            </p>
        </div>
    )
}

export default Hate;