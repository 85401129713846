import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import Header from './Header';
import { fontFamily, colour, QuidvidButton, MultilineInput } from './GlobalStyles';
import { secret, secretArray, secretAnonymous } from './actions';
import * as firebase from 'firebase/app';
import '@firebase/firestore';
import { useSelector, useDispatch } from 'react-redux';
import Checkbox from '@material-ui/core/Checkbox';
import { isMobile } from 'react-device-detect';

function Secret() {

    const secretData = useSelector(state => state.secret);
    const secretArrayData = useSelector(state => state.secretArray);
    const currentHeight = useSelector(state => state.height);
    const currentWidth = useSelector(state => state.width);
    const secretAnonymousState = useSelector(state => state.secretAnonymous)
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {

        firebase.firestore().collection("Secrets").doc("Data")
        .onSnapshot(snapshot => {
            if (!snapshot.exists) {
                console.log('No such document!')
            }
            else {
                const data = snapshot.data();
                
                if ( data.secrets !== undefined ) {
                    const secrets = data.secrets;
                    dispatch(secretArray(secrets));
                }
            }
        })
    })

    function confess() {
        if ( secretData !== "" ) {
            firebase.auth().onAuthStateChanged( user => {
                if (user) {
                    const secrets = secretArrayData;
                    const newSecret = {
                                        date: new Date(),
                                        secret: secretData,
                                        userId: secretAnonymousState === true ? 'anonymous' : user.uid,
                                        email: secretAnonymousState === true ? 'anonymous' : user.email
                                    };
                    secrets.push(newSecret);

                    firebase.firestore().collection("Secrets").doc("Data").set({
                        secrets
                    }, {merge: true})

                    dispatch(secret(""));
                    dispatch(secretArray(secrets));
                    dispatch(secretAnonymous(false));
                    history.goBack();
                }
            })
        }
    }

    const styles = {
        centre: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        },
        input: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        },
        anonymous: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
        }
    }

    return (
        <div>
           <Header />
            <div style={styles.centre}>
                <h1
                    style={{ fontSize: '1.375em', fontFamily: fontFamily, color: colour.dark, fontWeight: 'bold', textAlign: 'center' }}
                >
                    Because, why not?
                </h1>
            </div>
            <div style={{ height: currentHeight * 0.02 }} />
            <div style={styles.input}>
                <MultilineInput
                    value={secretData}
                    id="outlined"
                    label=""
                    placeholder="Sometimes it feels good to get something off your chest. Or maybe just make our day interesting? We’ll be your best friend or your priest if you need to confess. Whatever you want."
                    rows={isMobile === true ? "6" : "8"}
                    inputProps={{
                        autoCapitalize: 'none'
                    }}
                    variant="outlined" 
                    onKeyDown={(e) => e.key === 'Enter' ? confess() : null} 
                    onChange={(text) => dispatch(secret(text.target.value))}
                    style={styles.textField}
                    multiline
                />
            </div>
            <div style={{ height: currentHeight * 0.02 }} />
            <div style={styles.anonymous}>
              <div style={{ width: currentWidth * 0.05 }} />
              <Checkbox
                  checked={secretAnonymousState}
                  onChange={(state) => dispatch(secretAnonymous(state.target.checked))}
                  name="checkedB"
                  label=""
                  color="primary"
              />
              <p style={{ color: colour.med, fontSize: '1em', fontFamily: fontFamily }}>
                  Make it from anonymous
              </p>
            </div>
            <div style={styles.centre}>
                <QuidvidButton
                    onClick={() => confess()}
                >
                    Confess
                </QuidvidButton>
            </div>
            <div style={{ height: currentHeight * 0.05 }} />
            <p 
                onClick={() => history.push('./account')}
                style={{ color: colour.med, textAlign: 'center', fontSize: '1.125em', fontFamily: fontFamily, fontWeight: 'bold', cursor: 'pointer' }}
            >
                nevermind
            </p>
        </div>
    )
}

export default Secret;